import React, { Component, Fragment } from "react";
import FeedbackDetails from "./FeedbackDetails";

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Button, TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';


import { inject, observer } from "mobx-react";
import { humanizeDate } from "./stores/Util";
import { COMPLETE_DIALOG_FLAG, ACCEPT_DIALOG_FLAG } from "./stores/LabelStore";
import ClassificationsModal from "./ClassificationsModal";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = theme => ({
  root: {
    height: window.innerHeight / 2.5,
    width: '100%',
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  grow: {
    flexGrow: 1,
  }
});

@inject("feedbackStore", "dimensionStore", "feedbackInteractionStore", "feedbackActionStore","feedbackListStore")
@observer
class PendingForAuditDetailsView extends Component {

  onClose = () => {
    const { feedbackInteractionStore } = this.props;
    feedbackInteractionStore.updateOpenDialog('');
  }

  renderActions = () => {
    const { feedbackInteractionStore, feedbackActionStore, feedbackListStore } = this.props;
    const {selectedFeedback:{completedMailId}} = feedbackListStore;
    if (completedMailId && completedMailId !== 0) {
      return <div><Button variant="contained" color="primary"
        onClick={feedbackActionStore.onResend}>ReSend</Button></div>
    }
    return (<div style={{ "display": "flex" }}>{feedbackActionStore.editable && this.editDialog()}
      {feedbackInteractionStore.isEditable() && this.completeDialog()}</div>);
  }

  renderHeader = () => {
    const { classes, feedbackStore, dimensionStore } = this.props;
    return (
      <AppBar position="static" color="secondary" onClick={dimensionStore.adjustSize}>
        <Toolbar variant="dense">
          <Typography variant="subtitle1" color="inherit" className={classes.grow}>
            {feedbackStore.header}
          </Typography>
          {this.renderActions()}
        </Toolbar>
      </AppBar>
    );
  }

  onModifyButtonClicked = () => {
    const { feedbackActionStore } = this.props;
    feedbackActionStore.getClassifications();
    feedbackActionStore.updateOpenDialog(ACCEPT_DIALOG_FLAG);
  }

  editDialog = () => {
    const { feedbackActionStore, feedbackStore, classes } = this.props;
    return (
      <div>
        <Button variant="contained" color="primary"
          onClick={this.onModifyButtonClicked}>Modify</Button>
        <ClassificationsModal
          openDialog={ACCEPT_DIALOG_FLAG}
          feedbackActionStore={feedbackActionStore} feedbackStore={feedbackStore} classes={classes}
          onConfirm={feedbackActionStore.onModify}
          onCancel={feedbackActionStore.reset}
        />
      </div>
    );
  }

  completeDialog = () => {
    const { feedbackInteractionStore, feedbackActionStore, feedbackStore } = this.props;
    const message = 'Mark as completed on ' + humanizeDate(new Date());
    return (
      <div style={{ "marginLeft": "5px" }}>
        <Button variant="contained" color="primary" onClick={() => { feedbackInteractionStore.updateOpenDialog(COMPLETE_DIALOG_FLAG) }}>
          Close
        </Button>
        <Dialog
          open={feedbackInteractionStore.openDialog === COMPLETE_DIALOG_FLAG}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.onClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{feedbackStore.header}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {message}
            </DialogContentText>
            <TextField multiline={true} variant="outlined" rows={5} autoFocus
              onChange={(event) => { feedbackActionStore.updateComments(event.target.value) }}
              style={{ "marginTop": "5px", "minWidth": "500px" }} margin="dense"
              id="reason" label="Audit Comments"
              fullWidth
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onClose} color="primary">
              Cancel
                  </Button>
            <Button onClick={feedbackActionStore.onFeedbackClosed} color="primary">
              Confirm
                  </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }


  render() {
    return (
      <Fragment >
        {this.renderHeader()}
        <FeedbackDetails />
      </Fragment>
    );
  }
}

export default withStyles(styles)(PendingForAuditDetailsView);