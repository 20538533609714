import React ,{ Component } from "react";
import { withStyles, CircularProgress } from "@material-ui/core";
import {observer} from 'mobx-react';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import Button from '@material-ui/core/Button';
import { snackBarStore } from "./stores/SnackBarStore";
import { LOADING } from "./stores/LabelStore";

const styles = theme => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    icon: {
      verticalAlign: 'bottom',
      height: 20,
      width: 20,
    },
    details: {
      alignItems: 'center',
    },
    column: {
      flexBasis: '33.33%',
    },
    helper: {
      borderLeft: `2px solid ${theme.palette.divider}`,
      padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    iconSmall: {
        fontSize: 20,
    },
});


@observer
class ActionItemView extends Component
{

    render()
    {
        const {actionItem,index} = this.props;
        const {classes} = this.props;
        return(
            <ExpansionPanel expanded = {actionItem.expanded} onChange = {(event,expanded)=>{actionItem.onSelect(expanded)}}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>{actionItem.action}</Typography>
                </ExpansionPanelSummary>
                <Divider/>
                <ExpansionPanelDetails>
                <form className={classes.container} noValidate>
                    {this.textMap("Tag Line",'action',actionItem.action,actionItem.isEditable(),classes,true,actionItem.onFieldChange,index)}
                    {this.multiLineText("Proposed Corrective Action",'description',actionItem.description,actionItem.isEditable(),classes,true,actionItem.onFieldChange)}
                    {this.datePicker('Expected Completion Date','expectedCompletionDate',actionItem.expectedCompletionDate,actionItem.isEditable(),actionItem.onFieldChange)}
                </form>
                 <br/>
                </ExpansionPanelDetails>
                <Divider />
                <ExpansionPanelActions>
                <Button size="small" disabled={!actionItem.isEditable()} style={{"background":'#ff0000c4'}} variant="contained" onClick={actionItem.deleteActionItem}>
                    Delete
                </Button>
                <Button size="small"  variant="contained" onClick = {actionItem.cancel}>Cancel</Button>
                <Button size="small" disabled={!actionItem.isEditable()} color="primary" variant="contained" onClick={actionItem.save}>
                    Save {actionItem.pageState === LOADING && <CircularProgress style={{"marginLeft":"5px"}}className={classes.spinner} size = {18} color='white'/>}
                </Button>
                </ExpansionPanelActions>
                
            </ExpansionPanel>
        );
    }

    textMap = (label, fieldId, value, editable, classes, fullWidth,action,index) => {
      const updateStore = (event) => {
        action(fieldId,event.target.value);
      }
      const id = fieldId+'-'+index;
      return (
        <TextField id={id} required disabled={!editable} fullWidth={true} label={label} value={value} className={classes.textField} margin="normal" variant="outlined"
        onChange={updateStore}
    />
      );
    }

    multiLineText = (label, fieldId,value,editable,classes,fullWidth,action) => {
      
      const updateStore = (event) => {
        action(fieldId,event.target.value);
      }
    
      return (
        <TextField id={fieldId} required disabled={!editable} multiline={true} rows={3} fullWidth={true} label={label} value={value} className={classes.textField} margin="normal" variant="outlined"
        onChange={updateStore}
    />
      );
    }


    datePicker = (label,fieldId, value,editable,action) => {
      const updateStore = (event) => {
        let date = new Date();
        if(date <= event)
        {
          action(fieldId,event);  
          return;
        }
        snackBarStore.showErrorMessage('Date should be greater than today');
      }
      
      let date = new Date();
      date.setDate(date.getDate()+1);
      return (
          <MuiPickersUtilsProvider utils={DateFnsUtils} >
            <DatePicker
              margin="normal"
              disabled={!editable}
              style={{ "marginLeft": "9px" }}
              value={value}
              label = 'Expected End Date'
              hinttext={label}
              onChange={updateStore}
            />
          </MuiPickersUtilsProvider>
      );
    }
}
export default withStyles(styles)(ActionItemView);