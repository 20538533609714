import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import { Provider,inject } from "mobx-react";
import DistributionListStore from "./stores/DistributionListStore";

import FunctionMappingUIContainer from "./FunctionMappingUIContainer";

const styles = theme => ({
    root: {
        padding: theme.spacing.unit,
    },
});

@inject("appStore")
class FunctionMappingWorkbench extends Component {

    constructor(props) {
        super(props);
        this.distributionListStore = new DistributionListStore({ apiProxy: props.appStore.apiProxy });
    }

    render() {
        const { classes } = this.props;
        return (
            <Provider distributionListStore={this.distributionListStore}>
                <div className={classes.root}>
                    <FunctionMappingUIContainer />
                </div>
            </Provider>
        );
    }
}


FunctionMappingWorkbench.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FunctionMappingWorkbench);