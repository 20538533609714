import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ThumbDown from '@material-ui/icons/ThumbDown';
import ThumbUp from '@material-ui/icons/ThumbUp';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


import {dimensionStore} from './stores/DimensionStore';

import { observer, inject } from 'mobx-react';


import { NO_MAIL_IS_SELECTED } from './stores/LabelStore'
import MailWidget from './MailWidget.js';

const styles = theme => ({
  root: {
    height: dimensionStore.height,
    padding: theme.spacing.unit * 2,
  },
  grow: {
    flexGrow: 1,
  },
  chip: {
    marginRight: theme.spacing.unit,
  },
  content: {
    height: dimensionStore.height * dimensionStore.content_ratio,
    overflow: "auto",
    maxHeight: dimensionStore.height * dimensionStore.content_ratio,
  },
});

var formData = {};

@inject("correspondenceStore", "feedbackStore")
@observer
class MailContent extends Component {


  adjustSize = () => {
    dimensionStore.adjustSize();
  }

  handleOpen = () => {
    const store = this.props.correspondenceStore;
    console.log(store);
    store.isDialogOpen = true;
  }

  handleAccept = () => {
    const store = this.props.correspondenceStore;
    console.log(store);
    store.managerRemarksOnFeedback(store.correspondence.feedbackId, "NOT A FEEDBACK");
    store.isDialogOpen = false;
    console.log(data);
  }

  handleReject = () => {
    const store = this.props.correspondenceStore;
    console.log(store);
    store.managerRemarksOnFeedback(store.correspondence.feedbackId, "FEEDBACK");
    store.isDialogOpen = false;
  }

  handleClose = () => {
    const store = this.props.correspondenceStore;
    store.isDialogOpen = false;
  }

  handleIgnore = () => {
    const store = this.props.correspondenceStore;
    store.isDialogOpen = false;
    store.ignoreMail();
  }

  renderIgnoreDialog = () => {
    const store = this.props.correspondenceStore;
    const isDialogOpen = store.isDialogOpen;

    return (
      <Dialog
          open={isDialogOpen}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Not a Feedback</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              The selected mail will be treated as NOT a Feedback or will be treated as
              a duplicate.

              Example scenarios are: 
                  Either you or your team member has flagged a mail as Feedback from the Mail Box. 
                  But after reading the mail you have decided that the given mail is not a feedback.
                  Here you are treating mail as Not a feedback.   
                <br/>
                  You have create already a feedback for the given context. The mail is a supporting 
                  document for the created feedback. Here you are accepting the mail but a feedback need not 
                  to be created.  
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleAccept} color="primary">Yes, this is not a feedback</Button>
            <Button onClick={this.handleReject} color="primary" autoFocus>No, this is a feedback</Button>
          </DialogActions>
        </Dialog>
     
    );
  }

  renderIgnoredMailContent() {
    const { classes } = this.props;
    return (
      <Fragment>
        <AppBar position="static" color="default">
          <Toolbar variant="dense">
            <Typography variant="subtitle1" color="inherit">
              {NO_MAIL_IS_SELECTED}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.root} >
          <Typography variant="body1" color="secondary">
            Dear User,

            <ul>
              <li>A mail was successfully marked as ignored.</li>
              <li>You may see a fresh set of mails for you to assign.</li>
            </ul>

            Thank you.
          </Typography>
        </div>
      </Fragment>
    );
  }
  

  renderMailContent() {
    const { classes } = this.props;
    let correspondence = this.props.correspondenceStore.correspondence;
    const feedbackId = correspondence.feedbackId;

    if(correspondence.attachedFile===undefined)
    {
      correspondence.attachedFile = [];
    }
    if(correspondence.mailRecipients===undefined)
    {
      correspondence.mailRecipients = [];
    }
 
    return (
      <Fragment>
        <AppBar position="static" color="secondary">
          <Toolbar variant="dense">
            <Typography variant="subtitle1" color="inherit" className={classes.grow}>{correspondence.subject}</Typography>
            <IconButton color="inherit" onClick={this.handleOpen}><ThumbDown/></IconButton>
            <IconButton color="inherit" onClick={this.handleOpen}><ThumbUp/></IconButton>
          </Toolbar>
        </AppBar>

        <MailWidget correspondence = {correspondence}/>
        {this.renderIgnoreDialog()}
      </Fragment>
    )
  }

  renderAlternateContent() {
    const isIgnored = this.props.correspondenceStore.isMailIgnored;
    return isIgnored ? this.renderIgnoredMailContent() : this.renderEmptyMailContent();
  }

  renderEmptyMailContent() {
    const { classes } = this.props;
    return (
      <Fragment>
        <AppBar position="static" color="default">
          <Toolbar variant="dense">
            <Typography variant="subtitle1" color="inherit">
              {NO_MAIL_IS_SELECTED}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.root} style={{"height" : "315px"}}>
          <Typography variant="body2" color="secondary">
            Dear User,
           <ul>
              <li>On your left side you may be seeing a list of mails; those are marked as potential feedbacks.</li>
              <li>The content of the mail will be visible in this area, when you click on it.</li>
              <li>The first step of a feedback creation is assigning a distribution list to the selected mail.</li>
              <li>You can create a distribution list by selecting the publiser, division and functions.</li>
              <li>After you have entered all the required fields, the next button will be enabled automatically.</li> 
            </ul>
            Thank you.
          </Typography>
        </div>
      </Fragment>
    );
  }

  render() {
    const isMailReady = this.props.correspondenceStore.isMailReady;
    return isMailReady ? this.renderMailContent() : this.renderAlternateContent();
  }
}

MailContent.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(MailContent);
