import React, { Component } from "react";
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { Button, Grid } from '@material-ui/core';
import {appStore} from './stores/AppStore';
import { AgGridReact } from 'ag-grid-react';
import APIProxy from './stores/APIProxy';
import '../Delivery.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import {usersUrl, userSave} from './stores/APIEndpoints';
import { SortByAlpha } from "@material-ui/icons";

const styles = {
    card: {
        marginTop: '30px',
        marginLeft:'auto',
        marginRight:'auto',
        maxWidth: '600px',
    },
    media: {
        objectFit: 'cover',
    },
    table: {
      minWidth: 300,
    },
};

var formData = {};

class EmployeeDetails extends Component
{
    constructor(prop){
      super(prop);
      this.state = {publisher: [], pubval:'', teamval:'', leadval:'', funval:'', funcatval:'', buheadval:'', hrisval:'', accountval:'', delval:'', division: [], function: [], userval:'', user: [], empval:'', teamval:'', qcfunctionval:'', functionreportval:'',}
    }

    fetchUsers = () => {
      try {
        appStore.apiProxy.get(usersUrl, this.setUser);
      }
      catch (e) {
        console.log(e);
      }
    }

    componentDidMount() {
        this.fetchUsers();
      }

    setUser = (data) => {
      console.log(data);
      this.setState({user: data});
    }
  
    handleEmpChange = event => {
      let empDetail = [];
        formData['id'] = event.target.value;
        this.setState({empval:event.target.value});
        for (var i=0; i < this.state.user.length; i++) {
          if (this.state.user[i].id === event.target.value) {
            empDetail = this.state.user[i];
          }
      }
      this.setState({
        teamval:empDetail.teamName,
        qcfunctionval:empDetail.qcFunction,
        functionreportval:empDetail.functionForReport
      });
      console.log(empDetail);
    };

    handleTeamChange = event => {
        formData['teamName'] = event.target.value;
        this.setState({teamval:event.target.value});
        };

    handleQcfunctionChange = event => {
        formData['qcFunction'] = event.target.value;
        this.setState({qcfunctionval:event.target.value});
        };
    
    handlefunctionForReportChange = event => {
        formData['functionForReport'] = event.target.value;
        this.setState({functionreportval:event.target.value});
        };

    saveUser = async () => {
        formData.id = parseInt(formData.id);
        formData.teamName;
        formData.qcFunction;
        formData.functionForReport;
        console.log(formData);
            try{
            const response = await appStore.apiProxy.asyncPost(userSave, formData);
            console.log(response);
            this.setState({empval:'', teamval:'', qcfunctionval:'', functionreportval:''})
            } catch{
            console.log("user data is not save");
            }
        }

    render()
    {
      return (
        <div style ={{"text-align": "center","width":"100%","margin-top":"70px","margin-left":"70px"}} >
        <form noValidate autoComplete="off">
          <Grid container>
          <TextField style ={{"margin":"10px","width":"18%"}} 
                id="id"
                select
                label="Employee Name"
                value={this.state.empval}
                onChange={this.handleEmpChange}
                variant="outlined">
                {this.state.user.map(option => (
                <MenuItem key={option.id} value={option.id}>{option.name} ({option.empId})</MenuItem>
                ))}
                </TextField>
          <TextField style ={{"margin":"10px","width":"18%","sort":"asc"}} 
                id="teamName"
                select
                label="Team Name"
                value={this.state.teamval}
                onChange={this.handleTeamChange}
                variant="outlined">
                {this.state.user.map(option => (
                <MenuItem key={option.id} value={option.teamName}>{option.teamName}</MenuItem>
                ))}
                </TextField>
            <TextField style ={{"margin":"10px","width":"18%","sort":"asc"}} 
                id="qcFunction"
                select
                label="QC Function"
                value={this.state.qcfunctionval}
                onChange={this.handleQcfunctionChange}
                variant="outlined">
                {this.state.user.map(option => (
                    <MenuItem key={option.id} value={option.qcFunction}>{option.qcFunction}</MenuItem>
                ))}
                </TextField>
            <TextField style ={{"margin":"10px","width":"18%","sort":"asc"}} 
                id="functionForReport"
                select
                label="Function for Report"
                value={this.state.functionreportval}
                onChange={this.handlefunctionForReportChange}
                variant="outlined">
                {this.state.user.map(option => (
                    <MenuItem key={option.id} value={option.functionForReport}>{option.functionForReport}</MenuItem>
                ))}
            </TextField>
            <Button style ={{"background-color":"#4caf50", "color":"#fff", "marginRight":"","margin":"10px","width":"10%","height":"10%","marginTop":"20px"}} label="Save" variant="outlined" color="secondary" onClick={this.saveUser}>Save</Button>
            </Grid>
        </form>
        </div>
      )
    }
}

export default withStyles(styles)(EmployeeDetails);