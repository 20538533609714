
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';


import { inject, observer } from "mobx-react";
import { LOADING, ERROR } from './stores/LabelStore';
import { CircularProgress } from '@material-ui/core';

import { dimensionStore } from './stores/DimensionStore';
import { AgGridReact } from 'ag-grid-react';
import { feedbackStore } from './stores/FeedbackStore';

const styles = theme => ({
    paper: {
        padding: theme.spacing.unit,
        height: window.innerHeight / 2.5,
    },
    root: {
        padding: theme.spacing.unit,
    },
    grow: {
        flexGrow: 1,
    },
});

const columnDefs = [
    { headerName: "Function", field: "function" },
    { headerName: "Email", field: "user" },
    { headerName: "Type", field: "addressIn" },
    { headerName: "Actor", field:"owner",sort: 'desc',valueFormatter: params =>  {return params.data.owner ? 'Yes' : 'NO'}}
];

@observer
class ActionPeople extends Component {

    onGridReady = (params) => {
        if (!params) {
            return;
        }

        const store = feedbackStore;
        const values = store.feedbackPeople;        
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        this.gridApi.setRowData(values);
        this.autoSizeAll();
    }

    autoSizeAll() {
        var allColumnIds = [];
        this.gridColumnApi.getAllColumns().forEach(function (column) {
            allColumnIds.push(column.colId);
        });
        this.gridColumnApi.autoSizeColumns(allColumnIds);
    }

    render() {
        const store = feedbackStore;
        const { classes } = this.props;

        if (store.detailState === LOADING) {
            return <CircularProgress style={{ "marginTop": "25%", "marginLeft": "50%", "marginBottom": "15%" }} />
        }

        if (store.detailState === ERROR) {
            return (<div><p style={{ "display": "grid", "alignItems": "center", "justifyContent": "center" }} >Error while fetching people.</p></div>);
        }

        const grid_classes = [classes.root, "ag-theme-material"].join(' ');
        const height = dimensionStore.height - (0.1504 * dimensionStore.height);
        return (
            <div className={grid_classes} style={{ height: height }}>
                <AgGridReact columnDefs={columnDefs} onGridReady={this.onGridReady} enableSorting={true} />
            </div>
        )
    }
}

ActionPeople.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ActionPeople);