import { types, destroy } from 'mobx-state-tree';
import { rootCauseUrl, rootCauseUsersByFeedbackUrl, rootCauseTagsUrl } from './APIEndpoints';
import { snackBarStore } from './SnackBarStore';
import { actionItemListStore } from './ActionItemTreeStore';
import { INIT, LOADING, ERROR, DONE } from './LabelStore';
import { RootCauseStore, RootCauseUser } from './RootCauseStore';
import { appStore } from './AppStore';

const RootCauseTag = types.model({
    id:types.string,
    name:types.string
})

const RootCauseTreeStore = types
    .model({
        feedbackActorId: types.optional(types.integer, 0),
        feedbackId: types.optional(types.integer, 0),
        feedbackName: types.optional(types.string, ''),
        rootCauses: types.optional(types.array(RootCauseStore), []),
        functionName: types.optional(types.string, ''),
        pageState: types.optional(types.string, INIT),
        isOwner: types.optional(types.boolean,false),
        rootCauseTags:types.optional(types.array(RootCauseTag),[])
    })
    .views(self => {
        return {
            canFinalize() {
                let canfinalize = true;
                const actionItems = actionItemListStore.actionItems;
                for (let i = 0; i < actionItems.length; i += 1) {
                    if (actionItems[i].expanded) {
                        canfinalize = false;
                        snackBarStore.showErrorMessage('Close the actionItem.');
                        break;
                    }
                }

                if (!canfinalize) {
                    return canfinalize;
                }

                self.rootCauses.map(rootCause => {
                    if (rootCause.expanded) {
                        canfinalize = false;
                        snackBarStore.showErrorMessage('Close the root cause.');
                        return;
                    }
                });
                return canfinalize;
            },

            isEditable() {
                return !(self.pageState === ERROR || self.pageState === LOADING);
            },

            canCreate() {
                let canCreate = true;
                self.rootCauses.map(item => {
                    if (item.id === 0) {
                        canCreate = false;
                        return;
                    }
                });
                return canCreate;
            },

            findRootCauseById(id) {
                return self.rootCauses.find(rootCause => rootCause.id === id);
            },

            async retrieveRootCauses() {
                const url = rootCauseUrl.replace('{feedbackId}', self.feedbackId);
                const response = await appStore.apiProxy.getAsync(url);
                const data = await response.json();
                data.map(item => {
                    self.addRootCause(item);
                });
            },

            async fetchRootCauseUsers() {
                const url = rootCauseUsersByFeedbackUrl.replace('{feedbackId}', self.feedbackId);
                const response = await appStore.apiProxy.getAsync(url);
                const data = await response.json();
                data.map(item=>{
                    const rootCause = self.findRootCauseById(item.rootCauseId)
                    const rootCauseUser = RootCauseUser.create({
                        userId: item.userId,
                        rootCauseId: item.rootCauseId,
                        name: item.email
                    });
                    rootCause.addRootCauseUser(rootCauseUser);
                });
            },

            async fetchRootCauseTags(){
                const response = await appStore.apiProxy.getAsync(rootCauseTagsUrl);
                const data = await response.json();                
                data.map(item=>self.addRootCauseTag(item));
            }
        }
    })
    .actions(self => {
        async function fetchRootCauses(feedbackActor) {
            const functionName = feedbackActor.functionName == null ? '' : feedbackActor.functionName;
            try {
                self.updatePageState(LOADING);
                self.rootCauses = [];
                self.feedbackActorId = feedbackActor.id;
                self.feedbackId = feedbackActor.feedbackId;
                self.feedbackName = feedbackActor.feedbackName;
                self.functionName = functionName;
                self.isOwner = feedbackActor.isOwner;
                actionItemListStore.reset();
                await self.retrieveRootCauses(rootCauseUrl);
                self.updatePageState(DONE);
            } catch (e) {
                self.updatePageState(ERROR);
                console.log(e);
            }
        }

        function updatePageState(pageState) {
            self.pageState = pageState;
        }

        function reset() {
            self.feedbackId = 0;
            self.feedbackActorId = 0;
            self.feedbackName = '';
            self.rootCauses = [];
            actionItemListStore.reset();
        }

        function remove(rootCause) {
            destroy(rootCause);
        }

        function create() {
            const canCreate = self.canCreate();
            if (!canCreate) {
                snackBarStore.showErrorMessage('Save newly created root cause.');
                return;
            }
            const newRootCause = {
                id: 0, feedbackActorId: self.feedbackActorId,
                feedbackId: self.feedbackId,
                singlePointFailure: false,
                rootCauseFrom: self.rootCauseFrom,
                rootCauseSubName: self.rootCauseSubName
            }
            const rootCauseStore = self.addRootCause(newRootCause);
            rootCauseStore.onSelect(true);
        }

        function addRootCause(rootCause) {
            const stage = rootCause.stage == null ? '' : rootCause.stage;
            const functionName = rootCause.id === 0 ? self.functionName : rootCause.feedbackActor.function.name;
            const newRootCause = RootCauseStore.create({
                id: rootCause.id,
                name: rootCause.name,
                stage: stage,
                vendorName: rootCause.vendorName,
                description: rootCause.description,
                feedbackId: rootCause.feedbackId,
                feedbackActorId: rootCause.feedbackActorId,
                editable: rootCause.feedbackActorId == self.feedbackActorId,
                functionName: functionName,
                singlePointFailure: rootCause.singlePointFailure,
                rootCauseFrom: rootCause.rootCauseFrom,
                rootCauseSubName: rootCause.rootCauseSubName
            });
            self.rootCauses.push(newRootCause);
            return newRootCause;
        }

        function addRootCauseTag(rootCauseTag) {
            const newRootCauseTag = RootCauseTag.create({id:rootCauseTag.name,name:rootCauseTag.name})
            self.rootCauseTags.push(newRootCauseTag);
            console.log(self.rootCauseTags);
        }

        async function fetchSubmittedRootCauses(feedbackId) {
            try {
                self.rootCauses = [];
                self.updatePageState(LOADING);
                self.feedbackId = feedbackId
                self.feedbackActorId = 0;
                self.functionName = '';
                await self.retrieveRootCauses();
                await self.fetchRootCauseUsers();
                self.updatePageState(DONE);
            } catch (e) {
                console.log(e);
            }
        }

        return { reset, fetchRootCauses, addRootCause, remove, create, updatePageState, fetchSubmittedRootCauses , addRootCauseTag};
    });
export const rootCauseTreeStore = RootCauseTreeStore.create({});