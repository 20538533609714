import { usersUrl } from './APIEndpoints';
import { decorate, observable } from 'mobx';
import { appStore } from './AppStore';
import { filteredUsersUrl } from './APIEndpoints';

class UsersStore {
    users = [{label:"abhilasha@newgen.co",value:1}];
    fetchUsers = async () => {
        try {
            this.users = []
            const response = await appStore.apiProxy.getAsync(usersUrl);
            const data = await response.json();
            data.map(item => {
                this.addUser(item);
            });
        } catch (e) {
            console.log(e);
        }
    }

    filterUsers =  async (inputValue) => {
        try {
            const url =  filteredUsersUrl.replace('#{name}', inputValue);
            const response =  await appStore.apiProxy.getAsync(url);
            return response.json();
        }catch(e){
            console.log(e);
        }
    }
}

decorate(UsersStore, {
    users: observable
})

export const userStore = new UsersStore();