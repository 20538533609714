import React, { Component, Fragment } from "react";

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import { inject, observer } from "mobx-react";
import FeedbackDetails from "./FeedbackDetails";

const styles = theme => ({
    paper: {
        padding: theme.spacing.unit,
        height: window.innerHeight / 2.5,
    },
    root: {
        padding: theme.spacing.unit,
    },
});

@inject("feedbackStore")
@inject("dimensionStore")
@observer
class SubmittedDetailsActorView extends Component {

    renderHeader = () => {
        const { classes, feedbackStore,dimensionStore} = this.props;
        return (
            <AppBar position="static" color="secondary" onClick={dimensionStore.adjustSize}>
                <Toolbar variant="dense">
                    <Typography variant="subtitle1" color="inherit" className={classes.grow}>
                        {feedbackStore.header}
                    </Typography>
                </Toolbar>
            </AppBar>
        );
    }

    render() {
        return (
            <Fragment >
                {this.renderHeader()}
                <FeedbackDetails />
            </Fragment>
        )
    }
}
export default withStyles(styles)(SubmittedDetailsActorView);