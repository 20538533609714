import { withStyles } from '@material-ui/core/styles';
import { Provider, inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import FeedbackUIContainer from './FeedbackUIContainer';
import CorrespondenceStore from './stores/CorrespondenceStore';
import FeedbackControllerStore from './stores/FeedbackControllerStore';
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit,
  },
  progress: {
    margin: theme.spacing.unit,
  },
});


@inject("appStore")
@observer
class FeedbackWorkbench extends Component {

  constructor(props) {
    super(props);
    this.correspondenceStore = new CorrespondenceStore({ apiProxy: props.appStore.apiProxy });
    this.feedbackControllerStore = new FeedbackControllerStore({ apiProxy: props.appStore.apiProxy });
  }

  editable = () =>{
    return this.correspondenceStore.editable && this.feedbackControllerStore.editable;
  }

  render() {
    const { classes } = this.props;
    return (
      <Provider correspondenceStore={this.correspondenceStore} feedbackControllerStore={this.feedbackControllerStore}>
        <div className={classes.root}>
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={!this.editable()}
            style={{display:'flex',alignItems:'center',justifyContent:'center'}}
          >
             <CircularProgress className={classes.progress} />
          </Modal>
          <FeedbackUIContainer />
        </div>
      </Provider>
    );
  }
}

FeedbackWorkbench.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FeedbackWorkbench);
