import React, { Component } from "react";

import { withStyles, CircularProgress, FormGroup, FormControlLabel, Checkbox, MenuItem } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import AsyncSelect from 'react-select/lib/Async';
import { observer } from 'mobx-react';

import { userStore } from './stores/UsersStore';
import { reactionText } from "./InputElements";
import { LOADING } from "./stores/LabelStore";


const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '33.33%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },

  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  progress: {
    marginLeft: '50%'
  },
});

@observer
class RootCause extends Component {

  render() {
    const { rootCause, classes } = this.props;
    const stageTextBoxStyle = { 'marginLeft': '5px' };
    const rootCauseName = rootCause.name + ' ( ' + rootCause.functionName + ' )';
    return (
      <ExpansionPanel expanded={rootCause.expanded} onChange={(event, expanded) => { rootCause.onSelect(expanded) }}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>{rootCauseName}</Typography>
        </ExpansionPanelSummary>
        <Divider />
        <ExpansionPanelDetails>
          {this.renderForm(classes, rootCause, stageTextBoxStyle)}
        </ExpansionPanelDetails>
        <Divider />
        <ExpansionPanelActions>
          <Button size="small" disabled={!rootCause.isEditable()} style={{ "background": '#ff0000c4' }} variant="contained" onClick={rootCause.deleteRootCause}>
            Delete {rootCause.pageState === LOADING && <CircularProgress style={{ "marginLeft": "5px" }} className={classes.spinner} size={18} color="inherit" />}
          </Button>
          <Button size="small" variant="contained" onClick={rootCause.cancel}>Cancel</Button>
          <Button disabled={!rootCause.isEditable()} size="small" color="primary" variant="contained" onClick={rootCause.save}>
            Save  {rootCause.pageState === LOADING && <CircularProgress style={{ "marginLeft": "5px" }} className={classes.spinner} size={18} color="inherit" />}
          </Button>
        </ExpansionPanelActions>
      </ExpansionPanel>
    );
  }

  textMap = (label, fieldId, value, editable, classes, fullWidth, action, styleAttr) => {
    const updateStore = (event) => {
      action(event.target.value, fieldId);
    }

    return (
      <TextField id={fieldId} style={styleAttr} required disabled={!editable} fullWidth={fullWidth} label={label} value={value} className={classes.textField} margin="normal" variant="outlined"
        onChange={updateStore}
      />
    );
  }

  multiLineText = (label, fieldId, value, editable, classes, fullWidth, action) => {

    const updateStore = (event) => {
      action(event.target.value, fieldId);
    }

    return (
      <TextField id={fieldId} required disabled={!editable} multiline={true} rows={3} fullWidth={true} label={label} value={value} className={classes.textField} margin="normal" variant="outlined"
        onChange={updateStore}
      />
    );
  }

  renderAsyncSelect = () => {
    const { rootCause } = this.props;
    return (
      <div style={{ 'marginTop': '8%'}}>
        <div style={{ 'marginBottom': '2%'}}>
        <Typography variant='caption'>Primary Performer</Typography>
        <AsyncSelect
          isMulti
          value={rootCause.getRootCauseUsersView()}
          onChange={rootCause.onSelectUser}
          defaultOptions
          placeholder='Search / Select'
          cacheOptions
          isDisabled = {!rootCause.isEditable()}
          loadOptions={userStore.filterUsers}
        /></div>
        <div style={rootCause.singlePointFailure ? {display: 'none'} : {}}>
        <Typography variant='caption'>Secondary Performer(s)</Typography>
        <AsyncSelect
          isMulti
          value={rootCause.getRootCauseSecondaryUsersView()}
          onChange={rootCause.onSelectSecondaryUser}
          defaultOptions
          placeholder='Search / Select'
          cacheOptions
          isDisabled = {!rootCause.isEditable()}
          loadOptions={userStore.filterUsers}
        /></div>
      </div>
    );
  }
  
  
  renderForm(classes, rootCause, stageTextBoxStyle) {

    function onNameChange(value){
      rootCause.onNameChange(value,'name');
    }
    function handleRootCauseFrom(value){
      rootCause.onNameChange(value,'rootCauseFrom');
    }
    function handleSubName(value){
      rootCause.onNameChange(value,'rootCauseSubName');
    }
    
    const {tags,feedbackType} = this.props;
    const isPositive = feedbackType === 'positive';
    const nameOne = rootCause.name === "Performer's Negligence";
    const nameTwo = rootCause.name === 'Performer needs training';
    const stageTextBoxStyleVendor = { 'width': '80%' };
    return (
      <form className={classes.container} noValidate style={{'marginTop': '-2%'}}>
        {reactionText('rootCauseTag',{rootCauseTag:rootCause.name},onNameChange,true,tags,classes)}
        <span style={{ 'marginLeft': '1%' }}>
        {nameOne && reactionText('rootCauseSubName',{rootCauseSubName:rootCause.rootCauseSubName},handleSubName,true,[{name:"Induced Error",id:"Induced Error"},{name:"Correction not carried out properly", id:"Correction not carried out properly"},{name:"Process not followed", id:"Process not followed"},{name:"Instruction not followed", id:"Instruction not followed"},{name:"Correction missed out", id:"Correction missed out"},{name:"Formatting error", id:"Formatting error"},{name:"File management (wrong file upload, wrong file processed, file naming)", id:"File management (wrong file upload, wrong file processed, file naming)"},{name:"Manager's negligence", id:"Manager's negligence"}],{menu:[{id:"Induced Error",name:"Induced Error"},{id:"Correction not carried out properly", name:"Correction not carried out properly"},{id:"Process not followed", name:"Process not followed"},{id:"Instruction not followed", name:"Instruction not followed"},{id:"Correction missed out", name:"Correction missed out"},{id:"Formatting error", name:"Formatting error"},{id:"File management (wrong file upload, wrong file processed, file naming)", name:"File management (wrong file upload, wrong file processed, file naming)"},{id:"Manager's negligence", name:"Manager's negligence"}]})}</span>
        <span style={{ 'marginLeft': '1%' }}>
        {nameTwo && reactionText('rootCauseSubName',{rootCauseSubName:rootCause.rootCauseSubName},handleSubName,true,[{name:"Misinterpretation of correction/instruction",id:"Misinterpretation of correction/instruction"}],{menu:[{id:"Misinterpretation of correction/instruction",name:"Misinterpretation of correction/instruction"}]})}</span>
        <span style={{ 'marginRight': '1%', }}>
        {!isPositive &&  this.textMap('Stage', 'stage', rootCause.stage, rootCause.isEditable(), classes.textField, false, rootCause.onNameChange, stageTextBoxStyle)}</span>
        <span style={{ 'marginLeft': '2%' }}>
        {reactionText('rootCauseFrom',{rootCauseFrom:rootCause.rootCauseFrom},handleRootCauseFrom,true,[{name:"Internal",id:"Internal"},{name:"External", id:"External"}],{menu:[{id:"Internal",name:"Internal"},{id:"External", name:"External"}]})}</span>
        {!isPositive &&  this.multiLineText("Probable Rootcause", 'description', rootCause.description, rootCause.isEditable(), classes.textField, true, rootCause.onNameChange)}
        {this.textMap('Provide name of the vendor, if the performer is vendor', 'vendorName', rootCause.vendorName, rootCause.isEditable(), classes.textField, false, rootCause.onNameChange, stageTextBoxStyleVendor)}
        {this.renderCheckBox()}
        {this.renderAsyncSelect()}
      </form>
    );
  }

  handleCheckbox = () => {
    const { rootCause } = this.props;
    rootCause.onNameChange(!rootCause.singlePointFailure, 'singlePointFailure');
  }

  renderCheckBox() {
    const { rootCause } = this.props;
    return (
      <FormGroup style={{ "float": "right", 'marginTop': '-2%' }}>
        <FormControlLabel
          control={<Checkbox checked={rootCause.singlePointFailure} onChange={this.handleCheckbox} value="Single Point Failure" />}
          label="Single Point Failure"
          disabled={!rootCause.isEditable()}
        />
      </FormGroup>
    )
  }
}

export default withStyles(styles)(RootCause);