import React from 'react';
import PropTypes from 'prop-types';

import RootCausePanel from './RootCausePanel';
import ActionItemPanel from './ActionItemPanel';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { feedbackStore } from './stores/FeedbackStore';

const styles = theme => ({
    root: {
        flexGrow: 1
    },

});
class ActionItemController extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <Grid container spacing={16}>
                <Grid item xs={6}>
                    <Paper className={classes.root}>
                        <RootCausePanel feedbackStore={feedbackStore} />
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper className={classes.root}>
                        <ActionItemPanel  />
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

ActionItemController.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ActionItemController);