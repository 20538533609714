import { types,  getParent } from 'mobx-state-tree';
import { actionItemsSaveUrl, deleteActionItemsUrl } from './APIEndpoints';
import { snackBarStore } from './SnackBarStore';
import {appStore} from './AppStore';
import { LOADING, ERROR, INIT, DONE } from './LabelStore';

export const ActionItem = types
    .model({
        id: types.optional(types.integer, 0),
        action: types.optional(types.string, ''),
        description: types.optional(types.string, ''),
        feedbackId: types.integer,
        feedbackActorId: types.integer,
        rootCauseId: types.integer,
        expanded: types.optional(types.boolean, false),
        expectedCompletionDate: types.maybe(types.Date, undefined),
        editable: types.optional(types.boolean, true),
        pageState: types.optional(types.string, INIT)
    })
    .views(self => {
        return {
            validate() {
                if (self.action === '') {
                    snackBarStore.showErrorMessage('Action should not be blank.');
                    return false;
                }

                if (self.description === '') {
                    snackBarStore.showErrorMessage('Description should not be  blank for #{actionName}'.replace('#{actionName}', self.name));
                    return false;
                }

                if (self.expectedCompletionDate === undefined || self.expectedCompletionDate == 'Invalid Date') {
                    snackBarStore.showErrorMessage('Select a valid date.');
                    return false;
                }
                return true;
            },

            isNew() {
                return self.id === 0;
            },

            isEditable() {
                if (self.pageState === LOADING || self.pageState === ERROR) {
                    return false;
                }
                return self.editable;
            }
        }
    })
    .actions(self => {

        function onFieldChange(fieldId, value) {
            self[fieldId] = value;
        }

        async function onSelect(expanded) {
            if (expanded) {
                self.expand();
                return;
            }
            if (!self.isEditable()) {
                self.cancel();
                return;
            }
            const saved = await self.save();
            if (saved) {
                self.cancel();
            }
        }

        function expand() {
            self.expanded = true;
        }

        function cancel() {
            self.updateExpanded(false);
            if (self.isNew()) {
                self.deleteItem();
            }
        }

        function updateExpanded(expanded){
            self.expanded = expanded;
        }

        async function deleteActionItem() {
            try {
                self.updatePageState(LOADING);
                if (!self.isNew()) {
                    await appStore.apiProxy.asyncPost(deleteActionItemsUrl, self.id);
                }
                self.deleteItem();
                snackBarStore.showSuccessMessage('Deleted successfully.');
            } catch (e) {
                self.updatePageState(ERROR);
            }
        }

        function deleteItem() {
            getParent(self, 2).remove(self);
        }

        function updatePageState(pageState) {
            self.pageState = pageState;
        }

        async function save() {
            const isValid = self.validate();
            if (!isValid) {
                return false;
            }
            try {
                self.updatePageState(LOADING);
                const response = await appStore.apiProxy.asyncPost(actionItemsSaveUrl, self);
                const json = await response.json();
                self.updateId(json.id);
                self.updatePageState(DONE);
                snackBarStore.showSuccessMessage('Saved successfully.');
                return true;
            } catch (e) {
                self.updatePageState(ERROR);
                snackBarStore.showErrorMessage('Unable to save,try again later.');
                return false;
            }
        }

        function updateId(id) {
            self.id = id;
        }

        return { onFieldChange, onSelect, cancel, save, deleteActionItem, deleteItem, expand, updateId, updatePageState,updateExpanded };
    })