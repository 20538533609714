import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { AppBar, Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Grid, Chip } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';

import { dimensionStore } from './stores/DimensionStore';
import { observer, inject } from 'mobx-react';

const styles = theme => ({
    root: {
        height: window.innerHeight / 2.5,
        width: '100%',
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    grow: {
        flexGrow: 1,
    },
    progress: {
        marginLeft: '50%'
    }
});

@inject("rootCauseTreeStore")
@observer
class SubmittedRootCauseContainer extends Component {

    renderRecipients(rootCauseUsers) {
        const { classes } = this.props;
        const renderItems = [];
        rootCauseUsers.map(item => {
            renderItems.push(<Chip key={item.userId} className={classes.chip} label={item.name} />);
        });
        return (
            <Grid item xs={12} id="recipients">
                <div style={{ "display": "flex", "flexDirection": 'row', 'overflow': 'hidden', 'overflowX': 'scroll' }}>
                    {renderItems}
                </div>
            </Grid>
        )
    }

    render_items() {
        const store = this.props.rootCauseTreeStore
        const items = store.rootCauses;
        const renderItems = [];
        items.map((item, index) => { renderItems.push(this.render_root_cause(item, index)) });
        return renderItems;
    }

    render_root_cause(rootCause) {
        const rootCauseName = rootCause.name + ' ( ' + rootCause.functionName + ' )';
        const height = dimensionStore.height * (45 / 100);
        const panelHeight = dimensionStore.height * (50 / 100);
        return (
            <ExpansionPanel expanded={rootCause.expanded} onChange={(event, expanded) => { rootCause.updateExpanded(expanded) }}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid container>
                        <Grid item xs>
                            <Typography variant="subtitle1">
                                {rootCauseName}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">
                                {rootCause.stage}
                            </Typography>
                        </Grid>
                        {this.renderRecipients(rootCause.rootCauseUsers)}
                    </Grid>
                </ExpansionPanelSummary>
                <Divider />
                <ExpansionPanelDetails style={{ ...{ height: panelHeight } }}>
                    <Typography style={{ ...{ height: height, maxHeight: height }, 'overflow': 'auto' }}> {rootCause.description}</Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }

    render_header() {
        const store = this.props.rootCauseTreeStore;
        const displayName = store.feedbackId === 0 ? 'Please Select a Feedback to see its rootcauses' : 'Rootcauses For Feedback - ' + store.feedbackId;
        const { classes } = this.props;
        return (<AppBar position="static" color="secondary">
            <Toolbar variant="dense">
                <Typography variant="subtitle1" color="inherit" className={classes.grow}>
                    {displayName}
                </Typography>
            </Toolbar>
        </AppBar>);
    }

    render() {
        const { classes } = this.props;
        return (
            <Fragment>
                {this.render_header()}
                <div className={classes.root}>
                    <div>{this.render_items()}</div>
                </div>
            </Fragment>
        );
    }
}

export default withStyles(styles)(SubmittedRootCauseContainer);