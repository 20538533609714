import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { observer, inject, Provider } from 'mobx-react';

import FeedbackWorkbench from './FeedbackWorkbench';
import ActionItemWorkbench from './ActionItemWorkbench';
import FeedbackProposalWorkbench from './FeedbackProposalWorkbench';
import ManagerFeedbackProposalWorkbench from './ManagerFeedbackProposalWorkbench';
import ObservationWorkbench from './ObservationWorkbench';
import FunctionMappingWorkbench from './FunctionMappingWorkbench';
import SubmittedFeedbackUIContainer from './SubmittedFeedbackUIContainer';
import EmployeeDetails from './EmployeeDetails';
import DivisionsGroup from './DivisionsGroup';
import Delivery from './Delivery';
import FeedbackAnalysis from './FeedbackAnalysis';
import PerformerScorecard from './PerformerScorecard';
import Login from './Login';
import About from './About';
import { dimensionStore } from './stores/DimensionStore';
import PendingForAuditUIContainer from './PendingForAuditUIContainer';
import UserPendingStats from './UserPendingStats';
import { feedbackStatStore } from './stores/FeedbackStatStore';


const styles = theme => ({
    root: {
        padding: theme.spacing.unit,
    },
});

@inject("appStore")
@observer
class SelectedComponent extends Component {

    evaluateComponent() {
        const appStore = this.props.appStore;
        switch (appStore.currentComponent.key) {
            case 'FeedbackWorkbench': {
                return <FeedbackWorkbench />;
            }
            case 'ActionItemWorkbench': {
                return <ActionItemWorkbench />;
            }
            case 'ObservationWorkbench': {
                return <ObservationWorkbench />;
            }
            case 'FeedbackProposalWorkbench': {
                return <FeedbackProposalWorkbench />;
            }
            case 'ManagerFeedbackProposalWorkbench': {
                return <ManagerFeedbackProposalWorkbench />;
            }
            case 'FunctionMappingWorkbench': {
                return <FunctionMappingWorkbench />;
            }
            case 'SubmittedFeedbackUIContainer': {
                return <SubmittedFeedbackUIContainer />;
            }
            case 'PendingForAuditUIContainer': {
                return <PendingForAuditUIContainer />;
            }
            case 'UserPendingStats': {
                return <UserPendingStats />;
            }
            case 'Login': {
                return <Login />;
            }
            case 'About': {
                return <About />;
            }
            case 'Delivery': {
                return <Delivery />;
            }
            case 'FeedbackAnalysis': {
                return <FeedbackAnalysis />;
            }
            case 'EmployeeDetails': {
                return <EmployeeDetails />;
            }
            case 'DivisionsGroup': {
                return <DivisionsGroup />;
            }
            case 'PerformerScorecard': {
                return <PerformerScorecard />;
            }
            default: {
                return <About />;
            }
        }
    }

    render() {
        const {appStore} = this.props;
        return (
            <Provider appStore={appStore} dimensionStore = {dimensionStore} feedbackStatStore={feedbackStatStore}>
                {this.evaluateComponent()}
            </Provider>

        );
    }
}

SelectedComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SelectedComponent);
