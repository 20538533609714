import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import Refresh from '@material-ui/icons/Refresh';
import MailIcon from '@material-ui/icons/Mail';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {appStore} from './stores/AppStore';
import MenuItem from '@material-ui/core/MenuItem';
import { decorate, observable, flow, computed, action } from 'mobx';

import { AgGridReact } from 'ag-grid-react';
import { humanizeDate } from './stores/Util.js';
import { observer, inject } from 'mobx-react';
import { dimensionStore } from './stores/DimensionStore.js';
import { text, reactionText, multiSelect, selectableText, tag, zimbraFolders } from './InputElements';
import {
  getUserEmailFolders,
  unassignedFeedbacksFolderUrl,
  getPubkitQmrsPubDivUrl
} from './stores/APIEndpoints';

const PENDING = 'pending';
const DONE = 'done';
const ERROR = 'error';


const styles = theme => ({
  root: {
    height: window.innerHeight / 2.5,
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
});

var formData = {};

const columnDefs = [
  { headerName: "Received On", field: "mailDate", width: 100, sort: 'asc', valueFormatter: params => humanizeDate(params.value) },
  { headerName: "Subject", field: "subject", width: 100 },
  { headerName: "Attachment", field: "attachedFileCount", width: 50 },
  { headerName: "From", field: "sender", width: 100 },
  { headerName: "Age", width: 50, cellStyle: { 'text-align': 'right' } },
  { headerName: "Status", field: "status", width: 50 }
];

@inject("correspondenceStore")
@inject("feedbackControllerStore")

@observer
class UnassignedFeedbacks extends Component {

  constructor(props) {
    super(props);
    this.state = {zimbraFolders: [], client: [], clientval:'', listState:''
  }
  this.fetchZimbraFolders();
  }

  fetchZimbraFolders = () => {
    try {
      appStore.apiProxy.get(getUserEmailFolders, this.setZimbraUserFolders);
    }
    catch (e) {
        console.log(e);
    }
}

setZimbraUserFolders = data => {
  this.setState({zimbraFolders: data});
}

  clientChange = event => {
    console.log("running clientChange");
    formData['zimbraFolder'] = event.target.value;
    this.setState({clientval:event.target.value});
    this.props.correspondenceStore.selectedFolder = event.target.value;
    this.props.correspondenceStore.fetchUnassignedFeedbacks(formData);
  };


  onGridReady = (params) => {
    if (!params) {
      return;
    }
    console.log("running onGridReady");
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.addEventListener('rowClicked', this.mailSelected);
    this.props.correspondenceStore.fetchUnassignedFeedbacks();
  }

  refresh = () => {
    //this.props.correspondenceStore.fetchUnassignedFeedbacks();
  }

  mailHeight = () => {
    const mailHeight = dimensionStore.height
    return { height: mailHeight };
  }

  setPubkitVsQmrs = data => {
    this.publisherPubkitVsQmrs = {};
    this.divisionPubkitVsQmrs = {};
    for (var i=0; i<data.length; i++){
      if (data[i]["type"] == "Publisher"){
        this.publisherPubkitVsQmrs[data[i]["pubkitId"]] = data[i]["qmrsId"];
      } 
      if (data[i]["type"] == "Division"){
        this.divisionPubkitVsQmrs[data[i]["pubkitId"]] = data[i]["qmrsId"];
      } 
    }
    console.log(data)
  }


  fetchPubkitVsQmrs = () => {
    this.state = PENDING;
    try {
      this.apiProxy.get(getPubkitQmrsPubDivUrl, this.setPubkitVsQmrs);
    }
    catch (e) {
      this.state = ERROR;
      console.log(e);
    }
  }

  mailSelected = (params) => {
    this.props.feedbackControllerStore.peopleStore.resetFeedback();
    let selectedNodes = this.gridApi.getSelectedNodes();
    let selectedData = selectedNodes.map(node => node.data);
    console.log('EMAIL IS CLICKED' + selectedData[0].sender);
    const mailId = params.data.id;
    localStorage.setItem(params.data.messageId, JSON.stringify(params.data));
    const messageId = params.data.messageId;
    this.props.correspondenceStore.setCorrespondence(selectedData[0]);
    this.props.feedbackControllerStore.isEditable = true;
    this.props.feedbackControllerStore.mailSelected(mailId, messageId);
    this.fetchPubkitVsQmrs();
  }

  renderAgGrid() {

    const { classes } = this.props;
    const grid_classes = [classes.root, "ag-theme-material"].join(' ');

    if (!this.params) {
      return (
        <div className={grid_classes} style={this.mailHeight()}>
          <AgGridReact columnDefs={columnDefs} rowSelection={'single'} onGridReady={this.onGridReady} enableSorting={true}  enableFilter={true} />
        </div>
      );
    }
  }

  renderRowData(unassignedFeedbacks) {
    if (this.gridApi) {
      this.gridApi.setRowData(unassignedFeedbacks);
    }
  }

    render() {
    const store = this.props.correspondenceStore
    const unassignedFeedbacks = store.unassignedFeedbacks;

    const { classes } = this.props;
    return (
      <Fragment>
        <AppBar position="static" color="secondary">
          <Toolbar variant="dense">
            <Typography variant="subtitle1" color="inherit" className={classes.grow}>
              Unassigned Feedback Mails
          </Typography>
        <TextField style ={{"margin":"10px","width":"21%"}} 
              id="zimbraFolder"
              select
              label="Zimbra Folder"
              value={this.state.clientval}
              onChange={this.clientChange}
              variant="outlined">
              {this.state.zimbraFolders.map(option => (
                <MenuItem key={option.id} value={option.zimbraFolder}>{option.zimbraFolder}</MenuItem>
              ))}
            </TextField>
            <Badge badgeContent={store.rowCount} max={10000}  style={{'marginLeft':'10px'}} color="primary"/>
          </Toolbar>
        </AppBar>
        {this.renderAgGrid()}
        {this.renderRowData(unassignedFeedbacks)}
      </Fragment>
    );
  }
}

UnassignedFeedbacks.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UnassignedFeedbacks);
