import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import LoginUIContainer from './LoginUIContainer';

const styles = theme => ({

});

class Login extends Component {
    render() {
        const { classes } = this.props;

        return (
            <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: '100vh', margin: '20px' }}>
                <LoginUIContainer />
            </Grid>
        )
    }
}

Login.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Login);