import React, { Component } from "react";
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { Button, Grid } from '@material-ui/core';
import {appStore} from './stores/AppStore';
import { AgGridReact } from 'ag-grid-react';
import APIProxy from './stores/APIProxy';
import '../Delivery.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import {divisionGroupUrl, divisionSave, usersUrl} from './stores/APIEndpoints';


const styles = {
    card: {
        marginTop: '30px',
        marginLeft:'auto',
        marginRight:'auto',
        maxWidth: '600px',
    },
    media: {
        objectFit: 'cover',
    },
    table: {
      minWidth: 300,
    },
};


var formData = {};

class DivisionsGroup extends Component
{
    constructor(prop){
      super(prop);
      this.state = {division: [], divisionval:'', buHeadUserVal:'', categoryVal:'', subCategoryVal:'', divisionGroupVal:'', user: [],}
    }

    fetchDivisions = () => {
      try {
        appStore.apiProxy.get(divisionGroupUrl, this.setDivision);
      }
      catch (e) {
        console.log(e);
      }
    }

    fetchUsers = () => {
      try {
        appStore.apiProxy.get(usersUrl, this.setUser);
      }
      catch (e) {
        console.log(e);
      }
    }

    componentDidMount() {
        this.fetchDivisions();
        this.fetchUsers();
      }

    setUser = (data) => {
      console.log(data);
      this.setState({user: data});
    }

    setDivision = (data) => {
      console.log(data);
      this.setState({division: data});
    }

    handleDivChange = event => {
      let divisionDetail = [];
        formData['id'] = event.target.value;
        this.setState({divisionval:event.target.value});
        for (var i=0; i < this.state.division.length; i++) {
          if (this.state.division[i].id === event.target.value) {
            divisionDetail = this.state.division[i];
          }
      }
      this.setState({
        buHeadUserVal:divisionDetail.id,
        categoryVal:divisionDetail.category,
        subCategoryVal:divisionDetail.subCategory,
        divisionGroupVal:divisionDetail.divisionGroup,
      });
      console.log(divisionDetail);
    };

    handleBuHeadChange = event => {
        formData['buHeadUserId'] = event.target.value;
        this.setState({buHeadUserVal:event.target.value});
        };

    handleCategoryChange = event => {
        formData['category'] = event.target.value;
        this.setState({categoryVal:event.target.value});
        };
    
    handleSubCategoryChange = event => {
        formData['subCategory'] = event.target.value;
        this.setState({subCategoryVal:event.target.value});
        };
    
    handleDivisionGroupChange = event => {
        formData['divisionGroup'] = event.target.value;
        this.setState({divisionGroupVal:event.target.value});
        };

    saveDivision = async () => {
      formData.id = parseInt(formData.id);
      formData.buHeadUserId = parseInt(formData.buHeadUserId);
      formData.name;
      formData.category;
      formData.subCategory;
      formData.divisionGroup;
      console.log(formData);
          try{
          const response = await appStore.apiProxy.asyncPost(divisionSave, formData);
          console.log(response);
          this.setState({divisionval:'', buHeadUserVal:'', categoryVal:'', subCategoryVal:'', divisionGroupVal:''})
          } catch{
          console.log("Division data is not save");
          }
      }

    render()
    {
      return (
        <div style ={{"text-align": "center","width":"100%","margin-top":"70px","margin-left":"30px"}} >
        <form noValidate autoComplete="off">
          <Grid container>
          <TextField style ={{"margin":"10px","width":"18%"}} 
                id="id"
                select
                label="Division Name"
                value={this.state.divisionval}
                onChange={this.handleDivChange}
                variant="outlined">
                {this.state.division.map(option => (
                <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                ))}
                </TextField>
            <TextField style ={{"margin":"10px","width":"18%"}} 
                id="buHeadUserId"
                select
                label="BU Head"
                value={this.state.buHeadUserVal}
                onChange={this.handleBuHeadChange}
                variant="outlined">
                {this.state.user.map(option => (
                    <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                ))}
                </TextField>
            <TextField style ={{"margin":"10px","width":"18%"}} 
                id="category"
                select
                label="Category"
                value={this.state.categoryVal}
                onChange={this.handleCategoryChange}
                variant="outlined">
                {this.state.division.map(option => (
                    <MenuItem key={option.id} value={option.category}>{option.category}</MenuItem>
                ))}
            </TextField>
            <TextField style ={{"margin":"10px","width":"18%"}} 
                id="subCategory"
                select
                label="Sub-Category"
                value={this.state.subCategoryVal}
                onChange={this.handleSubCategoryChange}
                variant="outlined">
                {this.state.division.map(option => (
                    <MenuItem key={option.id} value={option.subCategory}>{option.subCategory}</MenuItem>
                ))}
            </TextField>
            <TextField style ={{"margin":"10px","width":"18%"}} 
                id="divisionGroup"
                select
                label="Division Group"
                value={this.state.divisionGroupVal}
                onChange={this.handleDivisionGroupChange}
                variant="outlined">
                {this.state.division.map(option => (
                    <MenuItem key={option.id} value={option.divisionGroup}>{option.divisionGroup}</MenuItem>
                ))}
            </TextField>
            <Button style ={{"background-color":"#4caf50", "color":"#fff", "marginRight":"","margin":"10px","width":"10%","height":"10%","marginTop":"20px","marginLeft":"86.2%"}} label="Save" variant="outlined" color="secondary" onClick={this.saveDivision}>Save</Button>
            </Grid>
        </form>
        </div>
      )
    }
}

export default withStyles(styles)(DivisionsGroup);