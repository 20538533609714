import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import {LicenseManager} from 'ag-grid-enterprise';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { IntlProvider } from 'react-intl';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import AutoLogin from './AutoLogin';

//old license
//LicenseManager.setLicenseKey("Newgen_Knowledge_Works_Ltd_MultiApp_1Devs5_April_2019__MTU1NDQxODgwMDAwMA==f0636d4a0070aed2fa512c5159add138");

//new license
const agGrid = "Newgen_Knowledge_Works_Multi_Multiple_Applications_1_Devs_1_Deployment_License_6_January_2021_[v2]_MTYwOTg5MTIwMDAwMA==d5419a9f9838589f1ce84b7449afc68e";
LicenseManager.setLicenseKey(agGrid);

ReactDOM.render(<Router>
    <div>
      <Route exact path='/' component={App} />
      <Route exact path='/autologin' component={AutoLogin} />
    </div>
      </Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
