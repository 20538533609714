import React, { Component, Fragment } from 'react';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import MailIcon from '@material-ui/icons/Mail';
import IconButton from '@material-ui/core/IconButton';
import Refresh from '@material-ui/icons/Refresh';

import { AgGridReact } from 'ag-grid-react';
import { humanizeDate } from './stores/Util.js';
import { dimensionStore } from './stores/DimensionStore.js';
import { observer, inject } from 'mobx-react';
import { submittedFeedbacksUrl, submittedFeedbackActorsUrl } from './stores/APIEndpoints.js';

const styles = theme => ({
    paper: {
        padding: theme.spacing.unit,
        height: window.innerHeight / 2.5,
    },
    root: {
        padding: theme.spacing.unit,
    },
    grow: {
        flexGrow: 1,
    },
});

const columnDefs = [
    { headerName: "ID", field: "id", width: 20 },
    { headerName: "Submitted On", field: "submittedAt", width: 100, sort: 'asc', valueFormatter: params => humanizeDate(params.value) },
    { headerName: "Publisher", field: "publisherName", width: 100 },
    { headerName: "Division", field: "divisionName", width: 100 },
    { headerName: "Age", field: "ageDays" },
];


@inject("feedbackListStore","appStore")
@observer
class SubmittedFeedbacks extends Component {

    onFeedbackSelected = (params) => {
        const { feedbackListStore } = this.props;
        const id = params.data.id;
        feedbackListStore.setSelectedFeedbackId(id,params.data);
    }
    getUrl() {
        const {appStore} = this.props;
        if (appStore.credentials.role === 'actor') {
            return submittedFeedbackActorsUrl.replace('#{userId}', appStore.credentials.userId);
        }
        return submittedFeedbacksUrl;
    }

    onGridReady = (params) => {
        if (!params) {
            return;
        }
        const { feedbackListStore } = this.props;
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.addEventListener('rowClicked', this.onFeedbackSelected);
        feedbackListStore.fetchFeedbacks(this.getUrl());
    }

    renderRowData() {
        const { feedbackListStore } = this.props;
        const values = feedbackListStore.feedbacks;
        if (this.gridApi) {
            this.gridApi.setRowData(values);
            this.autoSizeAll();
        }
    }

    autoSizeAll() {
        var allColumnIds = [];
        this.gridColumnApi.getAllColumns().forEach(function (column) {
            allColumnIds.push(column.colId);
        });
        this.gridColumnApi.autoSizeColumns(allColumnIds);
    }


    renderAgGrid() {
        const { classes } = this.props;
        const grid_classes = [classes.root, "ag-theme-material"].join(' ');

        if (!this.params) {
            return (
                <div className={grid_classes} style={{ height: dimensionStore.height }}>
                    <AgGridReact columnDefs={columnDefs} rowSelection={'single'} onGridReady={this.onGridReady}
                        enableSorting={true} enableFilter={true} />
                </div>
            );
        }
    }

    renderAppBar = () => {
        const { classes, feedbackListStore } = this.props;
        const count = feedbackListStore.feedbacks.length;
        return (
            <AppBar position="static" color="secondary">
                <Toolbar variant="dense">
                    <Typography variant="subtitle1" color="inherit" className={classes.grow}>
                        Submitted Feedbacks
                    </Typography>
                    <IconButton color="inherit" onClick={()=>{feedbackListStore.fetchFeedbacks(this.getUrl())}}><Refresh /></IconButton>
                    <Badge badgeContent={count} max={10000} color="primary">
                        <MailIcon />
                    </Badge>
                </Toolbar>
            </AppBar>
        );
    }

    render() {
        return (
            <Fragment>
                {this.renderAppBar()}
                {this.renderAgGrid()}
                {this.renderRowData()}
            </Fragment>
        );
    }
}


export default withStyles(styles)(SubmittedFeedbacks);