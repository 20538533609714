import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider, inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import FeedbackDetailsActorView from './FeedbackDetailsActorView';
import ActionItemController from './ActionItemController';
import AssignedFeedbacks from './AssignedFeedbacks';

import { feedbackInteractionStore } from './stores/FeedbackInteractionStore';
import { dimensionStore } from './stores/DimensionStore';
import { feedbackStore } from './stores/FeedbackStore';
import { rootCauseTreeStore } from './stores/RootCauseTreeStore';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing.unit * 2,
    },

});

@inject("appStore")
@observer
class ActionItemWorkbench extends Component {
    constructor(props) {
        super(props);
        this.apiProxy = props.appStore.apiProxy;
        feedbackInteractionStore.loadFunctions(this.apiProxy);
        rootCauseTreeStore.fetchRootCauseTags();
        dimensionStore.setDimension(window.innerHeight / 3.5, window.innerHeight / 3);
    }

    render() {
        const mailWidth = dimensionStore.mailWidth;
        return (
            <Provider feedbackStore={feedbackStore} >
                <Grid container spacing={16}>
                    <Grid item xs={12 - mailWidth}>
                        <Paper>
                            <AssignedFeedbacks />
                        </Paper>
                    </Grid>
                    <Grid item xs={mailWidth}>
                        <Paper>
                            <FeedbackDetailsActorView />
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <ActionItemController />
                    </Grid>
                </Grid>
            </Provider>
        )
    }
}

ActionItemWorkbench.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ActionItemWorkbench);