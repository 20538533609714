import React from 'react';
import { Button, Dialog } from "@material-ui/core";
import { reactionText } from './InputElements';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ACCEPT_DIALOG_FLAG } from './stores/LabelStore';
import { acceptSubmittedFeedbackUrl } from './stores/APIEndpoints';
import { observer } from 'mobx-react';

const renderDropdown = (classes, feedbackActionStore) => {

    const classificationId = feedbackActionStore.selectedClassificationId;
    const subClassificationId = feedbackActionStore.selectedSubClassficationId;
    const classifications = feedbackActionStore.classifications;
    const subClassifications = feedbackActionStore.getSubClassifications;

    return (
        <div>
            {reactionText('classification', { classification: classificationId }, feedbackActionStore.onClassificationSelect, true, classifications, classes)}
            {reactionText('subClassification', { subClassification: subClassificationId }, feedbackActionStore.onSubClassificationSelect, true, subClassifications, classes, { 'marginLeft': '7px' })}
        </div>
    );
}

const datePicker = (label, value, editable, action) => {
    const updateStore = (event) => {
        let date = new Date();
        if (date <= event) {
            action(event);
            return;
        }
        snackBarStore.showErrorMessage('Date should be greater than today');
    }

    let date = new Date();
    date.setDate(date.getDate() + 1);
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} >
            <DatePicker
                margin="normal"
                disabled={!editable}
                style={{ "marginLeft": "9px" }}
                value={value}
                label='Audit Date'
                hinttext={label}
                onChange={updateStore}
            />
        </MuiPickersUtilsProvider>
    );
}

const ClassificationsModal = ({
    openDialog,
    classes,
    feedbackStore,
    feedbackActionStore,
    onConfirm,
    onCancel,
}) => {
    return (
        <Dialog
            open={feedbackActionStore.openDialog === openDialog}
            onClose={onCancel}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">{feedbackStore.header}</DialogTitle>
            <DialogContent>
                {renderDropdown(classes, feedbackActionStore)}
                <TextField multiline={true} variant="outlined" rows={5} autoFocus
                    onChange={(event) => { feedbackActionStore.updateComments(event.target.value) }}
                    style={{ "marginTop": "5px" }} margin="dense"
                    id="reason" label="Audit Comments"
                    fullWidth
                />
                {datePicker('Audit Date', feedbackActionStore.acceptedDate, true, feedbackActionStore.updateDate)}
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary">Cancel</Button>
                <Button disabled={!feedbackActionStore.validate} onClick={onConfirm} color="primary">Confirm</Button>
            </DialogActions>
        </Dialog>
    );
}
export default observer(ClassificationsModal);