import React, { Component, Fragment } from "react";
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import { inject, observer } from "mobx-react";

import { text, password } from './InputElements';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing.unit * 2,
    },
    button: {
        margin: theme.spacing.unit,
    },

});
@inject("appStore")
@observer
class LoginUIContainer extends Component {

    onSubmit = async () => {
        const store = this.props.appStore;
        await store.authenticate();
    }

    renderMessage = () => {
        const store = this.props.appStore;
        const message = store.validationMessage;

        switch (store.state) {
            case 'error':
                {
                    return (
                        <Typography gutterBottom variant="body1" color="error" >{message}</Typography>
                    )
                }
            case 'pending':
                {
                    return (
                        <Typography gutterBottom variant="body1" color="secondary" >{message}</Typography>
                    )
                }
            case 'done':
                {
                    return (
                        <Typography gutterBottom variant="body1" color="primary" >{message}</Typography>
                    )
                }
            default:
                {
                    return (
                        <Typography gutterBottom variant="body1" color="primary" >{message}</Typography>
                    )
                }
        }

    }

    renderLoginForm() {
        const { classes } = this.props;

        const appStore = this.props.appStore;
        const loginStore = appStore.loginStore;
        const loginCredentials = loginStore.loginCredentials;
       
        return (
            <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ padding: '40px' }}>
                <Typography gutterBottom variant="h5" >
                    Quality Management And Reporting System
                </Typography>
                {text('email', loginCredentials, true, classes, false, false)}
                {password('password', loginCredentials, classes)}
                <Button variant="contained" disabled={!appStore.isEditable} onClick={this.onSubmit}  className={classes.button}>
                    Login
                </Button>
                {this.renderMessage()}
            </Grid>
        )
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Paper>
                    {this.renderLoginForm()}
                </Paper>
            </div>
        )
    }
}
LoginUIContainer.propTypes = {
    classes: PropTypes.object.isRequired
}
export default withStyles(styles)(LoginUIContainer);
