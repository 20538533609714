import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { AppBar, Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Grid, Chip } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { dimensionStore } from './stores/DimensionStore';
import { observer, inject } from 'mobx-react';
import { humanizeDate } from './stores/Util';

const styles = theme => ({
    root: {
        height: window.innerHeight / 2.5,
        width: '100%',
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    grow: {
        flexGrow: 1,
    },
    progress: {
        marginLeft: '50%'
    }
});

@inject("actionItemListStore")
@observer
class SubmittedActionItems extends Component {

    render_items() {
        const store = this.props.actionItemListStore;
        const items = store.actionItems;
        const renderItems = [];
        items.map((item, index) => { renderItems.push(this.render_action_item(item, index)) });
        return renderItems;
    }

    render_action_item(actionItem) {
        const height = dimensionStore.height * (45/100);
        const panelHeight = dimensionStore.height *(50/100);
        return (
            <ExpansionPanel expanded = {actionItem.expanded} onChange = {(event,expanded)=>{actionItem.updateExpanded(expanded)}}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid container>
                        <Grid item xs>
                            <Typography variant="subtitle1">
                                {actionItem.action}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">
                                {humanizeDate(actionItem.expectedCompletionDate)}
                            </Typography>
                        </Grid>
                    </Grid>
                </ExpansionPanelSummary>
                <Divider />
                <ExpansionPanelDetails style={{...{height:panelHeight}}}>
                    <Typography style={{ ...{height:height,maxHeight:height}, 'overflow': 'auto' }}> {actionItem.description}</Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }

    render_header() {
        const store = this.props.actionItemListStore;
        const displayName = store.feedbackId === 0 ? 'Please Select a Feedback to see its actionItems' : 'ActionItems For Feedback - ' + store.feedbackId;
        const { classes } = this.props;
        return (<AppBar position="static" color="secondary">
            <Toolbar variant="dense">
                <Typography variant="subtitle1" color="inherit" className={classes.grow}>
                    {displayName}
                </Typography>
            </Toolbar>
        </AppBar>);
    }

    render() {
        const { classes } = this.props;
        return (
            <Fragment>
                {this.render_header()}
                <div className={classes.root}>
                    <div>{this.render_items()}</div>
                </div>
            </Fragment>
        );
    }
}

export default withStyles(styles)(SubmittedActionItems);