import React,{ Component } from "react";
import { Grid, withStyles, CircularProgress } from "@material-ui/core";
import { tag } from "./InputElements";
import { observer } from "mobx-react";
import { LOADING, ERROR } from "./stores/LabelStore";
import { feedbackStore } from "./stores/FeedbackStore";

const styles = theme => ({

});

@observer
class FeedbackInfo extends Component{

    render() {
        const feedback = feedbackStore.selectedFeedback;
        
        if (feedbackStore.detailState === LOADING) {
            return <CircularProgress style={{ "marginTop": "25%", "marginLeft": "50%", "marginBottom": "15%" }} />
        }

        if(feedbackStore.detailState === ERROR)
        {
            return (<div><p style={{ "display": "grid", "alignItems": "center", "justifyContent": "center" }} >Error while loading info.</p></div>);
        }
        
        return (
            <div style={{ "marginLeft": "25px" }}>
                <Grid item xs={12}>
                    {tag("Name", feedback.name)}
                    {tag("Provider Role", feedback.providerRole)}
                    {tag("Project Manager", feedback.projectManager)}
                    {tag("Project Tag", feedback.projectTag)}
                    {tag("Feedback Type", feedback.feedbackType)}
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(FeedbackInfo);