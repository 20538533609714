import moment from 'moment';

export function humanizeDate(rawDate) {
  const m  = new moment(rawDate);
  return m.format('DD-MMM-YYYY');
}

export function isBlank(str) {
  return (!str || 0 === str.trim().length || /^\s*$/.test(str));
}

export const toUrlParams = params => new URLSearchParams(params).toString();