
const labels = {
  feedbackLabels:{

  },

};

const feedbackLabels = {
  id:"Feedback Number",
  name: "Subject",
  feedbackOn: "Feedback On",
  feedbackType: "Kind",
  severity:"Severity",
  tags:"Tags",
  publisherId:"Publisher",
  divisionId:"Division",
  functionIds: "Functions",
  functionId: "Function",
  content:"Context from Mail",
  publisher:"Publisher",
  division:"Division",
  projectName:"Project",
  action: "Action",
  reminderDate: "Reminder Date",
  description: "Description",
  expectedCompletionDate: "Expected End Date",
  auditDate:"Audit Date",
  save:"Save",
  rootCauseUsers: 'Users',
  email:'Email',
  password:'Password',
  providerRole:'Provider Role',
  projectTag:'Project',
  projectManager:'Project Manager',
  user:'User',
  userId:'User',
  isOwner: 'Type',
  classification: 'Classification',
  subClassification: 'SubClassification',
  rootCauseTag: 'Tag',
  rootCauseFrom: 'Category',
  rootCauseSubName: 'Sub-Tag'
};

export const actionItemsLabels = {
  action: "action",
  id: "id",
  description: "description"
}

export const feedbackOns = [
  {
    id: 'delivery',
    name: 'Delivery',
  },
  {
    id: 'quality',
    name: 'Quality',
  },
  {
    id: 'communication',
    name: 'Communication',
  },
];

export const feedbackKinds = [
  {
    id: 'positive',
    name: 'Positive',
  },
  {
    id: 'negative',
    name: 'Negative',
  },
];

export const feedbackSeverities = [
  {
    id: 'critical',
    name: 'Critical',
  },
  {
    id: 'print failure',
    name: 'Print Failure',
  },
  {
    id: 'escalation',
    name: 'Escalation',
  },
  {
    id: 'moderate',
    name: 'Moderate',
  },
  {
    id: 'minor',
    name: 'Minor',
  },
  {
    id: 'not applicable',
    name: 'Not Applicable',
  },
];

export const yesNo = [
  {
    id:'to',
    name:'TO'
  },
  {
    id:'cc',
    name:'CC'
  },
  {
    id:'bcc',
    name:'BCC'
  }

];

export const NO_MAIL_IS_SELECTED = "Please select an unassigned feedback mail";
export const rootCauseLabelMap = {
  name:'Name',
}

export const INIT = 'init';
export const LOADING = 'loading';
export const DONE = 'done';
export const ERROR = 'error';
export const REJECT_DIALOG_FLAG = 'showRejectDialog';
export const ACCEPT_DIALOG_FLAG = 'showAcceptDialog';
export const COMPLETE_DIALOG_FLAG = 'showCompleteDialog';

export default function labelOf(fieldId) {

  return feedbackLabels[fieldId];
}
