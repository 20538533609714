import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import MailIcon from '@material-ui/icons/Mail';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
import { AgGridReact } from 'ag-grid-react';
import { humanizeDate } from './stores/Util.js';
import { observer, inject } from 'mobx-react';
import {appStore} from './stores/AppStore';
import {rootCauseTreeStore} from './stores/RootCauseTreeStore';
import {feedbackInteractionStore} from './stores/FeedbackInteractionStore';
import Refresh from '@material-ui/icons/Refresh';
import { IconButton } from '@material-ui/core';
import { feedbackStore } from './stores/FeedbackStore';
import { StringFilter } from './filters/StringFilter.js';

const styles = theme => ({
  root: {
    height: window.innerHeight / 2.5,
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
});

const columnDefs = [
  { headerName: "Circulated Date", field: "createdAt", width: 150, sort: 'asc', valueFormatter: params => humanizeDate(params.value)  },
  { headerName: "ID",width:100,field: "feedbackId",filterFramework: StringFilter},
  { headerName: "Publisher", field: "publisherName" ,filterFramework: StringFilter},
  { headerName: "Division", field: "divisionName" ,filterFramework: StringFilter},
  { headerName: "Function", field: "functionName",filterFramework: StringFilter },
  { headerName: "Age", width:100,field: "ageDays",filterFramework: StringFilter },
  { headerName: "Severity", field: "severity",filterFramework: StringFilter},
  { headerName: "On", field: "feedbackOn",filterFramework: StringFilter},
  { headerName: "Type", field: "feedbackType",filterFramework: StringFilter }
];

@observer
class AssignedFeedbacks extends Component {

  onGridReady = (params) => {
    if (!params) {
      return;
    }
    
    this.gridApi = params.api;
    this.gridApi.addEventListener('rowClicked', this.feedbackSelected);
    feedbackStore.fetchAssignedFeedbacks(appStore.credentials.userId);
  }


  feedbackSelected = (params) => {
    const data = params.data;
    feedbackStore.reset();
    feedbackStore.fetchFeedbackPeople(data.feedbackId,data);
    rootCauseTreeStore.fetchRootCauses(data);
    feedbackInteractionStore.fetchInteractions(data.feedbackId,data.id);
  }


  renderAgGrid() {

    const { classes } = this.props;
    const grid_classes = [classes.root, "ag-theme-material"].join(' ');

    if (!this.params) {
      return (
        <div className={grid_classes} >
          <AgGridReact columnDefs={columnDefs} 
                       rowSelection={'single'} 
                       onGridReady={this.onGridReady} enableSorting={true} 
                       enableFilter={true} />
        </div>
      );
    }
  }

  renderRowData(assignedFeedbacks) {
    if (this.gridApi) {
      this.gridApi.setRowData(assignedFeedbacks);
    }
  }

  render() {
    const assignedFeedbacks = feedbackStore.assignedFeedbacks;
   
    const { classes } = this.props;
    return (
      <Fragment>
        <AppBar position="static" color="secondary">
          <Toolbar variant="dense">
            <Typography variant="subtitle1" color="inherit" className={classes.grow}>
              Assigned Feedbacks
          </Typography>
          <IconButton color="inherit" onClick={feedbackStore.refresh}><Refresh /></IconButton>
          <IconButton color="inherit" onClick={()=>feedbackStore.setUserEmails('to')}><p style={{"fontSize":"1rem", "marginBottom":"-2px"}}>To</p><MailOutlinedIcon /></IconButton>
          {/* <p style={{"marginTop":"-10px", "marginLeft":"-3px", "marginRight":"10px"}}><Badge badgeContent={feedbackStore.rowCount} max={10000} color="primary"></Badge></p> */}
          <IconButton color="inherit" onClick={()=>feedbackStore.setUserEmails('cc')}><p style={{"fontSize":"1rem", "marginBottom":"-2px"}}>Cc</p><MailOutlinedIcon /></IconButton>
          {/* <p style={{"marginTop":"-10px", "marginLeft":"-3px", "marginRight":"10px"}}><Badge badgeContent={feedbackStore.rowCount} max={10000} color="primary"></Badge></p> */}
          </Toolbar>
        </AppBar>
        {this.renderAgGrid()}
        {this.renderRowData(assignedFeedbacks)}
      </Fragment>
    );
  }
}

AssignedFeedbacks.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AssignedFeedbacks);
