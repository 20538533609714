import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import Refresh from '@material-ui/icons/Refresh';
import MailIcon from '@material-ui/icons/Mail';

import { AgGridReact } from 'ag-grid-react';
import { humanizeDate } from './stores/Util.js';
import { observer, inject } from 'mobx-react';

import {feedbackInteractionStore} from './stores/FeedbackInteractionStore';
import { feedbackStore } from './stores/FeedbackStore';

const styles = theme => ({
  root: {
    height: window.innerHeight / 2.5,
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
});

const columnDefs = [
  { headerName: "Feedback Date", field: "feedbackDate", width: 120, sort: 'asc', valueFormatter: params => humanizeDate(params.data["feedbackDate"]) },
  { headerName: "Ref Id", field: "feedbackId", width: 100 },
  { headerName: "Feedback", field: "feedbackName"},
  { headerName: "Type", field: "proposalType"},
  { headerName: "Manager Remark", field: "managerRemark"},
];

@inject("correspondenceStore")
@observer
class PendingProposals extends Component {

  constructor(props) {
    super(props);
  }

  onGridReady = (params) => {
    if (!params) {
      return;
    }

    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.addEventListener('rowClicked', this.mailSelected);

    this.props.correspondenceStore.fetchPendingProposals();
  }

  refresh = () => {
    this.props.correspondenceStore.fetchPendingProposals();
  }

  mailHeight = () => {
    const mailHeight = this.props.correspondenceStore.height;
    return { height: mailHeight };
  }

  mailSelected = (params) => {
    console.log(params.data);
    const mailId = params.data.correspondenceId;
    const feedbackId = params.data.feedbackId;
    const dummyData = [{"messageId":"dddd","body":{"content":"dd", "dueDate":new Date()}}];
    console.log(mailId);
    this.props.correspondenceStore.fetchCorrespondence(mailId);
    feedbackStore.fetchFeedbackPeople(feedbackId);
    feedbackInteractionStore.fetchInteractions(feedbackId);
  }

  renderAgGrid() {

    const { classes } = this.props;
    const grid_classes = [classes.root, "ag-theme-material"].join(' ');

    if (!this.params) {
      return (
        <div className={grid_classes} style={this.mailHeight()}>
          <AgGridReact columnDefs={columnDefs} rowSelection={'single'} onGridReady={this.onGridReady} enableSorting={true} />
        </div>
      );
    }
  }

  renderRowData(pendingProposals) {
    if (this.gridApi) {
      this.gridApi.setRowData(pendingProposals);
    }
  }

  render() {
    const store = this.props.correspondenceStore
    const pendingProposals = store.pendingProposals;

    const { classes } = this.props;
    return (
      <Fragment>
        <AppBar position="static" color="secondary">
          <Toolbar variant="dense">
            <Typography variant="subtitle1" color="inherit" className={classes.grow}>
              Proposals Received From Team
          </Typography>
            <IconButton color="inherit" onClick={this.refresh}><Refresh /></IconButton>
            <Badge badgeContent={store.rowCount} color="primary">
              <MailIcon />
            </Badge>
          </Toolbar>
        </AppBar>
        {this.renderAgGrid()}
        {this.renderRowData(pendingProposals)}
      </Fragment>
    );
  }
}

PendingProposals.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PendingProposals);
