import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import { feedbackInteractionStore } from './stores/FeedbackInteractionStore';

import InteractionLineItem from './InteractionLineItem'

import { observer } from "mobx-react";
import { LOADING, ERROR } from './stores/LabelStore';
import { CircularProgress } from '@material-ui/core';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
});

@observer
class FeedbackInteractions extends Component {

    render() {
        const { classes } = this.props;

        const interactions = feedbackInteractionStore.interactions

        if (feedbackInteractionStore.pageState === LOADING) {
            return <CircularProgress style={{ "marginTop": "25%", "marginLeft": "50%", "marginBottom": "15%" }} />
        }

        if(feedbackInteractionStore.pageState === ERROR){
            return (<div><p style={{ "display": "grid", "alignItems": "center", "justifyContent": "center" }} >Error while loading.</p></div>);
        }

        return (
            <Paper className={classes.root}>
                <Grid item xs={12} id="interactions">
                    {interactions.map(item => {
                        return <InteractionLineItem key={item.actionId} interaction={item} />
                    })}
                </Grid>
            </Paper>
        );
    }
}

FeedbackInteractions.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FeedbackInteractions);