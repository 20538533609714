import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import Refresh from '@material-ui/icons/Refresh';
import Add from '@material-ui/icons/Add';
import PeopleIcon from '@material-ui/icons/People';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AsyncSelect from 'react-select/lib/Async';

import { AgGridReact } from 'ag-grid-react';

import { observer, inject } from 'mobx-react';

import { reactionText, selectableText } from './InputElements'

import { yesNo } from './stores/LabelStore';
import { userStore } from './stores/UsersStore';
import { StringFilter } from './filters/StringFilter';


const styles = theme => ({
    root: {
        height: window.innerHeight,
        width: '100%',
    },
    grow: {
        flexGrow: 1,
    },
});


const renderType = (data) =>{
    return (
        <span>{data.value}</span>
    )
}


const columnDefs = [
    {id: "ID",field: "id",filterFramework: StringFilter, width: 100 },
    { headerName: "Publisher", field: "publisherName",filterFramework: StringFilter, width: 300 },
    { headerName: "Division", field: "divisionName",filterFramework: StringFilter },
    { headerName: "Function Group", field: "functionGroupName",filterFramework: StringFilter },
    { headerName: "Function", field: "functionName",filterFramework: StringFilter},
    { headerName: "User", field: "userName",filterFramework: StringFilter, width: 300 },
    { headerName: "Owner", field: "owner_type",cellRendererFramework: renderType, width: 125 }
];

@inject("distributionListStore")
@observer
class FunctionMappingUIContainer extends Component {

    onGridReady = (params) => {
        if (!params) {
            return;
        }

        this.gridApi = params.api;
        this.gridApi.addEventListener('rowClicked', this.entitySelected);

        this.props.distributionListStore.fetchEntities();
    }

    refresh = () => {
        this.props.distributionListStore.fetchEntities();
    }

    add = () => {
        this.props.distributionListStore.newEntity();
    }


    entitySelected = (params) => {
        this.props.distributionListStore.fetchSelectedEntity(params.data.id);
    }

    handleClose = () => {
        const store = this.props.distributionListStore;
        store.isDialogOpen = false;
    }

    handleDelete = async () => {
        const store = this.props.distributionListStore;
        await store.delete();
        this.refresh();
    }

    handleSave = async () => {
        const store = this.props.distributionListStore;
        await store.save();
        this.refresh();
    }

    renderEditDialog = () => {

        const { classes } = this.props;

        const store = this.props.distributionListStore;
        const isDialogOpen = store.isDialogOpen;
        const entity = store.entity;

        const publishers = store.publishers;
        const divisions = store.divisions;
        const functions = store.functions;

        const editable = true;

        const title = entity.id === 0 ? "New Mapping" : "Edit Mapping";
    
        return (
            <Dialog
                open={isDialogOpen}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    {reactionText("publisherId", entity, store.setPublisherId, editable, publishers, classes)}
                    {reactionText("divisionId", entity, store.setDivisionId, editable, divisions, classes)}
                    {reactionText("functionId", entity, store.setFunctionId, editable, functions, classes)}
                    {this.renderAsyncSelect()}
                    {selectableText("ownerType", entity, editable, yesNo, classes)}
                </DialogContent>
                <DialogActions>
                    <Button disabled={store.entity.id === 0} onClick = {this.handleDelete} color="primary"> Delete</Button>
                    <Button onClick={this.handleClose} color="primary"> Cancel</Button>
                    <Button disabled={!store.canSave} onClick={this.handleSave} color="primary" autoFocus>Save</Button>
                </DialogActions>
            </Dialog>

        );
    }

    renderAsyncSelect = () => {
        const { distributionListStore } = this.props;

        return (
            <div>
                <Typography variant='caption'>Select User</Typography>
                <AsyncSelect
                    value={distributionListStore.selectedUser}
                    onChange={distributionListStore.setUser}
                    defaultOptions
                    placeholder='Search / Select'
                    cacheOptions
                    loadOptions={userStore.filterUsers}
                />
            </div>
        );
    }


    renderAgGrid() {

        const { classes } = this.props;
        const grid_classes = [classes.root, "ag-theme-material"].join(' ');

        if (!this.params) {
            return (
                <div className={grid_classes}>
                    <AgGridReact
                        columnDefs={columnDefs}
                        rowSelection={'single'}
                        onGridReady={this.onGridReady}
                        enableSorting={true}
                        animateRows={true}
                        enableFilter={true}
                        enableColResize={true}
                        rowGroupPanelShow={'always'} />
                </div>
            );
        }
    }

    renderRowData(entities) {

        if (this.gridApi && entities.length > 0) {
            this.gridApi.setRowData(entities);
        }
    }

    render() {
        const store = this.props.distributionListStore;
        const entities = store.entities;

        const { classes } = this.props;
        return (
            <Fragment>
                <AppBar position="static" color="secondary">
                    <Toolbar variant="dense">
                        <Typography variant="subtitle1" color="inherit" className={classes.grow}>
                            Distribution List
                        </Typography>
                        <IconButton color="primary" onClick={this.add}><Add /></IconButton>
                        <IconButton color="inherit" onClick={this.refresh}><Refresh /></IconButton>
                        <Badge badgeContent={store.rowCount} color="primary">
                            <PeopleIcon />
                        </Badge>
                    </Toolbar>
                </AppBar>
                {this.renderAgGrid()}
                {this.renderRowData(entities)}
                {this.renderEditDialog()}
            </Fragment>
        );
    }
}
FunctionMappingUIContainer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FunctionMappingUIContainer);
