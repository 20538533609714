import { INIT, LOADING, ERROR, DONE } from "./LabelStore";
import { snackBarStore } from "./SnackBarStore";
import { observable, decorate } from "mobx";
import { feedbackStore } from "./FeedbackStore";
import { feedbackActionStore } from "./FeedbackActionStore";
import { rootCauseTreeStore } from "./RootCauseTreeStore";
import { actionItemListStore } from "./ActionItemTreeStore";
import { feedbackInteractionStore } from "./FeedbackInteractionStore";
import { appStore } from "./AppStore";

class FeedbackListStore{

    feedbacks = [];
    selectedFeedbackId = 0;
    selectedFeedback = {};
    pageState = INIT;

    fetchFeedbacks = async (url) => {
        try {
            this.pageState = LOADING;
            this.reset();
            const response = await appStore.apiProxy.getAsync(url);
            const data = await response.json();
            this.feedbacks = data;
            this.pageState = DONE;
        } catch (e) {
            this.pageState = ERROR;
            this.feedbacks = [];
            snackBarStore.showErrorMessage('unable to fetch feedbacks.');
            console.log(e);
        }
    }

    reset = () => {
        this.selectedFeedbackId = 0;
        this.pageState=INIT;
        this.selectedFeedback = {};
        feedbackStore.reset();
        feedbackActionStore.reset();
        rootCauseTreeStore.reset();
        actionItemListStore.reset();
        feedbackInteractionStore.updateOpenDialog('');
        feedbackActionStore.reset();
    }

    setSelectedFeedbackId = (id,selectedFeedack) => {
        this.reset();
        this.selectedFeedbackId = id;
        this.selectedFeedback = selectedFeedack;
        feedbackStore.fetchFeedbackPeople(id);
        feedbackInteractionStore.fetchInteractions(id, 0);
        rootCauseTreeStore.fetchSubmittedRootCauses(id);
        actionItemListStore.fetchSubmittedActionItems(id);
    }
}
decorate(FeedbackListStore,{
    feedbacks: observable,
    pageState: observable,
    selectedFeedbackId: observable,
})

export const feedbackListStore = new FeedbackListStore();