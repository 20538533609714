import React, { Component } from "react";
import { Grid, Typography, withStyles, Chip } from "@material-ui/core";
import { humanizeDate } from './stores/Util.js';
import { dimensionStore } from "./stores/DimensionStore.js";
import { observer, inject } from "mobx-react";
import { appStore } from './stores/AppStore';
import { getDownloadAttachmentUrl } from './stores/APIEndpoints';
import { Button } from 'antd';


const styles = theme => ({
    root: {
        height: dimensionStore.height,
        padding: theme.spacing.unit * 2,
    },
    grow: {
        flexGrow: 1,
    },
    chip: {
        marginRight: theme.spacing.unit,
    },
    content: {
        height: dimensionStore.height * dimensionStore.content_ratio,
        overflow: "auto",
        maxHeight: dimensionStore.height * dimensionStore.content_ratio,
    },
});

var attachmentDownloadPayload = {};

@inject("correspondenceStore")
@observer
class MailWidget extends Component {

    renderRecipients(correspondence) {
        const { classes } = this.props;
            return (
                <Grid item xs={12} id="recipients">
                    <div style={{"display": "flex","flexDirection": 'row' ,'overflow': 'hidden','overflowX': 'scroll'}}>{correspondence.mailRecipients.map(item => {
                        return <Chip key={item.id} className={classes.chip} label={item.email} />
                    })}</div>
                </Grid>
            )
    }

      downloadAttachment = (messageId, zimbraFolder, companyId) => {
        var myHeaders = new Headers();
        myHeaders.append("email", appStore.credentials.email);
        myHeaders.append("token", appStore.credentials.token);

        const requestOptions = {
          method: 'GET',
          headers: myHeaders
        };
        console.log("email", appStore.credentials.email);
        console.log("token", appStore.credentials.token);

          fetch(getDownloadAttachmentUrl + "?messageId=" + messageId + "&zimbraFolder=" + zimbraFolder + "&companyId=" + companyId, requestOptions)
          .then((res) => {
            return res.blob();
          })
          .then((blob) => {
            const href = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', messageId+'.zip'); //or any other extension
            document.body.appendChild(link)
;
            link.click();
          })
          .catch((err) => {
            return Promise.reject({ Error: 'Something Went Wrong', err });
          })
      };


    render() {
        let { classes, correspondence } = this.props;

        console.log(1111);
        console.log(correspondence);

        if(correspondence === undefined)
        {
            return (
                <Typography variant="subtitle2">
                    Please Select Feedback.
                </Typography>
            );
        }
        return (
            <div className={classes.root} style={{"height" : "315px"}}>
                <Grid container>
                    <Grid item xs>
                        <Typography variant="subtitle1">
                            From: {correspondence.sender}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle2">
                            {humanizeDate(correspondence.mailDate)}
                        </Typography>
                    </Grid>
                </Grid>
                {correspondence.mailRecipients && this.renderRecipients(correspondence)}
                <hr />
                <div className={classes.content} style={dimensionStore.contentHeight()} dangerouslySetInnerHTML={{ __html: correspondence.content }}></div> 
                { correspondence.attachedFile && <div style={{"margin-top" : "20px"}} onClick = {() => {this.downloadAttachment(correspondence.messageId, this.props.correspondenceStore.selectedFolder)}} dangerouslySetInnerHTML={{ __html: correspondence.attachedFile.join(", ") }}/> }
                { correspondence.attachedFile && <Button type="primary" onClick = {() => {this.downloadAttachment(correspondence.messageId, this.props.correspondenceStore.selectedFolder)}}>Download</Button>}
            </div>
        )
    }
}

export default withStyles(styles)(MailWidget);