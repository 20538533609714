import React,{ Component } from "react";
import PendingForAudit from "./PendingForAudit";
import PendingForAuditDetailsView from "./PendingForAuditDetailsView";
import SubmittedRootCauseContainer from "./SubmittedRootCauseContainer";
import SubmittedActionItems from "./SubmittedActionItems";

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import { Provider, inject, observer } from 'mobx-react';

import { rootCauseTreeStore } from './stores/RootCauseTreeStore';
import { actionItemListStore } from './stores/ActionItemTreeStore';
import { feedbackStore } from './stores/FeedbackStore';
import { feedbackInteractionStore } from './stores/FeedbackInteractionStore';
import { feedbackActionStore } from "./stores/FeedbackActionStore";
import { feedbackListStore } from "./stores/FeedbackListStore";

const styles = theme => ({
    paper: {
        padding: theme.spacing.unit,
        height: window.innerHeight / 2.5,
    },
    root: {
        padding: theme.spacing.unit,
    },
});

@inject("dimensionStore","appStore")
@observer
class PendingForAuditUIContainer extends Component{
    
    constructor(props) {
        super(props);
        const { dimensionStore } = props;
        dimensionStore.setDimension(window.innerHeight / 2.5, window.innerHeight / 2.5);
    }

    renderBottomDiv() {
        return (
            <Grid item xs={12}>
                <Grid container spacing={16}>
                    <Grid item xs={6}>
                        <SubmittedRootCauseContainer />
                    </Grid>
                    <Grid item xs={6}>
                        <SubmittedActionItems />
                    </Grid>
                </Grid>
            </Grid>
        );
    }
    
    render() {
        const { classes,dimensionStore } = this.props;
        const mailWidth = dimensionStore.mailWidth;
        return (
            <Provider rootCauseTreeStore={rootCauseTreeStore}
                actionItemListStore={actionItemListStore} feedbackStore={feedbackStore} 
                    dimensionStore = {dimensionStore} feedbackInteractionStore = {feedbackInteractionStore}
                    feedbackActionStore={feedbackActionStore} feedbackListStore={feedbackListStore}>
                <div className={classes.root}>
                    <Grid container spacing={16}>
                        <Grid item xs={12 - mailWidth}>
                            <Paper>
                                <PendingForAudit />
                            </Paper>
                        </Grid>
                        <Grid item xs={mailWidth}>
                            <Paper>
                                <PendingForAuditDetailsView />
                            </Paper>
                        </Grid>
                        {this.renderBottomDiv()}
                    </Grid>
                </div>
            </Provider >
        );
    }
}
export default withStyles(styles)(PendingForAuditUIContainer);