import React, { Component } from 'react';
import { observer, Provider, inject } from 'mobx-react';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import SubmittedFeedbacks from './SubmittedFeedbacks';
import SubmittedRootCauseContainer from './SubmittedRootCauseContainer';
import SubmittedActionItems from './SubmittedActionItems';
import SubmittedDetailsManagerView from './SubmittedDetailsManagerView';

import { rootCauseTreeStore } from './stores/RootCauseTreeStore';
import { actionItemListStore } from './stores/ActionItemTreeStore';
import { feedbackStore } from './stores/FeedbackStore';
import { feedbackInteractionStore } from './stores/FeedbackInteractionStore';
import SubmittedDetailsActorView from './SubmittedDetailsActorView';
import { feedbackListStore } from './stores/FeedbackListStore';
import { feedbackActionStore } from './stores/FeedbackActionStore';

const styles = theme => ({
    paper: {
        padding: theme.spacing.unit,
        height: window.innerHeight / 2.5,
    },
    root: {
        padding: theme.spacing.unit,
    },
});

@inject("appStore")
@inject("dimensionStore")
@observer
class SubmittedFeedbackUIContainer extends Component {

    constructor(props) {
        super(props);
        const { dimensionStore } = props;
        dimensionStore.setDimension(window.innerHeight / 2.5, window.innerHeight / 2.5);
    }

    renderDetailsView() {
        const { appStore } = this.props;
        return appStore.credentials.role === "actor" ? <SubmittedDetailsActorView /> :  <SubmittedDetailsManagerView />;
    }

    renderBottomDiv() {
        return (
            <Grid item xs={12}>
                <Grid container spacing={16}>
                    <Grid item xs={6}>
                        <SubmittedRootCauseContainer />
                    </Grid>
                    <Grid item xs={6}>
                        <SubmittedActionItems />
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    render() {
        const { classes, dimensionStore } = this.props;
        const mailWidth = dimensionStore.mailWidth;
        return (
            <Provider rootCauseTreeStore={rootCauseTreeStore}
                actionItemListStore={actionItemListStore} feedbackStore={feedbackStore}
                dimensionStore={dimensionStore} feedbackInteractionStore={feedbackInteractionStore}
                feedbackListStore = {feedbackListStore} feedbackActionStore = {feedbackActionStore}
                >
                <div className={classes.root}>
                    <Grid container spacing={16}>
                        <Grid item xs={12 - mailWidth}>
                            <Paper>
                                <SubmittedFeedbacks />
                            </Paper>
                        </Grid>
                        <Grid item xs={mailWidth}>
                            <Paper>
                                {this.renderDetailsView()}
                            </Paper>
                        </Grid>
                        {this.renderBottomDiv()}
                    </Grid>
                </div>
            </Provider >
        );
    }
}

export default withStyles(styles)(SubmittedFeedbackUIContainer);