import { decorate, observable, flow, computed, action } from 'mobx';
import { feedbackObservationUrl } from './APIEndpoints';

const PENDING = 'pending';
const DONE = 'done';
const ERROR = 'error';


export default class ObservationStore {

    observationData = [];
    state = PENDING;
    rowCount = 0;

    constructor(props) {
        this.apiProxy = props.apiProxy;
    }

    fetchFeedbacks = flow(function* () {
        this.state = PENDING;
        try {
            const response = yield this.apiProxy.getAsync(feedbackObservationUrl);
            let data = yield response.json();
            console.log("DATA DATA", data);
            for(var i=0;i<data.length;i++)
            {
                var oneRow = data[i];
                var performerNames = oneRow["performerName"] + "" ;
                performerNames = performerNames.replace(/ /g, "");
                oneRow["performerName"] = performerNames.split(",").filter((v, i, a) => a.indexOf(v) === i).join(", ");

                var performerTeam = oneRow["primaryPerformerTeam"] + "" ;
                performerTeam = performerTeam.replace(/ /g, "");
                oneRow["primaryPerformerTeam"] = performerTeam.split(",").filter((v, i, a) => a.indexOf(v) === i).join(", ");

                var secondaryName = oneRow["secondaryName"] + "" ;
                secondaryName = secondaryName.replace(/ /g, "");
                oneRow["secondaryName"] = secondaryName.split(",").filter((v, i, a) => a.indexOf(v) === i).join(", ");

                var secondaryPerformerTeam = oneRow["secondaryPerformerTeam"] + "" ;
                secondaryPerformerTeam = secondaryPerformerTeam.replace(/ /g, "");
                oneRow["secondaryPerformerTeam"] = secondaryPerformerTeam.split(",").filter((v, i, a) => a.indexOf(v) === i).join(", ");

                var primaryEmpIds = oneRow["primaryEmpId"] + "" ;
                primaryEmpIds = primaryEmpIds.replace(/ /g, "");
                oneRow["primaryEmpId"] = primaryEmpIds.split(",").filter((v, i, a) => a.indexOf(v) === i).join(", ");

                var secondaryEmpIds = oneRow["secondaryEmpId"] + "" ;
                secondaryEmpIds = secondaryEmpIds.replace(/ /g, "");
                oneRow["secondaryEmpId"] = secondaryEmpIds.split(",").filter((v, i, a) => a.indexOf(v) === i).join(", ");
            }
            this.setFeedbacks(data);
        }
        catch (e) {
            this.state = ERROR
            console.log(e);
        };
    });

    setFeedbacks = (data) => {
        this.observationData = data;
        this.rowCount=data.length;
        this.state = DONE;
    }

}

decorate(ObservationStore,{
    state:observable,
    observationData:observable,
    rowCount:observable
})
