import { types, destroy,getParent ,getSnapshot } from 'mobx-state-tree';
import { snackBarStore } from './SnackBarStore';
import {appStore} from './AppStore';
import { actionItemListStore } from './ActionItemTreeStore';
import { saveRootCauseUrl, rootCauseUsersUrl,deleteRootCauseUrl } from './APIEndpoints';
import { INIT, LOADING, ERROR, DONE } from './LabelStore';
import { feedbackStore } from './FeedbackStore';

export const RootCauseUser = types.model({
    userId: types.integer,
    rootCauseId: types.integer,
    name:types.string,
    type:types.integer
});

export const RootCauseStore = types
    .model({
        id: types.optional(types.integer, 0),
        name: types.optional(types.string, ''),
        description: types.optional(types.string, ''),
        feedbackId: types.optional(types.integer, 0),
        feedbackActorId: types.optional(types.integer, 0),
        expanded: types.optional(types.boolean, false),
        stage: types.optional(types.string, ''),
        rootCauseUsers: types.optional(types.array(RootCauseUser),[]),
        rootCauseSecondaryUsers: types.optional(types.array(RootCauseUser),[]),
        editable: types.optional(types.boolean, true),
        functionName: types.optional(types.string, ''),
        rootCauseFrom: types.optional(types.string, ''),
        rootCauseSubName: types.optional(types.string, ''),
        singlePointFailure: types.optional(types.boolean,false),
        pageState: types.optional(types.string, INIT)
    })
    .views(self => {
        return {
            validate() {
                // if(self.singlePointFailure && self.rootCauseUsers.length > 1){
                //     snackBarStore.showErrorMessage('Should have only one user for single point failure.')
                //     return false;
                // }

                // if(!self.singlePointFailure && self.rootCauseUsers.length < 2 ){
                //     snackBarStore.showErrorMessage('Should select min 2 users if not single point failure.')
                //     return false;
                // }

                // if(!self.singlePointFailure && self.rootCauseUsers.length < 2 ){
                //     snackBarStore.showErrorMessage('Should select min 2 users if not single point failure.')
                //     return false;
                // }

                if(!self.singlePointFailure && self.rootCauseSecondaryUsers.length === 0 ){
                    snackBarStore.showErrorMessage('Should select min 1 user in the secondary performer if not single point failure.')
                    return false;
                }

                if (self.name === '') {
                    snackBarStore.showErrorMessage('TagLine should not be blank.');
                    return false;
                }

                if(feedbackStore.selectedFeedback.feedbackType === 'positive'){
                    return true;
                }
                
                if (self.stage === '') {
                    snackBarStore.showErrorMessage('Stage should not be blank.');
                    return false;
                }
                if (self.description === '') {
                    snackBarStore.showErrorMessage('Description should not be blank.');
                    return false;
                }

                if (self.rootCauseUsers.length === 0) {
                    snackBarStore.showErrorMessage('Select users for root cause');
                    return false;
                }

                if (self.rootCauseFrom.length === 0) {
                    snackBarStore.showErrorMessage('Select category');
                    return false;
                }

                return true;
            },

            findExpandedRootCause() {
                let expandedRootCause = undefined;
                getParent(self).map(item => {
                    if (item.expanded && self.id != item.id) {
                        expandedRootCause = item;
                    }
                });
                return expandedRootCause;
            },

            getRootCauseUsersView()
            {
                const array = [];
                self.rootCauseUsers.map(rootCauseUser=>{
                    let map = {};
                    map.label = rootCauseUser.name;
                    map.value = rootCauseUser.userId;
                    array.push(map);
                });
                return array;
            },

            getRootCauseSecondaryUsersView()
            {
                const array = [];
                self.rootCauseSecondaryUsers.map(rootCauseUser=>{
                    let map = {};
                    map.label = rootCauseUser.name;
                    map.value = rootCauseUser.userId;
                    array.push(map);
                });
                return array;
            },

            isNew() {
                return self.id === 0;
            },

            isEditable() {
                if (self.pageState === LOADING) {
                    return false;
                }
                return self.editable;
            }
        }
    })
    .actions(self => {

        function onNameChange(value, label) {
            self[label] = value;
        }

        function onSelectUser(values){
            destroy(self.rootCauseUsers);
            const newRootCauseUsers = [];
            values.map(temp=>{
                var emailLabel = temp.label;
                var emailArr = emailLabel.split("-");
                let tempRootCause = RootCauseUser.create({
                    userId: temp.value,
                    rootCauseId: self.id,
                    //name: temp.label,
                    name: emailArr[0],
                    type: 1
                });
                newRootCauseUsers.push(tempRootCause);
            });
            self.rootCauseUsers = newRootCauseUsers;
        }

        function onSelectSecondaryUser(values){
            destroy(self.rootCauseSecondaryUsers);
            const newRootCauseSecondaryUsers = [];
            values.map(temp=>{
                var emailLabel = temp.label;
                var emailArr = emailLabel.split("-");
                let tempRootCause = RootCauseUser.create({
                    userId: temp.value,
                    rootCauseId: self.id,
                    //name: temp.label,
                    name: emailArr[0],
                    type: 2
                });
                newRootCauseSecondaryUsers.push(tempRootCause);
            });
            self.rootCauseSecondaryUsers = newRootCauseSecondaryUsers;
            console.log(self.rootCauseSecondaryUsers);
        }

        async function onSelect(expanded) {
            if (expanded) {
                await onExpanded();
                return;
            }

            if (!self.isEditable()) {
                self.cancel();
                return;
            }

            await self.closePanel();

            async function onExpanded() {
                const expandedRootCause = self.findExpandedRootCause();
                if (expandedRootCause == undefined) {
                    self.expand();
                    return;
                }

                if (!expandedRootCause.isEditable()) {
                    expandedRootCause.updateExpanded(false);
                    self.expand();
                    return;
                }

                if (!expandedRootCause.validate) {
                    return;
                }

                let saved = await expandedRootCause.save();
                if (!saved) {
                    return;
                }
                expandedRootCause.updateExpanded(false);
                self.expand();
            }
        }

        function updateExpanded(expanded) {
            self.expanded = expanded;
        }

        function expand() {
            self.fetchRootCauseUsers();
            self.expanded = true;
            actionItemListStore.setRootCause(self);
        }

        async function closePanel() {
            const saved = await self.save();
            if (saved) {
                self.cancel();
            }
        }

        function cancel() {
            self.expanded = false;
            actionItemListStore.setRootCause(self);
            if (self.isNew()) {
                self.deleteItem();
            }
        }

        async function deleteRootCause() {
            try {
                self.updatePageState(LOADING);
                if (!self.isNew()) {
                    await appStore.apiProxy.asyncPost(deleteRootCauseUrl, self.id);
                    self.cancel();
                }
                self.deleteItem();
                snackBarStore.showSuccessMessage('Deleted Successfully.');
            } catch (e) {
                self.updatePageState(ERROR);
            }
        }

        function deleteItem() {
            getParent(self, 2).remove(self);
        }

        function updatePageState(pageState) {
            self.pageState = pageState;
        }

        async function save() {
            self.updatePageState(LOADING);
            const valid = self.validate();
            if (!valid) {
                self.updatePageState(ERROR);
                return false;
            }

            try {
                let rootCauseUsersList = getSnapshot(self.rootCauseUsers);
                rootCauseUsersList = rootCauseUsersList.concat(getSnapshot(self.rootCauseSecondaryUsers));
                    if (rootCauseUsersList.length > 1 && rootCauseUsersList[0].name === rootCauseUsersList[1].name){
                        snackBarStore.showErrorMessage('Primary and Secondary Performer Names are Same');
                        self.updatePageState(ERROR);
                        return false;
                    }    
                const rootCauseMap = { rootCause: createRootCauseMap(), rootCauseUsers: rootCauseUsersList };
                const response = await appStore.apiProxy.asyncPost(saveRootCauseUrl, rootCauseMap);
                const data = await response.json();
                self.updateId(data);
                if (rootCauseMap.rootCause.id === 0) {
                    await actionItemListStore.setRootCause(self);
                }
                snackBarStore.showSuccessMessage(self.name + ' saved successfully.');
                self.updatePageState(DONE);
                return true;
            } catch (e) {
                console.log("ttttt", e);
                snackBarStore.showErrorMessage(self.name + ' not saved, try again later.');
                self.updatePageState(ERROR);
                return false;
            }

            function createRootCauseMap() {
                const rootCause = {};
                rootCause.id = self.id;
                rootCause.name = self.name;
                rootCause.feedbackActorId = self.feedbackActorId;
                rootCause.feedbackId = self.feedbackId;
                rootCause.stage = self.stage;
                rootCause.vendorName = self.vendorName;
                rootCause.description = self.description;
                rootCause.singlePointFailure = self.singlePointFailure;
                rootCause.rootCauseFrom = self.rootCauseFrom;
                rootCause.rootCauseSubName = self.rootCauseSubName;
                return rootCause;
            }
        }

        function updateId(data) {
            self.id = data.rootCause.id;
        }

        async function fetchRootCauseUsers() {
            try {
                this.pageState = LOADING;
                if (self.id === 0 || self.rootCauseUsers.length !== 0) {
                    return;
                }
                const response = await appStore.apiProxy.getAsync(rootCauseUsersUrl.replace('#{rootCauseId}', self.id));
                const data = await response.json();
                data.map(item => {
                    const rootCauseUser = RootCauseUser.create({
                        userId: item.userId,
                        rootCauseId: item.rootCauseId,
                        name: item.user.name,
                    });
                    self.addRootCauseUser(rootCauseUser);
                });
                this.pageState=DONE
            } catch (e) {
                this.pageState = ERROR;
            }
        }

        function addRootCauseUser(rootCauseUser) {
            self.rootCauseUsers.push(rootCauseUser);
        }

        return { onSelectUser, onSelectSecondaryUser, updatePageState, updateExpanded, updateId, onSelect, onNameChange, save, cancel, fetchRootCauseUsers, addRootCauseUser,  deleteRootCause, deleteItem, closePanel, expand }
    })