import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { observer } from 'mobx-react';

import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';

import {initHeader} from './InputElements';
import FeedbackDetails from './FeedbackDetails';
import { feedbackInteractionStore } from './stores/FeedbackInteractionStore';
import {LOADING, ERROR} from './stores/LabelStore';
import { dimensionStore } from './stores/DimensionStore';
import { feedbackStore } from './stores/FeedbackStore';
import { date } from 'date-fns/locale/af';
import moment from "moment";


const styles = theme => ({
  root: {
    height: window.innerHeight / 2.5,
    width: '100%',
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  grow: {
    flexGrow: 1,
  },
});


@observer
class FeedbackDetailsActorView extends Component {

  renderHeader() {
    const { classes } = this.props;
    if(feedbackStore.detailState === LOADING || feedbackStore.listState === LOADING)
    {
      return initHeader(this.props,'Loading...');
    }

    if(feedbackStore.detailState === ERROR || feedbackStore.listState === ERROR)
    {
      return initHeader(this.props,'Error while loading feedback...');
    }
    
    return <AppBar position="static" color="secondary" onClick={dimensionStore.adjustSize}>
      <Toolbar variant="dense">
        <Typography variant="subtitle1" color="inherit" className={classes.grow}>
          {feedbackStore.header}
        </Typography>
        {feedbackStore.selectedFeedbackActor.isOwner && this.customDiscardDialog()}
        {feedbackStore.selectedFeedbackActor.isOwner && this.customReassignDialog()}
      </Toolbar>
    </AppBar>;
  }

  render() {
    return (
      <Fragment >
        {this.renderHeader()}
        <FeedbackDetails />
      </Fragment>
    );
  };

  customDiscardDialog = () => {
    return (
      <div>
        <Button variant="contained" color="primary" disabled={feedbackStore.feedbackId <= 0} onClick={()=>{feedbackInteractionStore.handleDiscardDialog(true)}}>Not A Feedback</Button>
        <Dialog
          open={feedbackInteractionStore.showDiscardDialog}
          onClose={()=>{feedbackInteractionStore.handleDiscardDialog(false)}}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Not A Feedback</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To mark this issue as not a feedback, please enter reason.
            </DialogContentText>
            <TextField
              multiline={true}
              variant="outlined"
              rows={5}
              autoFocus
              onChange={(event)=>{feedbackInteractionStore.updateReason(event.target.value)}}
              style={{ "marginTop": "5px" }}
              margin="dense"
              id="reason"
              label="Reason"
              fullWidth
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>{feedbackInteractionStore.handleDiscardDialog(false)}} color="primary">
              Cancel
            </Button>
            <Button onClick={feedbackInteractionStore.onDiscard} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>);
  }

  feedbackDate = () => {
    const feedbackCirculateDate = feedbackStore.circulatedDate;
    const feedbackCirculateDateFormat = moment(feedbackCirculateDate.split('T')[0]);
    const feedbackDay = feedbackCirculateDateFormat.day();
    const todayDate = moment().format("YYYY-MM-DD");
    let dateFormat = feedbackCirculateDateFormat.diff(todayDate, 'days');
    if (feedbackDay == 4 || 5 || 6)  {
      dateFormat = dateFormat + 1
    }
    if (dateFormat < -3) {
      return true
    }
    return false
  }
  //disabled={this.feedbackDate()}
  //disabled={feedbackStore.feedbackId <= 0}
  
  customReassignDialog = () => {
    return (
      <div style={{ "marginLeft": "5px" }}>
        <Button variant="contained" color="primary" disabled={this.feedbackDate()} onClick={()=>{feedbackInteractionStore.handleReassignDialog(true)}}>Reassign</Button>
        <Dialog
          open={feedbackInteractionStore.showReassignDialog}
          onClose={()=>{feedbackInteractionStore.handleReassignDialog(false)}}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Functions to Reassign this feedback</DialogTitle>
          <DialogContent>
            <div>
              {this.renderMultiSelect()}
            </div>
            <div>
              {this.renderFunctions(this.props.classes)}
            </div>
            <TextField
              multiline={true}
              variant="outlined"
              rows={5}
              autoFocus
              onChange={(event)=>{feedbackInteractionStore.updateReason(event.target.value)}}
              style={{ "marginTop": "5px" }}
              margin="dense"
              id="reason"
              label="Reason for reassignment"
              fullWidth
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>{feedbackInteractionStore.handleReassignDialog(false); window.location.reload()}} color="primary">
              Cancel
            </Button>
            <Button onClick={feedbackInteractionStore.onReassign} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>);
  }

  renderMultiSelect = () => {

    const functions = feedbackInteractionStore.functions;
    const selectedFunctionIds = feedbackInteractionStore.selectedFunctionIds;

    const updateStore = (event) => {
      feedbackStore.functionId = event.target.value;
      feedbackInteractionStore.onFunctionSelect([event.target.value]);
    }

    const selectedValues = (selected) => {
      return 'Select Functions';
    }

    return (
      <FormControl style={{ 'maringTop': '10 px' }} >
        <InputLabel htmlFor="select-functions">Functions</InputLabel>
        <Select id='users' required value={selectedFunctionIds}
          onChange={updateStore}
          renderValue={selected => selectedValues(selected)}
        >
          {functions.map(option => (
            <MenuItem key={option.id} value={option.id}>
              <Checkbox checked={selectedFunctionIds.includes(option.id)} />
              <ListItemText primary={option.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
  getfunctionId = (value) => {
    alert(value);
  }

  renderFunctions = () => {
    const { classes } = this.props;
    const functions = feedbackInteractionStore.selectedFunctions();
    return (
      <Grid container style={{ 'marginTop': '10px' }}>
        <Grid item xs={12}>
          <Typography variant='caption'>Selected Functions</Typography>
        </Grid>
        <Grid item xs={12} id="rootCauseUsers">
          {functions.map(item => {
            return <Chip key={item.id} className={classes.chip} label={item.name} />
          })}
        </Grid>
      </Grid>
    )
  }
}

FeedbackDetailsActorView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FeedbackDetailsActorView);