import { INIT, DONE, LOADING } from './LabelStore';
import { appStore } from './AppStore';
import { userPendingStatsUrl } from './APIEndpoints';
import { decorate, observable } from 'mobx';

class FeedbackStatStore {

    userPendingStats = [];
    listState = INIT;

    fetchUserPendingStats = async () => {
        try {
            this.listState  = LOADING;
            this.userPendingStats = [];
            const response = await appStore.apiProxy.getAsync(userPendingStatsUrl);
            const data = await response.json();
            this.userPendingStats = data;
            this.listState = DONE;
        }catch(e){
            console.log(e);
        }
    }
}
decorate(FeedbackStatStore,{
    userPendingStats: observable,
    listState: observable,
})
export const feedbackStatStore = new FeedbackStatStore();
