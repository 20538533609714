import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { observer, inject } from 'mobx-react';

import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import FeedbackDetails from './FeedbackDetails';
import { feedbackInteractionStore } from './stores/FeedbackInteractionStore';
import { feedbackStore } from './stores/FeedbackStore';


const styles = theme => ({
    root: {
        height: window.innerHeight / 2.5,
        width: '100%',
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    grow: {
        flexGrow: 1,
    },
});
@observer
class FeedbackDetailsManagerView extends Component {


    render() {
        const { classes } = this.props;

        return (
            <Fragment>
                <AppBar position="static" color="secondary">
                    <Toolbar variant="dense">
                        <Typography variant="subtitle1" color="inherit" className={classes.grow}>
                            {feedbackStore.header}
                        </Typography>
                        {this.customDiscardDialog()}
                    </Toolbar>
                </AppBar>
                <FeedbackDetails />
            </Fragment>
        );
    };
    customDiscardDialog = () => {
        return (
            <div>
                <Button variant="contained" color="primary" disabled={feedbackStore.feedbackId <= 0} onClick={()=>{feedbackInteractionStore.handleDiscardDialog(true)}} >Action</Button>
                <Dialog
                    open={feedbackInteractionStore.showDiscardDialog}
                    onClose={()=>{feedbackInteractionStore.handleDiscardDialog(false)}}
                    aria-labelledby="form-dialog-title">

                    <DialogTitle id="form-dialog-title">Discard or Reinstate</DialogTitle>

                    <DialogContent>
                        <DialogContentText>
                            To discard this feedback or to reinstate the feedback, please enter a reason.
                        </DialogContentText>
                        <TextField
                            multiline={true}
                            variant="outlined"
                            rows={2}
                            autoFocus
                            onChange={(event)=>{feedbackInteractionStore.updateReason(event.target.value)}}
                            style={{ "marginTop": "5px" }}
                            margin="dense"
                            id="reason"
                            label="Reason"
                            fullWidth/>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={()=>{feedbackInteractionStore.handleDiscardDialog(false)}} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={feedbackInteractionStore.onConfirmDiscard} color="primary">
                            Discard
                        </Button>
                        <Button onClick={feedbackInteractionStore.onReinstateFeedback} color="secondary">
                            Reinstate
                        </Button>
                    </DialogActions>

                </Dialog>
            </div>
        );
    }
}

FeedbackDetailsManagerView.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FeedbackDetailsManagerView);