import React, { Component, Fragment } from "react";

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';

import { inject, observer } from "mobx-react";
import FeedbackDetails from "./FeedbackDetails";
import { REJECT_DIALOG_FLAG, ACCEPT_DIALOG_FLAG } from "./stores/LabelStore";
import ClassificationsModal from "./ClassificationsModal";
import { acceptSubmittedFeedbackUrl } from "./stores/APIEndpoints";

const styles = theme => ({
    root: {
        height: window.innerHeight / 2.5,
        width: '100%',
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    grow: {
        flexGrow: 1,
    }
});

@inject("feedbackStore", "dimensionStore", "feedbackInteractionStore", "feedbackActionStore")
@observer
class SubmittedDetailsManagerView extends Component {
    renderHeader = () => {
        const { classes, feedbackStore, dimensionStore, feedbackInteractionStore,feedbackActionStore } = this.props;
        return (
            <AppBar position="static" color="secondary" onClick={dimensionStore.adjustSize}>
                <Toolbar variant="dense">
                    <Typography variant="subtitle1" color="inherit" className={classes.grow}>
                        {feedbackStore.header}
                    </Typography>
                    {feedbackActionStore.editable && this.acceptButton()}
                    {feedbackInteractionStore.isEditable() && this.rejectDialog()}
                </Toolbar>
            </AppBar>
        );
    }

    acceptButton = () => {
        const {feedbackActionStore,feedbackStore,classes} = this.props;
        return (
            <div>
                <Button variant="contained" color="primary"
                    onClick={this.onAcceptButtonClicked}>Accept</Button>
                    <ClassificationsModal 
                        openDialog={ACCEPT_DIALOG_FLAG}
                        feedbackActionStore={feedbackActionStore} feedbackStore={feedbackStore} classes={classes} 
                        onConfirm = {feedbackActionStore.onAccept} 
                        onCancel = {feedbackActionStore.reset}
                        />
            </div>
        )
    }

    rejectDialog = () => {
        const { feedbackStore, feedbackInteractionStore } = this.props;
        return (
            <div style={{ "marginLeft": "5px" }}>
                <Button variant="contained" color="primary" onClick={() => { feedbackInteractionStore.updateFlag(REJECT_DIALOG_FLAG, true) }}>Reject</Button>
                <Dialog
                    open={feedbackInteractionStore.showRejectDialog}
                    onClose={() => { feedbackInteractionStore.updateFlag(REJECT_DIALOG_FLAG, false) }}
                    aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{feedbackStore.header}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>To reject this submitted feedback, please enter reason.</DialogContentText>
                        <TextField multiline={true} variant="outlined" rows={5} autoFocus
                            onChange={(event) => { feedbackInteractionStore.updateReason(event.target.value) }}
                            style={{ "marginTop": "5px" }} margin="dense"
                            id="reason" label="Reason"
                            fullWidth
                            required
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { feedbackInteractionStore.updateFlag(REJECT_DIALOG_FLAG, false) }} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={feedbackInteractionStore.onReject} color="primary">Confirm</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    onAcceptButtonClicked = () => {
        const { feedbackActionStore } = this.props;
        feedbackActionStore.getClassifications();
        feedbackActionStore.updateOpenDialog(ACCEPT_DIALOG_FLAG);
    }

    render() {
        return (
            <Fragment >
                {this.renderHeader()}
                <FeedbackDetails />
            </Fragment>
        );
    }
}
export default withStyles(styles)(SubmittedDetailsManagerView);