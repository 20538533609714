import { decorate, observable, computed, action } from "mobx";

import { classifactionsUrl, submittedFeedbacksUrl, pendingForAuditUrl, markAsCompleteFeedbackUrl, acceptSubmittedFeedbackUrl, resendCompletedMailUrl } from "./APIEndpoints";
import { INIT, ERROR, DONE, LOADING } from "./LabelStore";

import { appStore } from "./AppStore";
import { feedbackStore } from "./FeedbackStore";
import { feedbackListStore } from "./FeedbackListStore";
import { humanizeDate } from "./Util";
import { snackBarStore } from "./SnackBarStore";


class FeedbackActionStore {

    pageState = INIT;
    openDialog = '';
    classifications = [];
    subClassifications = [];
    selectedClassificationId = 0;
    selectedSubClassficationId = 0;
    acceptedDate = new Date();
    comments = '';

    async getClassifications() {
        try {
            this.setClassificationId();
            this.pageState = LOADING;
            const url = classifactionsUrl.replace('{functionIds}', feedbackStore.selectedFunctionIds);
            const response = await appStore.apiProxy.getAsync(url);
            const data = await response.json();
            this.classifications = data.classifications;
            this.subClassifications = data.subClassifications;
            this.pageState = DONE;
        } catch (e) {
            this.pageState = ERROR;
            console.log(e);
        }
    }

    setClassificationId = () => {
        const { selectedFeedback } = feedbackStore;
        if (!feedbackStore.selectedFeedback) {
            return
        }
        this.selectedClassificationId = selectedFeedback.classificationId;
        this.selectedSubClassficationId = selectedFeedback.subClassificationId;
    }

    reset = () => {
        this.pageState = INIT;
        this.openDialog = '';
        this.classifications = [];
        this.subClassifications = [];
        this.selectedClassificationId = 0;
        this.selectedSubClassficationId = 0;
        this.acceptedDate = new Date();
    }

    onClassificationSelect = (value) => {
        this.selectedClassificationId = value;
        this.selectedSubClassficationId = 0;
    }

    onSubClassificationSelect = (value) => {
        this.selectedSubClassficationId = value;
    }

    updateDate = (value) => {
        this.acceptedDate = value;
    }

    updateComments = (value) => {
        this.comments = value;
    }

    updateOpenDialog = (value) => {
        this.openDialog = value;
    }

    get validate() {
        return this.selectedSubClassficationId !== 0;
    }

    get getSubClassifications() {
        const array = [];
        this.subClassifications.map((item, index) => {
            if (this.selectedClassificationId === item.classificationId) {
                array.push(this.subClassifications[index]);
            }
        });
        return array;
    }

    get editable() {
        if (feedbackListStore.selectedFeedbackId === 0) {
            return false;
        }
        return !(this.pageState === ERROR || this.pageState === LOADING);
    }

    onAccept = async () => {
        if (!this.validate) {
            snackBarStore.showErrorMessage('Select classification and sub classification.');
            return;
        }
        const body = {
            classificationId: this.selectedClassificationId, subclassificationId: this.selectedSubClassficationId,
            feedbackId: feedbackListStore.selectedFeedbackId, acceptedDate: humanizeDate(this.acceptedDate), comments: this.comments
        }
        await this.makeRequest(acceptSubmittedFeedbackUrl, body);
        feedbackListStore.fetchFeedbacks(submittedFeedbacksUrl);
    }

    onModify = async (url) => {
        if (!this.validate) {
            snackBarStore.showErrorMessage('Select classification and sub classification.');
            return;
        }
        try {
            const body = {
                classificationId: this.selectedClassificationId, subclassificationId: this.selectedSubClassficationId,
                feedbackId: feedbackListStore.selectedFeedbackId, acceptedDate: humanizeDate(this.acceptedDate), comments: this.comments
            }
            await this.makeRequest(acceptSubmittedFeedbackUrl, body);
            feedbackListStore.fetchFeedbacks(pendingForAuditUrl);
        }
        catch (e) {
            console.log(e);
            snackBarStore.showErrorMessage('Unable to mark as complete.');
        }
    }

    makeRequest = async (url, body) => {
        const response = await appStore.apiProxy.asyncPost(url, body);
        const data = await response.json();
        snackBarStore.showSuccessMessage(data.message);
    }

    onFeedbackClosed = async () => {
        if (!this.comments) {
            snackBarStore.showErrorMessage('Please enter closing comments.');
            return;
        }
        try {
            this.pageState = LOADING;
            await this.makeRequest(markAsCompleteFeedbackUrl, { feedbackId: feedbackListStore.selectedFeedbackId, comments: this.comments });
            await feedbackListStore.fetchFeedbacks(pendingForAuditUrl);
            snackBarStore.showSuccessMessage('Feedback closed successfully.');
            this.pageState = DONE;
        } catch (e) {
            console.log(e);
            snackBarStore.showErrorMessage('Unable to mark as complete.');
        }
    }

    onResend = async() => {
        try{
            this.pageState = LOADING;
            await this.makeRequest(resendCompletedMailUrl,feedbackListStore.selectedFeedbackId);
            await feedbackListStore.fetchFeedbacks(pendingForAuditUrl);
            snackBarStore.showSuccessMessage('Mail Resent successfull.');
            this.pageState = DONE;
        }catch(e){
            console.log(e);
        }
    }
}

decorate(FeedbackActionStore, {
    pageState: observable,
    openDialog: observable,
    classifications: observable,
    subClassifications: observable,
    selectedClassificationId: observable,
    selectedSubClassficationId: observable,
    acceptedDate: observable,
    comments: observable,

    getSubClassifications: computed,
    validate: computed,
    editable: computed,
});
export const feedbackActionStore = new FeedbackActionStore();