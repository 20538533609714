import React, { Component } from "react";
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { Button, Grid } from '@material-ui/core';
import {appStore} from './stores/AppStore';
import { AgGridReact } from 'ag-grid-react';
import APIProxy from './stores/APIProxy';
import '../Delivery.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import {
  deliverySave,
  publisherListUrl,
  divisionListUrl,
  functionListUrl,
  deliveryListUrl
} from './stores/APIEndpoints';


const styles = {
    card: {
        marginTop: '30px',
        marginLeft:'auto',
        marginRight:'auto',
        maxWidth: '600px',
    },
    media: {
        objectFit: 'cover',
    },
    table: {
      minWidth: 300,
    },
};

const stages = [
  {
    value: 'firstproofs',
    label: 'First Proofs',
  },
  {
    value: 'revises',
    label: 'Revises',
  },
  {
    value: 'finals',
    label: 'Finals',
  },
  
];

var formData = {};
var targetvalue = '';


// const saveDelivery = async () => {
//  formData.divisionId = parseInt(formData.divisionId);
//  formData.functionId = parseInt(formData.functionId);
//  formData.publisherId = parseInt(formData.publisherId);
//   try{
//     console.log("delivery result process");
//     const response = await appStore.apiProxy.asyncPost(deliverySave, formData);
//     console.log("delivery result");
//     console.log(response);
//     fetchDeliveryReport();
//   } catch{
//     console.log("delivery data is not save");
//   }
  
// }


const handleChange = event => {
  formData[event.target.id] = event.target.value;
};


class Delivery extends Component
{
    constructor(prop){
      super(prop);
      this.state = {publisher: [], pubval:'', divval:'', funval:'', delval:'', division: [], function: [], userid: [],
      columnDefs: [
        {headerName: 'Sl. No.', valueGetter: 'node.rowIndex + 1', width: 120, cellStyle:{textAlign:'left'}},
        {headerName: 'Date', field: 'createdAt', width: 150, cellStyle:{textAlign:'left'}},
        {headerName: 'Publisher', field: 'publisherName', width: 150, cellStyle:{textAlign:'left'}},
        {headerName: 'Division', field: 'divisionName', width: 150, cellStyle:{textAlign:'left'}},
        {headerName: 'Function', field: 'functionName', width: 150, cellStyle:{textAlign:'left'}},
        {headerName: 'Delivery', field: 'delivery', width: 150, cellStyle:{textAlign:'left'}},
        {headerName: 'Ahead', field: 'ahead', width: 120, cellStyle:{textAlign:'left'}},
        {headerName: 'OnTime', field: 'ontime', width: 120, cellStyle:{textAlign:'left'}},
        {headerName: 'Delay', field: 'delay', width: 120, cellStyle:{textAlign:'left'}},
        {headerName: 'User', field: 'userEmail', width: 220, cellStyle:{textAlign:'left'}}
      ],
      rowData: []
    };
      this.fetchPublishers();
      this.fetchFunctions();
      this.fetchDeliveryReport();
      //this.fetchRowData();

    }
    fetchRowData = () => {
      try {
       const dt = appStore.apiProxy.get(publisherListUrl);
       this.setState({"rowData":dt});

      }
      catch (e) {
        console.log(e);
      }
    }

    fetchPublishers = () => {
      try {
        appStore.apiProxy.get(publisherListUrl, this.setPublisher);
      }
      catch (e) {
        console.log(e);
      }
    }

    fetchDeliveryReport = () => {
      try {
        appStore.apiProxy.get(deliveryListUrl, this.setDeliveryData);
      }
      catch (e) {
        console.log(e);
      }
    }

    fetchDivisions = () => {
      try {
        appStore.apiProxy.get(divisionListUrl, this.setDivision);
      }
      catch (e) {
        console.log(e);
      }
    }
    fetchFunctions = () => {
      try {
        appStore.apiProxy.get(functionListUrl, this.setFunction);
      }
      catch (e) {
        console.log(e);
      }
    }
    fetchUsers = () => {
      try {
        appStore.apiProxy.get(usersUrl, this.setUser);
      }
      catch (e) {
        console.log(e);
      }
    }
    
    saveDelivery = async () => {
      formData.divisionId = parseInt(formData.divisionId);
      formData.functionId = parseInt(formData.functionId);
      formData.publisherId = parseInt(formData.publisherId);
      formData.userId = appStore.credentials.userId;
       try{
         console.log("delivery result process");
         const response = await appStore.apiProxy.asyncPost(deliverySave, formData);
         console.log("delivery result");
         console.log(response);
         this.fetchDeliveryReport();
         this.form.reset(); 
       } catch{
         console.log("delivery data is not save");
       }
     }

  
    handlePubChange = event => {
      formData['publisherId'] = event.target.value;
      this.setState({pubval:event.target.value});
      console.log(formData['publisherId']);
      try {
        const url = divisionListUrl.replace('{publisherId}', formData['publisherId']);
        appStore.apiProxy.get(url, this.setDivision)  ;
      }
      catch (e) {
        console.log(e);
      }
    };

    handleDivChange = event => {
      formData['divisionId'] = event.target.value;
      this.setState({divval:event.target.value});
    };

    handleFunChange = event => {
      formData['functionId'] = event.target.value;
      this.setState({funval:event.target.value});
    };
    handleDeliverChange = event => {
      formData['delivery'] = event.target.value;
      this.setState({delval:event.target.value});
    };

    setPublisher = (data) => {
      console.log(data);
      this.setState({publisher: data});
    }

    setDeliveryData = (data) => {
      console.log(data);
      this.setState({rowData: data});
    }


    setDivision = (data) => {
      console.log("get divistion data");
      console.log(data);
      this.setState({division: data});
    }

    setFunction = (data) => {
      console.log(data);
      this.setState({function: data});
    }
    
    setUser = (data) => {
      console.log(data);
      this.setState({userid: data});
    }
    
    renderAgGrid() {
      return (
          <div
              className="ag-theme-material" 
              style={{ height: '500px', width: '100%', margin: '0 auto', marginTop: '30px' }}>
              <AgGridReact style ={{"textAlign":"left"}}
                  columnDefs={this.state.columnDefs}
                  rowData={this.state.rowData}>
              </AgGridReact>
          </div>
      );
  }

    render()
    {
      return (
        <div style ={{"text-align": "center","width":"100%","margin-top":"70px"}} >
        <form noValidate autoComplete="off"  style ={{"width":"60%","margin": "0 auto"}} >
          <Grid container>
          <TextField style ={{"margin":"10px","width":"21%"}} 
              id="publisherId"
              select
              label="Publisher"
              value={this.state.pubval}
              onChange={this.handlePubChange}
              variant="outlined">
              {this.state.publisher.map(option => (
                <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
              ))}
            </TextField>
            <TextField style ={{"margin":"10px","width":"21%"}} 
              id="divisionId"
              select
              label="Division"
              value={this.state.divval}
              onChange={this.handleDivChange}
              variant="outlined"
            >
              {this.state.division.map(option => (
                <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
              ))}
            </TextField>
            <TextField style ={{"margin":"10px","width":"21%"}} 
              id="functionId"
              select
              label="Function"
              value={this.state.funval}
              onChange={this.handleFunChange}
              variant="outlined"
            >
              {this.state.function.map(option => (
                <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
              ))}
            </TextField>
          <TextField style ={{"margin":"10px","width":"21%"}} 
              id="delivery"
              select
              label="Delivery"
              value={this.state.delval}
              onChange={this.handleDeliverChange}
              variant="outlined"
            >
              {stages.map(option => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
              ))}
            </TextField>
            </Grid>
            <Grid container>
            <TextField style ={{"margin":"10px","width":"21%"}} id="ahead" label="Ahead" variant="outlined" onChange={handleChange}/>
            <TextField style ={{"margin":"10px","width":"21%"}} id="ontime" label="On Time" variant="outlined" onChange={handleChange}/>
            <TextField style ={{"margin":"10px","width":"21%"}} id="delay" label="Delay" variant="outlined" onChange={handleChange}/>
            <Button style ={{"background-color":"#4caf50", "color":"#fff", "marginRight":"","margin":"10px","width":"10%","height":"10%","marginTop":"20px"}} label="Save" variant="outlined" color="secondary" onClick={this.saveDelivery}>Save</Button>
            </Grid>
        </form>
        {this.renderAgGrid()}
        </div>
      )
    }
}

export default withStyles(styles)(Delivery);