import React, { Component, Fragment } from 'react';
import { Provider } from 'mobx-react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { green, indigo } from '@material-ui/core/colors';
import './App.css';
import SelectedComponent from './components/SelectedComponent'
import NavigationBar from './components/NavigationBar';
import MySnackbar from './components/MySnackbar';
import {appStore} from './components/stores/AppStore';

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: indigo[500]
    },
    primary: {
      main: green[500]
    }
  },

  typography: {
    useNextVariants: true,

    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '"Lato"',
      'sans-serif'
    ].join(',')
  }
});


export default class App extends Component {

  render() {

    return (
      <div>
        <Provider appStore={appStore}>
          <MuiThemeProvider theme={theme}>
            <NavigationBar />
            <SelectedComponent />
            <MySnackbar />
          </MuiThemeProvider>
        </Provider>
      </div>
    );
  }
}
