import React, { Component, Fragment } from 'react';
import { Provider } from 'mobx-react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { green, indigo } from '@material-ui/core/colors';
import './App.css';
import SelectedComponent from './components/SelectedComponent'
import NavigationBar from './components/NavigationBar';
import MySnackbar from './components/MySnackbar';
import {appStore} from './components/stores/AppStore';
import queryString from 'query-string';
import apiProxy from './components/stores/APIProxy';




const theme = createMuiTheme({
  palette: {
    secondary: {
      main: indigo[500]
    },
    primary: {
      main: green[500]
    }
  },

  typography: {
    useNextVariants: true,

    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '"Lato"',
      'sans-serif'
    ].join(',')
  }
});

export default class AutoLogin extends Component {

  componentDidMount() {
    this.AutoLoginCaller();
  }
    AutoLoginCaller = async() =>
  {
    const queryParams = queryString.parse(location.search);
    console.log(queryParams);
    await appStore.autoAuthenticate(queryParams);
    window.location.href = '/';
  }
    render() {

    const parsed = queryString.parse(location.search);
    return (
      <div>
        <Provider appStore={appStore}>
          <MuiThemeProvider theme={theme}>
            <NavigationBar />
            <SelectedComponent />
            <MySnackbar />
          </MuiThemeProvider>
        </Provider>
      </div>
    );
  }
}
