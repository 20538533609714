import { decorate, observable, flow, computed, action } from 'mobx';
import { managerRemarkOnFeedback, mailUrl, unassignedFeedbacksUrl, pendingProposalsUrl, deleteCorrespondenceByMessageId, pendingManagerProposalsUrl } from './APIEndpoints';

const PENDING = 'pending';
const DONE = 'done';
const ERROR = 'error';
const IGNORED = 'ignored';
const INIT='init';



const IGNORE_ERROR_MESSAGE = 'There is an error in marking the mail as ignored.';


export default class CorrespondenceStore {

  unassignedFeedbacks = [];
  unassignedFeedbacksById = {};
  pendingProposals = [];

  listState = INIT;
  rowCount = 0;

  correspondenceId = 0;
  correspondence = {};
  mailState = INIT;

  isDialogOpen = false;

  selectedFolder = "";

  constructor(props) {
    this.apiProxy = props.apiProxy;
  }

  get isMailReady() {
    return this.mailState == DONE;
  }

  get isMailIgnored() {
    return this.mailState == IGNORED;
  }

  get editable() {
    return this.mailState !== PENDING && this.listState !== PENDING;
  }

  refreshInbox = () => {
    var newData = [];
    for (var prop in this.unassignedFeedbacksById) 
    {
      newData.push(this.unassignedFeedbacksById[prop]);
    }
    this.rowCount = newData.length;
    this.unassignedFeedbacks = newData;
    this.listState = DONE;
  }

  fetchUnassignedFeedbacks = flow(function* (formData) {
    var newUrl = unassignedFeedbacksUrl;
    if (formData != undefined){
      newUrl = newUrl+'='+formData['zimbraFolder'];
    }

    try {
      this.setListState(PENDING);
      const response = yield this.apiProxy.getAsync(newUrl);
      const data = yield response.json();
      this.setUnassignedFeedbacks(data);
      this.setListState(DONE);
    }
    catch (e) {
      this.setListState(ERROR);
    };
  });

  fetchPendingProposals = flow(function* () {
    this.setListState(PENDING);
    try {
      const response = yield this.apiProxy.getAsync(pendingProposalsUrl);
      const data = yield response.json();
      this.setPendingProposals(data);
      this.setListState(DONE);
    }
    catch (e) {
      this.setListState(ERROR);
      console.log(e);
    };
  });

  fetchManagerPendingProposals = flow(function* () {
    this.setListState(PENDING);
    try {
      const response = yield this.apiProxy.getAsync(pendingManagerProposalsUrl);
      const data = yield response.json();
      this.setPendingProposals(data);
      this.setListState(DONE);
    }
    catch (e) {
      this.setListState(ERROR);
      console.log(e);
    };
  });

  setUnassignedFeedbacks(data) {
    this.unassignedFeedbacks = data;  
    for (var i=0; i<data.length; i++) 
    {
      this.unassignedFeedbacksById[data[i].messageId]=data[i];
    }
    this.rowCount = data.length;
    this.listState = DONE;
  }

  setPendingProposals(data) {
    this.pendingProposals = data;
    this.rowCount = data.length;
    this.listState = DONE;
  }

  setListState(status) {
    this.listState = status;
  }


  fetchCorrespondence = flow(function* (mailId) {
    this.correspondenceId = mailId
    this.setMailStatus(PENDING);
    try {
      const response = yield this.apiProxy.getAsync(mailUrl.replace('{mailId}', mailId));
      let data = yield response.json();
      if(data.attachedFile===undefined)
      {
          data.attachedFile = [];
      }
      if(data.mailRecipients===undefined)
      {
          data.mailRecipients = [];
      }

      this.setCorrespondence(data);
    }
    catch (e) {
      this.setMailStatus(ERROR);
      //console.log(e);
    }
  });

  managerRemarksOnFeedback = (feedbackId, remark) => 
  {
    const response = this.apiProxy.asyncPost(managerRemarkOnFeedback + '?feedbackId='+feedbackId+'&remark='+remark);
    const data = response.json();  
  }



  // fetchCorrespondence(data) {
  //   console.log('DATA' + data);
  //    this.setMailStatus(PENDING);
  //    this.setCorrespondence(data[0]);
  //  }

  ignoreMail = async () => {
    this.setMailStatus(PENDING);
    try {
      const body = { messageId: this.correspondenceId};
      console.log(1111);
      console.log(this.unassignedFeedbacksById.hasOwnProperty(body.messageId));
      delete this.unassignedFeedbacksById[body.messageId];
      console.log(222);
      console.log(this.unassignedFeedbacksById.hasOwnProperty(body.messageId));
      const response = await this.apiProxy.asyncPost(deleteCorrespondenceByMessageId, body);
      const deleteResponse = await response.json();

      this.correspondenceId = 0;
      this.correspondence = {};
      this.setMailStatus(IGNORED);
      this.refreshInbox();
      this.isDialogOpen = false;
    }
    catch (e) {
      console.log(e.message);
      this.setMailStatus(ERROR);
      throw IGNORE_ERROR_MESSAGE;
    }
  }


  setCorrespondence(data) {
    this.correspondence = data;
    this.correspondenceId = data.messageId;
    this.mailState = DONE;
  }

  setMailStatus(status) {
    this.mailState = status;
  }

}

decorate(CorrespondenceStore, {

  listState: observable,
  unassignedFeedbacks: observable,
  pendingProposals: observable,
  rowCount: observable,


  mailState: observable,
  correspondenceId: observable,
  correspondence: observable,
  isDialogOpen: observable,

  isMailReady: computed,
  isMailIgnored: computed,
  ignoreMail: action,
  editable: computed,
})
