import { types, destroy } from 'mobx-state-tree';
import { actionItemsUrl, actionItemsByFeedbackUrl } from './APIEndpoints';
import { LOADING, ERROR, INIT, DONE } from './LabelStore';
import { appStore } from './AppStore';
import { ActionItem } from './ActionItemStore';

const ActionItemListStore = types
    .model({
        feedbackId: types.optional(types.integer, 0),
        feedbackActorId: types.optional(types.integer, 0),
        rootCauseId: types.optional(types.integer, 0),
        rootCauseName: types.optional(types.string, ''),
        editable: types.optional(types.boolean, true),
        actionItems: types.optional(types.array(ActionItem), []),
        pageState: types.optional(types.string, INIT)
    })
    .views(self => {
        return {
            isEditable() {
                if (self.pageState === LOADING || self.pageState === ERROR) {
                    return false;
                }
                return self.editable;
            },

            async  fetchActionItems(url) {
                const response = await appStore.apiProxy.getAsync(url);
                const data = await response.json();
                data.map(item => {
                    self.addActionItem(item);
                });
            },
        }
    })
    .actions(self => {
        return {
            async setRootCause(rootCause) {
                try {
                    if (rootCause.id === 0 || !rootCause.expanded) {
                        self.reset();
                        return;
                    }
                    self.updatePageState(LOADING);
                    self.rootCauseId = rootCause.id;
                    self.feedbackActorId = rootCause.feedbackActorId;
                    self.feedbackId = rootCause.feedbackId;
                    self.rootCauseName = rootCause.name;
                    self.editable = rootCause.editable;
                
                    const url = actionItemsUrl.replace('{rootCauseId}', self.rootCauseId);
                    self.actionItems = [];
                    await self.fetchActionItems(url);
                    self.updatePageState(DONE);
                } catch (e) {
                    self.updatePageState(ERROR);
                }
            },
            updatePageState(pageState) {
                self.pageState = pageState;
            },

            reset() {
                self.rootCauseId = 0;
                self.rootCauseName = '';
                self.actionItems = [];
                self.feedbackActorId=0;
                self.feedbackId=0;
                self.pageState = INIT;
            },

            create() {
                const actionItemMap = {};
                actionItemMap.action = '';
                actionItemMap.feedbackActorId = self.feedbackActorId;
                actionItemMap.feedbackId = self.feedbackId;
                actionItemMap.description = '';
                actionItemMap.rootCauseId = self.rootCauseId;
                const actionItem = self.addActionItem(actionItemMap);
                actionItem.onSelect(true);
            },

            addActionItem(actionItemMap) {
                const date = actionItemMap.expectedCompletionDate === '' ? undefined : new Date(actionItemMap.expectedCompletionDate);
                const actionItem = ActionItem.create({
                    id: actionItemMap.id,
                    feedbackId: actionItemMap.feedbackId,
                    feedbackActorId: actionItemMap.feedbackActorId,
                    rootCauseId: actionItemMap.rootCauseId,
                    action: actionItemMap.action,
                    expectedCompletionDate: date,
                    description: actionItemMap.description,
                    editable: self.editable
                });
                self.actionItems.push(actionItem);
                return actionItem;
            },
            remove(actionItem) {
                destroy(actionItem);
            },

            async fetchSubmittedActionItems(feedbackId) {
                try {
                    self.actionItems = [];
                    self.updatePageState(LOADING);
                    self.feedbackId = feedbackId;
                    const url = actionItemsByFeedbackUrl.replace('#{feedbackId}',feedbackId);
                    await self.fetchActionItems(url);
                    self.updatePageState(DONE);
                } catch (e) {
                    self.updatePageState(ERROR);
                    console.log(e);
                }
            }
        }
    });
export const actionItemListStore = ActionItemListStore.create({});