import { observable, decorate, action, flow, computed } from "mobx";
import {
    distributionListManagementUrl,
    publisherListUrl,
    divisionListUrl,
    divisionListUrlwithid,
    functionListUrl,
    deleteDistributionList
} from "./APIEndpoints";
import { snackBarStore } from "./SnackBarStore";



const PENDING = 'pending';
const DONE = 'done';
const ERROR = 'error';

const NEW_ENTITY = { id: 0, publisherId: -1, divisionId: -1, functionId: -1, userId: -1, ownerType: "cc" };

export default class DistributionListStore {

    state = PENDING;
    isDialogOpen = false;

    entity = NEW_ENTITY;
    entities = [];
    rowCount = 0;

    publishers = [];
    divisions = [];
    functions = [];
    selectedUser = undefined;

    constructor(props) {
        this.apiProxy = props.apiProxy;
        this.fetchPublishers();
        this.fetchFunctions();
    }

    /**
   * Load the three domain store objects Publishers, Divisions and Functions
   */
    fetchPublishers = () => {
        this.state = PENDING;
        try {
            this.apiProxy.get(publisherListUrl, this.setPublishers);
        }
        catch (e) {
            this.state = ERROR;
            console.log(e);
        }
    }


    /**
     * If the publisherId is not yet selected, we should keep the divisions as empty.
     */
    fetchDivisions = () => {
        if (this.entity.publisherId == 0) {
            this.divisions = [];
            return;
        }
        this.state = PENDING;
        try {
            const url = divisionListUrl.replace('{publisherId}', this.entity.publisherId);
            this.apiProxy.get(url, this.setDivisions);
        }
        catch (e) {
            this.state = ERROR;
            console.log(e);
        }
    }

    fetchFunctions = () => {
        this.state = PENDING;
        try {
            this.apiProxy.get(functionListUrl, this.setFunctions);
        }
        catch (e) {
            this.state = ERROR;
            console.log(e);
        }
    }

    // The private methods or internal callback methods

    setPublishers = data => {
        this.state = DONE;
        this.publishers = data;

        this.entity.publisherId = 0;
        this.entity.divisionId = 0;
        this.entity.functionId = 0;
    }

    setFunctions = data => {
        this.state = DONE;
        this.functions = data;
    }

    setDivisions = data => {
        this.state = DONE;
        this.divisions = data;
    }


    setPublisherId = publisherId => {
        this.state = DONE;
        if (publisherId !== this.entity.publisherId) {
            this.entity.publisherId = publisherId;
            this.entity.divisionId = 0;
            this.entity.functionId = 0;
            this.fetchDivisions();
        }
    }

    setDivisionId = divisionId => {
        this.state = DONE;
        this.entity.divisionId = divisionId;
    }

    setFunctionId = functionId => {
        this.state = DONE;
        this.entity.functionId = functionId;
    }

    setUser = user => {
        this.entity.userId = user.value;
        this.selectedUser = user;
        this.state = DONE
    }

    save = async () => {
        this.state = PENDING;

        if (!this.canSave) {
            this.state = ERROR;
            return;
        }

        try {
            const body = { ...this.entity };

            const response = await this.apiProxy.asyncPost(distributionListManagementUrl, body);
            await response.json();
            this.isDialogOpen = false;
            this.state = DONE;
        }
        catch (e) {
            this.state = ERROR;
        }
    }

    delete  = async() => {
        this.state = PENDING;
        try {
            const response = await this.apiProxy.asyncPost(deleteDistributionList, parseInt(this.entity.id));
            const data = await response.json();
            this.isDialogOpen = false;
            snackBarStore.showSuccessMessage(data.message);
            this.state = DONE;
        }
        catch (e) {
            this.state = ERROR;
        }
    }

    get canSave() {
        return this.entity.divisionId > 0 && this.entity.functionId > 0 && this.entity.userId > 0
    }

    fetchEntities = flow(function* () {
        this.state = PENDING;

        try {
            const response = yield this.apiProxy.getAsync(distributionListManagementUrl);
            const data = yield response.json();
            this.setEntities(data);
        }
        catch (e) {
            this.state = ERROR;
            this.entities = [];
            console.log(e);
        }
    });

    setEntities(data) {
        this.entities = data;
        this.rowCount = this.entities.length;
        this.state = DONE;
    };

    fetchSelectedEntity = flow(function* (entityId) {
        this.state = PENDING;

        try {
            const response = yield this.apiProxy.getAsync(distributionListManagementUrl + "/" + entityId);
            const data = yield response.json();
            this.setSelectedEntity(data);
        }
        catch (e) {
            this.state = ERROR;
            console.log(e);
        }
    });

    setSelectedEntity(data) {

        this.entity = {id: data.id, isOwner: data.owner};

        this.entity.publisherId = data.division.publisher.id;
        this.entity.divisionId = data.division.id;
        this.entity.functionId = data.function.id;
        this.entity.userId = data.user.id

        this.selectedUser = {label:data.user.name,value:data.user.id}

        this.fetchDivisions();

        this.isDialogOpen = true;
        this.state = DONE;
    }


    newEntity = () => {
        this.entity = NEW_ENTITY;
        this.selectedUser = undefined;
        this.isDialogOpen = true;
        this.state = DONE;
    }

}

decorate(DistributionListStore, {

    state: observable,
    isDialogOpen: observable,
    rowCount: observable,

    canSave: computed,

    publishers: observable,
    divisions: observable,
    functions: observable,
    selectedUser: observable,

    entities: observable,
    entity: observable,

    save: action,
    newEntity: action
})
