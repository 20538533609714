import React, { Component, Fragment, useState } from 'react';
import labelOf from './stores/LabelStore.js';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import { AppBar, Toolbar } from '@material-ui/core';


export function text(fieldId, entity, editable, classes, fullWidthFlag, multiLineFlag) {
  const label = labelOf(fieldId)

  var value = "";
  if(entity[fieldId]!==undefined)
  {
    value = entity[fieldId];
    console.log("Element value", value);
    
  }

  const isFullWidth = typeof (fullWidthFlag) == 'undefined' ? false : fullWidthFlag;
  const isMultiLine = typeof (multiLineFlag) == 'undefined' ? false : multiLineFlag;

  const numberOfRows = 5;
  const updateStore = (event) => {
    console.log(event.target.value);
    entity[fieldId] = event.target.value;
  }

  return (
    <TextField value={value} id={fieldId} required disabled={!editable} multiline={isMultiLine} rows={numberOfRows} fullWidth={isFullWidth} label={label} className={classes.textField} margin="normal" variant="outlined"
      onChange={updateStore}
    />
  );
}

export function password(fieldId, entity, classes) {
  const label = labelOf(fieldId)

  const value = entity[fieldId];

  const updateStore = (event) => {
    entity[fieldId] = event.target.value;
  }

  return (
    <TextField id={fieldId} required label={label} value={value} type="password" className={classes.textField} margin="normal" variant="outlined"
      onChange={updateStore}
    />
  );
}



export function multiLineText(fieldId, entity, editable, classes, fullWidth) {
  const label = labelOf(fieldId)
  const value = entity[fieldId];

  const updateStore = (event) => {
    entity[fieldId] = event.target.value;
  }

  return (
    <TextField id={fieldId} required disabled={!editable} fullWidth={fullWidth} label={label} value={value} className={classes.textField} margin="normal" variant="outlined"
      multiline={true}
      rows={2}
      onChange={updateStore}
    />
  );
}

export function selectableText(fieldId, entity, editable, list, classes) {
  const label = labelOf(fieldId)
  const value = entity[fieldId];
  const helperText = "Please Select " + label;

  const updateStore = (event) => {
    entity[fieldId] = event.target.value;
  }

  return (
    <TextField id={fieldId} required disabled={!editable} select label={label} value={value} className={classes.textField} margin="normal" variant="outlined"
      onChange={updateStore}
      helperText={helperText}
      SelectProps={{
        MenuProps: {
          className: classes.menu,
        },
      }}
    >
      {list.map(option => (
        <MenuItem key={option.id} value={option.id}>
          {option.name}
        </MenuItem>
      ))}
    </TextField>
  );
}


export function reactionText(fieldId, entity, action, editable, list, classes,styles) {
  const label = labelOf(fieldId)
  const value = entity[fieldId];
  const helperText = "Please Select " + label;

  const updateStore = (event) => {
    action(event.target.value);
  }
  

  return (
    <TextField id={fieldId} required disabled={!editable} select label={label} value={value} className={classes.textField} margin="normal" variant="outlined"
      onChange={updateStore}
      helperText={helperText}
      style={{...styles}}
      SelectProps={{
        MenuProps: {
          className: classes.menu,
        },
      }}
    >
      {list.map(option => (
        <MenuItem key={option.id} value={option.id}>
          {option.name}
        </MenuItem>
      ))}
    </TextField>
  );
}

export function zimbraFolders(fieldId, entity, action, editable, list, classes,styles) {
  const label = labelOf(fieldId)
  const value = entity[fieldId];
  const helperText = "Please Select " + label;

  const updateStore = (event) => {
    action(event.target.value);
  }

  return (
    <TextField id={fieldId} required disabled={!editable} select label={label} value={value} className={classes.textField} margin="normal" variant="outlined"
      onChange={updateStore}
      helperText={helperText}
      style={{...styles}}
      SelectProps={{
        MenuProps: {
          className: classes.menu,
        },
      }}
    >
      if(list){list.map(option => (
        <MenuItem key={option.id} value={option.id}>
          {option.zimbraFolder}
        </MenuItem>
      ))}
    </TextField>
  );
}


export function multiSelect(fieldId, entity, action, editable, list, classes) {
  const label = labelOf(fieldId)
  const values = entity[fieldId];
  const helperText = "Please Select " + label;

  const updateStore = (event) => {
    action(event.target.value);
  }

  const selectedValues = (selected) => {
    const selections = []
    list.map(item => {
      if (selected.includes(item.id)) {
        selections.push(item.name);
      }
    })
    return selections.join();
  }

  return (
    <FormControl >
      <InputLabel htmlFor="select-functions">{label}</InputLabel>
      <Select id={fieldId} required disabled={!editable} value={values}
        onChange={updateStore}
        multiple
        renderValue={selected => selectedValues(selected)}
      >
        {list.map(option => (
          <MenuItem key={option.id} value={option.id}>
            <Checkbox checked={values.includes(option.id)} />
            <ListItemText primary={option.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export function datePicker(fieldId, entity) {
  const updateStore = (event) => {
    entity[fieldId] = event;
  }

  const label = labelOf(fieldId);
  const value = entity[fieldId];
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        margin="normal"
        style={{ "marginLeft": "9px" }}
        label={label}
        value={value}
        hinttext="Portrait Dialog"
        onChange={updateStore}
      />
    </MuiPickersUtilsProvider>

  );
}

export function button(fieldId, onClickFunction, classes) {
  const saveFunction = (event) => {
    onClickFunction();
  }
  const label = labelOf(fieldId);
  return (
    <Button variant="contained" color="primary"
      onClick={saveFunction}
      className={classes.button}>
      {label}
    </Button>
  )

}

export function tag(label, _value) {
  const value = _value ? _value : 'No Tag';
  const color = _value ? 'secondary' : 'default';
  return (
    <Fragment>
      <Typography gutterBottom variant='caption'>{label}</Typography>
      <Typography gutterBottom color={color} variant='body2'>{value}</Typography>
    </Fragment>
  )
  };

  export function initHeader(props,message){
        const { classes } = props;
        return (<AppBar position="static" color="secondary">
            <Toolbar variant="dense">
                <Typography variant="subtitle1" color="inherit" className={classes.grow}>
                    {message}
                </Typography>
            </Toolbar>
        </AppBar>);
  }

  export function centerMessageDiv(message){
    return (<div style={{ 'marginTop': '5px' }}><center style={{ "display": "grid", "alignItems": "center", "justifyContent": "center" }} >{message}</center></div>);
  }
