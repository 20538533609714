import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';
import {observer,inject} from 'mobx-react';
import FeedbackPeople from './FeedbackPeople';

const styles = theme => ({
  
  back: {
    marginRight: theme.spacing.unit,
  },

  error: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    '&:hover': {
      backgroundColor: purple[700],
    },
  },

  success: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },

  next: {
    marginRight: theme.spacing.unit,
  },

  messages: {
   
  },
});

@inject("feedbackControllerStore")
@inject("correspondenceStore")
@observer
class FeedbackController extends React.Component {

  save = async() => {
    const correspondence = this.props.correspondenceStore.correspondence;
    console.log("1111");
    console.log("CORRESPONDENCE" + correspondence);
    await this.props.feedbackControllerStore.save(correspondence);
    
  };

  handleFinish = async() => {
    const correspondence = this.props.correspondenceStore.correspondence;
    await this.props.feedbackControllerStore.finish(correspondence);
    //console.log(this.props.correspondenceStore.unassignedFeedbacksById);
    //this.props.correspondenceStore.refreshInbox();
  }

  handleResend = async() => {
    await this.props.feedbackControllerStore.resend();
  }
 
  renderResend() {
    const { classes } = this.props;
    const store = this.props.feedbackControllerStore;
  
    if(store.shouldResend)
    {
      return (
        <Button disabled={!store.shouldResend} variant="contained" onClick={this.handleResend} className={classNames(classes.next, classes.error)}>
          Resend Mail
        </Button>
      )
    }
  }

  renderFinalize() {
    const { classes } = this.props;
    const store = this.props.feedbackControllerStore;
    const canFinalize = store.isValidStep && !store.isFeedbackFinalized; 
    const editable = this.props.feedbackControllerStore.isEditable;
      return (
        <Button variant="contained" color="primary" onClick={this.handleFinish} className={classes.next}>
         Circulate
        </Button>
      );
  }

  renderMessages() {
    const { classes } = this.props;

    const store = this.props.feedbackControllerStore;

    if(store.shouldResend) {
      return (
        <Typography component = 'button' variant='caption' className={classNames(classes.messages, classes.error)}>
            Problem in mailing. Please try to resend the mail.
        </Typography>
      )
    };
    
    if(store.isFeedbackFinalized && store.isMailClosed) {
      return (
        <Typography component = 'button' variant='caption' className={classNames(classes.messages, classes.success)}>
          The feedback is finalized.
        </Typography>
      )
    };

  
  }
  
  renderNavigationButtons() {
    const { classes } = this.props;
    const store = this.props.feedbackControllerStore;
    //const editable = store.isValidStep && !store.isFeedbackFinalized;
    const editable = this.props.feedbackControllerStore.isEditable;

    return (
      <div>
        <Button variant="contained" color="primary" onClick={this.save} className={classes.next}>
           Save
        </Button>
        {this.renderFinalize()}
        {this.renderResend()}
      </div>
    )
  }

  render() {
    console.log("Email Clicked");
    return (
        <Fragment>
          {this.renderMessages()}
          <FeedbackPeople greeting={{}}/>
          {this.renderNavigationButtons()}
        </Fragment>
    );
  }
}

FeedbackController.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(FeedbackController);
