import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { humanizeDate } from './stores/Util.js';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 2,
        overflowX: 'auto',
    },
});

class InteractionLineItem extends Component {

    render() {
        const { classes, interaction } = this.props;
        return (
            <div className={classes.root}>
                <Grid container>
                    <Grid item xs>
                        <Typography variant="caption">
                            {interaction.createdBy}
                        </Typography>
                    </Grid>
                    <Grid item >
                        <Typography variant="caption">
                            {interaction.functionName}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="caption">
                            {humanizeDate(interaction.createdAt)}
                        </Typography>
                    </Grid>
                </Grid>
                <Typography variant="body1">
                    {interaction.notes}
                </Typography>
                <hr />
            </div>
        );
    }
}

InteractionLineItem.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InteractionLineItem);