import { decorate, observable, flow, computed, action } from 'mobx';

//import CorrespondenceStore from '../stores/CorrespondenceStore';

import {
  distributionListUrl,
  feedbackMailUrl,
  feedbackPeopleUrl,
  publisherListUrl,
  divisionListUrl,
  functionListUrl,
  mailTagUrl,
  getPubkitQmrsPubDivUrl,
} from './APIEndpoints';

import { isBlank } from './Util';


const newUIFields = {
  feedbackId: 0,
  publisherId: 0,
  divisionId: 0,
  functionIds: [],
  feedbackOn: '',
  feedbackType: '',
  severity: '',
  name: '',
  content:'',
  providerRole:'',
  projectTag:'',
  projectManager:'',
  ititle:'',
  vertical:'',
  isFeedbackFinalized: false,
  isMailClosed: false,
  shouldFinalize: false,
  body:''
};


const correspondenceTemplate = {
  feedbackId: 0,
  publisherId: 0,
  divisionId: 0,
  functionIds: [],
  feedbackOn: '',
  feedbackType: '',
  severity: '',
  name: '',
  content:'',
  providerRole:'',
  projectTag:'',
  projectManager:'',
  ititle:'',
  vertical:'',
  isFeedbackFinalized: false,
  isMailClosed: false,
  shouldFinalize: false,
  body:''
};

const processingTag = {
  publisherTag: 'loading',
  divisionTag: 'loading',
  projectTag: 'loading',
  ititle: 'loading',
  vertical: 'loading',
  projectManager: 'loading',
  tagSource: '',
}

const emptyCorrespondenceTag = {
  publisherTag: '',
  divisionTag: '',
  projectTag: '',
  projectManager: '',
  ititle: 'loading',
  vertical: 'loading',
  tagSource: '',
};

const VALIDATION_ERROR_MESSAGE = 'We need at least one person to act on the feedback. The distribution list is empty.';
const SAVE_ERROR_MESSAGE = 'There is an error in saving the feedback people information';

const PENDING = 'pending';
const DONE = 'done';
const ERROR = 'error';


export default class FeedbackPeopleStore {
  state = PENDING;
  messageId = 0;
  correspondenceId = 0;
  correspondence = correspondenceTemplate;
  publishers = [];
  divisions = [];
  publishersByName = {};
  divisionsByName = {};
  functions = [];
  mailTags = {};
  publisherPubkitVsQmrs = {};
  divisionPubkitVsQmrs = {};



  uiFields = newUIFields;
  correspondenceTag = emptyCorrespondenceTag;
  feedbackPeople = [];
  shouldFinalize = false;


  constructor(props) {
    this.apiProxy = props.apiProxy;
    this.fetchPublishers();
    this.fetchDivisions();
    this.fetchFunctions();
    this.fetchPubkitVsQmrs();
    //this.correspondenceStore = new CorrespondenceStore({ apiProxy: props.apiProxy });
  }

  /**
   * Load the three domain store objects Publishers, Divisions and Functions
   */
  fetchPublishers = () => {
    this.state = PENDING;
    try {
      this.apiProxy.get(publisherListUrl, this.setPublishers);
    }
    catch (e) {
      this.state = ERROR;
      console.log(e);
    }
  }

  fetchPubkitVsQmrs = () => {
    this.state = PENDING;
    try {
      this.apiProxy.get(getPubkitQmrsPubDivUrl, this.setPubkitVsQmrs);
    }
    catch (e) {
      this.state = ERROR;
      console.log(e);
    }
  }

  fetchFunctions = () => {
    this.state = PENDING;
    try {
      this.apiProxy.get(functionListUrl, this.setFunctions);
    }
    catch (e) {
      this.state = ERROR;
      console.log(e);
    }
  }

  /**
   * If the publisherId is not yet selected, we should keep the divisions as empty. 
   */
  fetchDivisions = () => {
    if (this.uiFields.publisherId == 0) {
      console.log(this.uiFields.publisherId);
      this.divisions = [];
    }
    this.state = PENDING;
    try {
      const url = divisionListUrl.replace('{publisherId}', this.uiFields.publisherId);
      this.apiProxy.get(url, this.setDivisions);
    }
    catch (e) {
      this.state = ERROR;
      console.log(e);
    }
  }

  fetchDivisionsByPublisherId = (publisherId) => {
    this.uiFields.publisherId = publisherId;
    this.state = PENDING;
    try {
      const url = divisionListUrl.replace('{publisherId}', this.uiFields.publisherId);
      this.apiProxy.get(url, this.setDivisions);
    }
    catch (e) {
      this.state = ERROR;
      console.log(e);
    }
  }

  /**
      Load the feedback actors for the given mail
  */
  loadFeedback = flow(function* (correspondenceId) {
    this.state = PENDING;

    if((this.correspondenceId==0) || (this.correspondenceId=="0"))
    {
      return { feedbackId: this.uiFields.feedbackId, isFeedbackFinalized: this.uiFields.isFeedbackFinalized, isMailClosed: this.uiFields.isMailClosed };      
    }

    try {
      const response = yield this.apiProxy.getAsync(feedbackMailUrl + "?correspondenceId=" + correspondenceId);
      const data = yield response.json();
      
      this.setFeedbackPeople(data);

      return { feedbackId: this.uiFields.feedbackId, isFeedbackFinalized: this.uiFields.isFeedbackFinalized, isMailClosed: this.uiFields.isMailClosed };
    }
    catch (e) {
      this.state = ERROR;
      console.log(e);
    }
  })

  /**
   * If the publisher tag present in the correspondence tag, it is an indication
   * that we should attempt to obtain from the tag informations from MOB and PubKit.
   * 
   */
  loadCorrespondenceTag = flow(function* (messageId) {
    this.messageId = messageId;
    try {
      this.correspondenceTag = processingTag;
      const response = yield this.apiProxy.getAsync(mailTagUrl + "?messageId=" + messageId);
      const data = yield response.json();
      //this.mailTags = data;
      console.log(data);
      const publisherId = data.publisherId;
      const divisionId = data.divisionId;
      this.correspondenceTag = data.found ? data : emptyCorrespondenceTag;
      if (this.correspondenceTag.vertical == 2) {
         this.correspondenceTag.projectTag = this.correspondenceTag.ititle;
      }
      if (this.publisherPubkitVsQmrs[publisherId] != undefined){
        this.uiFields.publisherId = this.publisherPubkitVsQmrs[publisherId];
      }
      if (this.divisionPubkitVsQmrs[divisionId] != undefined){
        this.uiFields.divisionId = this.divisionPubkitVsQmrs[divisionId];
      }
      }
      
    catch (e) {
      console.log(e)
    }
    
    return this.correspondenceTag;
  })


  /**
   * Allow to edit when the state is DONE
   * When a feedback is finalized the correspondence will have a non-zero  feedback id 
   */
  get isEditable() {
    //return this.state === DONE && this.correspondenceId > 0 && !this.uiFields.isFeedbackFinalized;
    return this.state === DONE && !this.uiFields.isFeedbackFinalized;
  }


  /**
      The Feedback People Step is valid when we have the feedback id passed by the controller is non-zero.
      And the user should select at least one actor (Distribution List).
  */
  get isValidStep() {
    console.log("this.isEditable", this.isEditable);
    console.log("this.uiFields", this.uiFields);
    console.log("this.hasOwner", this.hasOwner);
    return this.hasOwner
      && !isBlank(this.uiFields.feedbackOn)
      && !isBlank(this.uiFields.feedbackType)
      && !isBlank(this.uiFields.severity)
      && !isBlank(this.uiFields.content);
  }

  get hasOwner() {
    const index = this.feedbackPeople.findIndex(people => people.owner);
    return index >= 0;
  }

  get publisherName() {
    if (this.uiFields.publisherId === 0) {
      return '#';
    }
    const selection = this.publishers.find(anItem => anItem.id === this.uiFields.publisherId)
    return selection.name;
  }

  get divisionName() {
    if (this.uiFields.divisionId === 0 || this.divisions.length == 0) {
      return '#';
    }
    const selection = this.divisions.find(anItem => anItem.id === this.uiFields.divisionId)
    if(selection === undefined)
    {
      return '';
    }
    return selection.name;
  }

  get feedbackName() {
    return this.publisherName + '-' + this.divisionName + '-' + this.uiFields.feedbackType.toUpperCase();
  }

  saveAndFinalize = async(body) => {
    console.log("3333");
    this.uiFields.shouldFinalize = true;
    const feedbackInfo = await this.save(body);
    return feedbackInfo;
  }

  /**
    Save the feedback general identification, feedback people (Actors) and
    the harvested correspondence tags.  
  */
    save = async () => {
      console.log("IsValid",this.isValidStep);
      if (!this.isValidStep) {
        throw VALIDATION_ERROR_MESSAGE;
      }
      console.log(222);
      this.state = PENDING;
      try {
  
        console.log(333);
        this.uiFields.name = this.feedbackName;
  
        this.uiFields.correspondenceId = this.correspondenceId;
        this.correspondence = JSON.parse(localStorage.getItem(this.messageId));
        localStorage.removeItem(this.messageId);
        console.log(444);
        console.log(this.correspondence);
        const body = { actors: this.feedbackPeople, feedbackPost: this.uiFields, correspondence:this.correspondence };
        const response = await this.apiProxy.asyncPost(feedbackPeopleUrl, body);
  
        const data = await response.json();
  
        this.setFeedbackPeople(data);
  
        return { feedbackId: this.uiFields.feedbackId, isFeedbackFinalized: this.uiFields.isFeedbackFinalized, isMailClosed: this.uiFields.isMailClosed };
      }
      catch (e) {
        this.state = ERROR;
        throw SAVE_ERROR_MESSAGE;
      }
    }


  // The private methods or internal callback methods

  setPublishers = data => {
    this.state = DONE;
    this.publishers = data;
    for (var i=0; i<data.length; i++){
      this.publishersByName[data[i]["name"]] = data[i]["id"];
    }
    console.log(data)

    this.uiFields.publisherId = 0;
    this.uiFields.divisionId = 0;
    this.uiFields.functionIds = [];

    this.feedbackPeople = [];
  }

  /**
  * Sample Response: [{"id":1,"pubkitId":55,"qmrsId":1,"type":"Publisher"},{"id":2,"pubkitId":92,"qmrsId":3,"type":"Division"}]
  */

  setPubkitVsQmrs = data => {
    this.publisherPubkitVsQmrs = {};
    this.divisionPubkitVsQmrs = {};
    for (var i=0; i<data.length; i++){
      if (data[i]["type"] == "Publisher"){
        this.publisherPubkitVsQmrs[data[i]["pubkitId"]] = data[i]["qmrsId"];
      } 
      if (data[i]["type"] == "Division"){
        this.divisionPubkitVsQmrs[data[i]["pubkitId"]] = data[i]["qmrsId"];
      } 
    }
    console.log(data)
  }

  setFunctions = data => {
    console.log("setFunctions", data);
    this.state = DONE;
    this.functions = data;
  }

  setDivisions = data => {
    this.state = DONE;
    this.divisions = data;
    for (var i=0; i<data.length; i++){
      this.divisionsByName[data[i]["name"]] = data[i]["id"];
    }
    console.log(data)
  }

  /**
   * Setting or resetting the Publisher Id will cause all the actors
   * empty. If the reset the publisher id of an existing feedback, the 
   * new actors should be mapped to that feedback. Hence we should not
   * reset the feedback id in this method.
   * 
   * Refer setFeedbackPeople method.
   *   
   */
  setPublisherId = publisherId => {
    this.state = DONE;
    if (publisherId !== this.uiFields.publisherId) {
      this.uiFields.publisherId = publisherId;
      this.uiFields.divisionId = 0;
      this.uiFields.functionIds = [];
      this.feedbackPeople = [];
      this.fetchDivisions();
    }
  }

  setDivisionId = divisionId => {
    this.state = DONE;
    this.uiFields.divisionId = divisionId;
    this.fetchDistributionList();
  }

  setFunctionIds = functionIds => {
    this.state = DONE;
    this.uiFields.functionIds = functionIds;
    this.fetchDistributionList();
  }

  fetchDistributionList = () => {
    this.state = DONE;

    if (!this.canFetchDistributionList()) {
      return;
    }

    this.state = PENDING;

    try {
      const url = distributionListUrl
        .replace('{divisionId}', this.uiFields.divisionId)
        .replace('{functionIds}', this.uiFields.functionIds);

      this.apiProxy.get(url, this.toFeedbackPeople);
    }
    catch (e) {
      this.feedbackPeople = [];
      this.state = ERROR;
    }
  }

  /**
    To allow refetching the distribution list when the user has selected
    a division id and function id.

    The distribution list is mapped to a division and function.
  */
  canFetchDistributionList = () => {
    return this.uiFields.divisionId != 0 && this.uiFields.functionIds.length > 0;
  }

  /**
    To transform the Distribution List into Feedback People.

    We should preserve people who are already started acting on a feedback. ActivePeople.


  */

  toFeedbackPeople = distributionList => {
    this.state = DONE;

    const activePeople = this.feedbackPeople.filter(people => people.isActive);
    distributionList.map(item => {
      const index = activePeople.findIndex(people => people.userId == item.user.id)
      if (index == -1) {
        const map = {};
        map.id = 0;
        map.divisionId = item.divisionId;
        map.functionId = item.functionId;
        map.userId = item.user.id;
        map.userName = item.user.name;
        map.email = item.user.email;
        map.owner = item.owner;
        map.addressIn = this.sanitizeAddressIn(item.owner, item.addressIn);
        map.isActive = false;

        activePeople.push(map);
      }
    })

    this.feedbackPeople = activePeople;
  }

  resetFeedback = () => {
    this.feedbackPeople = [];
    this.uiFields.feedbackId = 0;
    this.uiFields.feedbackType = '';
    this.uiFields.feedbackOn = '';
    this.uiFields.severity = '';
    this.uiFields.name = '';
    this.uiFields.content = '';
    this.uiFields.projectManager = '';
    this.uiFields.providerRole = '';
    this.uiFields.projectTag = '';
    this.uiFields.isFeedbackFinalized = false;
    this.uiFields.isMailClosed = false;
    this.uiFields.shouldFinalize = false;
    this.uiFields.publisherId = 0;
    this.uiFields.divisionId = 0;
    this.uiFields.functionIds = [];
   
  }

  /**
   * When we load the data for an existing feedback, let us capture
   * the required information about the feedback 
   * and the mapped correspondence tags if available
   */
  captureFeedback = (data) => {

    const feedback = data[0].feedback;

    this.uiFields.feedbackId = feedback.id;
    this.uiFields.feedbackType = feedback.feedbackType;
    this.uiFields.feedbackOn = feedback.feedbackOn;
    this.uiFields.severity = feedback.severity;
    this.uiFields.isFeedbackFinalized = feedback.finalizedAt;
    this.uiFields.content = !isBlank(feedback.content) ? feedback.content: '' ;
    this.uiFields.projectManager = !isBlank(feedback.projectManager) ? feedback.projectManager: '' ;
    this.uiFields.providerRole = !isBlank(feedback.providerRole) ? feedback.providerRole: '' ;
    this.uiFields.projectTag = !isBlank(feedback.projectTag) ? feedback.projectTag: '' ;
    this.uiFields.isMailClosed = feedback.correspondence.feedbackId && feedback.correspondence.feedbackId > 0;
  }

  captureFeedbackActors = (data) => {
    const peopleList = [];
    const list = [];

    const publisherId = data[0].division.publisher.id;
    const divisionId = data[0].division.id;

    data.map(item => {

      var functionId = null;

      if (item.function) {
        functionId = item.function.id;

        if (!list.includes(functionId)) {
          list.push(functionId);
        }
      }

      const map = {};
      map.id = item.id;
      map.publisherId = publisherId;
      map.divisionId = divisionId;
      map.functionId = functionId;
      map.userId = item.user.id;
      map.userName = item.user.name;
      map.email = item.user.email;
      map.owner = item.owner;
      map.addressIn = this.sanitizeAddressIn(item.owner, item.addressIn);
      map.isActive = item.isActive

      peopleList.push(map);
    })

    this.uiFields.functionIds = list;
    this.feedbackPeople = peopleList;
  }

  /**
   * Let us ensure that only the owners in the TO, 
   * If the addressIn is blank for some reason let us make it as cc.
   * If we have to for non-owners let us mark them as cc.
   */
  sanitizeAddressIn = (isOwner, addressIn) => {
    if (isOwner) {
      return 'to';
    }

    const result = isBlank(addressIn) ? 'cc' : addressIn.toLowerCase();
    return result === 'to' ? 'cc' : result;
  }

  /**
    A callback method in the context of after loading an existing feedback.

    If we do not have any actors mapped, we should assume that it is a new
    feedback.

    Hence first clear the feedback People and the feedbackId;
  */
  setFeedbackPeople = data => {

    this.resetFeedback();

    this.state = DONE;

    if (!data[0]) {
      this.setPublisherId(0);
      return;
    }

    const publisherId = data[0].division.publisher.id;
    const divisionId = data[0].division.id;

    this.setPublisherId(publisherId);
    this.setDivisionId(divisionId);
    this.captureFeedback(data);
    this.captureFeedbackActors(data);

  }

}

decorate(FeedbackPeopleStore, {
  state: observable,

  uiFields: observable,
  correspondenceTag: observable,
  feedbackPeople: observable,

  publishers: observable,
  divisions: observable,
  functions: observable,

  isValidStep: computed,
  isEditable: computed,
  hasOwner: computed,

  publisherName: computed,
  divisionName: computed,
  feedbackName: computed,

  fetchPublishers: action,
  fetchFunctions: action,
  fetchDivisions: action,
  fetchDivisionsByPublisherId: action,
  fetchPubkitVsQmrs: action,

  save: action
})
