import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { observer } from 'mobx-react';

import ActionPeople from './ActionPeople';
import ActionContext from './ActionContext';
import ActionMailTags from './ActionMailTags';

import FeedbackInteractions from './FeedbackInteractions';
import FeedbackInfo from './FeedbackInfo';
import MailWidget from './MailWidget';
import { feedbackStore } from './stores/FeedbackStore';



const styles = theme => ({
  root: {
    height: window.innerHeight / 2.5,
    width: '100%',
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  grow: {
    flexGrow: 1,
  },
});

@observer
class FeedbackDetails extends Component {


  getTabComponent =   (tabIndex) =>{
    switch (tabIndex) {
      case 0:
        return <FeedbackInteractions />;
      case 1:
        return <FeedbackInfo />;
      case 2:
        return <ActionContext />;
      case 3:
        return <ActionContext />;
        //return <MailWidget correspondence = {feedbackStore.selectedFeedback.correspondence}/>;
      case 4:
        return <ActionPeople />;
      case 5:
        return <ActionMailTags />;
      default:
        return 'Unknown step';
    }
  }

  renderTabs() {
    const { classes } = this.props;
    return <div className={classes.root}>
      <Tabs variant="scrollable" value={feedbackStore.tabIndex} onChange={(event,value)=>{feedbackStore.tabIndex = value}}
        scrollButtons="auto" indicatorColor="primary"
        textColor="primary">
        <Tab label="Notes" />
        <Tab label="Info" />
        <Tab label="Context" />
        <Tab label="Reference Mail" />
        <Tab label="People" />
        <Tab label="Mail Tags" />
      </Tabs>
      {this.getTabComponent(feedbackStore.tabIndex)}
    </div>;
  }

  render() {
    return (
      this.renderTabs()
    );
  };
}

FeedbackDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FeedbackDetails);