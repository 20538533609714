import { withStyles } from '@material-ui/core/styles';
import { Provider, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ObservationUIContainer from './ObservationUIContainer';
import ObservationStore from './stores/ObservationStore';
import CorrespondenceStore from './stores/CorrespondenceStore';


const styles = theme => ({
  root: {
    padding: theme.spacing.unit,
  },
});


@inject("appStore")
class ObservationWorkbench extends Component {

  constructor(props)
  {
    super(props);
    this.correspondenceStore = new CorrespondenceStore({ apiProxy: props.appStore.apiProxy });
    this.observationStore = new ObservationStore({ apiProxy: props.appStore.apiProxy });
  }

  render() {
    const { classes } = this.props;
    return (
      <Provider correspondenceStore={this.correspondenceStore} observationStore={this.observationStore}>
        <div className={classes.root}>
          <ObservationUIContainer />
        </div>
      </Provider>
    );
  }
}

ObservationWorkbench.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ObservationWorkbench);
