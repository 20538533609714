import React, { Component } from "react";
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles'

import { observer } from 'mobx-react';
import { dimensionStore } from "./stores/DimensionStore";
import { LOADING, ERROR } from "./stores/LabelStore";
import { CircularProgress, Typography } from "@material-ui/core";
import { feedbackStore } from "./stores/FeedbackStore";

const styles = theme => ({
    root : {
        padding: '30px'
    },
    content: {
        height: dimensionStore.height,
        overflow: "auto",
        maxHeight: dimensionStore.height,
    },
});


@observer
class ActionContext extends Component {
    render() {
        const { classes } = this.props;
        
        if (feedbackStore.detailState === LOADING) {
            return <CircularProgress style={{ "marginTop": "25%", "marginLeft": "50%", "marginBottom": "15%" }} />
        }

        if(feedbackStore.detailState === ERROR)
        {
            return (<div><p style={{ "display": "grid", "alignItems": "center", "justifyContent": "center" }} >Error while loading content.</p></div>);
        }

        return (
            <div className={classes.root} style={dimensionStore.mailHeight()}>
                <Typography className={classes.content} style={dimensionStore.mailHeight()} dangerouslySetInnerHTML={{ __html: feedbackStore.context }}></Typography>
            </div>
        );
    }
}

ActionContext.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ActionContext);