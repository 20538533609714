import React, { Component } from "react";
import '../feedback.css';
import { withStyles } from '@material-ui/core/styles';
import { DatePicker, Modal  } from 'antd';
import moment from 'moment';
import 'antd/dist/antd.css';
const { MonthPicker } = DatePicker;
import { Button } from '@material-ui/core';
import {appStore} from './stores/AppStore';
import {AgGridReact} from 'ag-grid-react';
import APIProxy from './stores/APIProxy';
import { dimensionStore } from './stores/DimensionStore.js';
import { makeStyles } from  '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {Line} from 'react-chartjs-2';
import {Doughnut} from 'react-chartjs-2';
import {
  getFeedbackScore
} from './stores/APIEndpoints';

const dateFormat = 'MM/YYYY';

const options = {
  legend: {
    position: 'bottom',
    labels: {
      boxWidth: 15,
      fontSize: 14
    }
  }
}

const lineoptions = {
  legend: {
    display: false,
    labels: {
      boxWidth: 15,
      fontSize: 14
    }
  },
  scales: {
    yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'No. of Feedback',
          fontSize: 14,
          fontStyle: 'bold'
          },
          ticks: {
            stepSize: 1,
            beginAtZero: true,
          },
        }]
  },
}

const manageroptions = {
  legend: {
    display: false,
    labels: {
      boxWidth: 15,
      fontSize: 14
    }
  },
  scales: {
    yAxes: [{
          ticks: {
            stepSize: 1,
            beginAtZero: true,
          },
        }]
  },
}

const axesoptions = {
  scales: {
    yAxes: [{
      fontSize: 14
    }]
}
}

let myFeedbackTypes=['Positive','Negative']
let teamFeedbackTypes=['Positive','Negative']

let myBackgroundColor=['#008000', '#F44336']
let teamBackgroundColor=['#008000','#F44336']

let myFeedbackValues = [0,0]
let teamFeedbackValues = [0,0]

const months = ["jan","feb","mar","apr","may","jun","jul","aug","sep","oct","nov","decem",]

let defaultHistory = {
        "negative": {"id":32,"userid":3,"feedbackType":"negative","uniquekey":null,"year":2020,"jan":0,"feb":0,"mar":0,"apr":1,"may":0,"jun":3,"jul":0,"aug":0,"sep":0,"oct":0,"nov":0,"decem":0},
        "positive": {"id":33,"userid":3,"feedbackType":"positive","uniquekey":null,"updatedat":"2020-06-23t03:48:07.220+0000","createdat":"2020-06-23t03:48:07.220+0000","year":2020,"jan":0,"feb":0,"mar":0,"apr":0,"may":0,"jun":0,"jul":0,"aug":0,"sep":0,"oct":0,"nov":0,"decem":0},
        "manager": {"id":33,"userid":3,"feedbackType":"manager","uniquekey":null,"updatedat":"2020-06-23t03:48:07.220+0000","createdat":"2020-06-23t03:48:07.220+0000","year":2020,"jan":0,"feb":0,"mar":0,"apr":0,"may":0,"jun":0,"jul":0,"aug":0,"sep":0,"oct":0,"nov":0,"decem":0}
      };


class PerformerScorecard extends Component {
  constructor(prop){
    super(prop);
    this.state = {
      "company":{"id":1,"total":0,"ltotal":0,"ptotal":0,"ntotal":0},
      "team":{"id":1,"total":0,"ltotal":0,"ptotal":0,"ntotal":0},
      "history":defaultHistory,
      "user":{"id":0,"total":0,"ltotal":0,"ptotal":0,"ntotal":0,"name":"Unknown","empid":"9105276","teamid":1,"roleid":4,"teamname":"r&d","managerid":"1","email":"bhakyaraj.k@newgen.co"}
    };
  }


async componentDidMount(){

  const url = getFeedbackScore.replace('#{userId}', appStore.credentials.userId).replace('#{year}', new Date().getFullYear());
        const response = await appStore.apiProxy.getAsync(url);
        let data = await response.json();
        console.log(data);
        if (data.user != null) {
          this.setState({user: data.user});
          myFeedbackValues = [data.user.ptotal, data.user.ntotal];
          if((data.user.ntotal==0) && (data.user.ptotal==0))
          {
            myFeedbackValues = [0, 0, 1]
            myFeedbackTypes.push("No Feedback");
            myBackgroundColor.push('#FFFF00')
          }
        }
        if (data.team != null) {
          this.setState({team: data.team});
          teamFeedbackValues = [data.team.ptotal, data.team.ntotal];
          if((data.team.ntotal==0) && (data.team.ptotal==0))
          {
            teamFeedbackValues = [0, 0, 1]
            teamFeedbackTypes.push("No Feedback");
            teamBackgroundColor.push('#FFFF00')
          }
        }
        if (data.company != null) {
          this.setState({company: data.company});
        }
        if (data.history != null) {
          for(let i=0;i<data.history.length;i++){
            defaultHistory[data.history[i]["feedbackType"]] = data.history[i];
          }
          console.log(defaultHistory["negative"]);
          console.log(defaultHistory["positive"]);
        
        //if performer got a negative feedback, the manager feel bad
        //if performer got nither positive nor nagtive feedback, the manager feel nothing
        //if performer got postive feedback, the manager feel happy
        //Author Bhakyaraj

        for(var i=0; i<months.length; i++)
        {
          var month = months[i];
          if (defaultHistory["negative"][month]>0)
          {
            defaultHistory["manager"][month] = -1;
          }
          else if (defaultHistory["positive"][month]>0)
          {
            defaultHistory["manager"][month] = +1;
          }
          else {
            defaultHistory["manager"][month] = 0;
          }
          console.log(defaultHistory["manager"][month]);
        }
          this.setState({history: defaultHistory});
          console.log(defaultHistory);
        }
}

render()
    {
      let data = {};
      let managerFeels = {};
      let myFeedback = {};
      let teamFeedback = {};
      let companyFeedback = {};
        companyFeedback = {
          labels: ['Positive','Negative'],
          datasets: [{
            data: [this.state.company.ptotal, this.state.company.ntotal],
            backgroundColor: ['#008000', '#F44336']
          }]
        };

        let negativeArray = [];
        let positiveArray = [];
        let managerArray = [];
        for(var i=0; i<months.length; i++)
        {
          negativeArray.push(this.state.history["negative"][months[i]]);
          positiveArray.push(this.state.history["positive"][months[i]]);
          managerArray.push(this.state.history["manager"][months[i]]);
        }
        
      
        data = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
          {
            label: 'Positive Feedback',
            fill: false,
            lineTension: 0.4,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: '#008000',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#008000',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#008000',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: positiveArray
          },
          {
            label: 'Negative Feedback',
            fill: false,
            lineTension: 0.4,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: '#F44336',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#F44336',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#F44336',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: negativeArray
          }
        ]
      };

        managerFeels = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
          {
            label: 'Negative Feedback',
            fill: false,
            lineTension: 0.4,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: '#F44336',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#F44336',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#F44336',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: managerArray
          }
        ]
      };
      myFeedback = {
        labels: myFeedbackTypes,
        datasets: [{
          data: myFeedbackValues,
          backgroundColor: myBackgroundColor
        }]
      };
      teamFeedback = {
        labels: teamFeedbackTypes,
        datasets: [{
          data: teamFeedbackValues,
          backgroundColor: teamBackgroundColor
        }]
      };  
      
      return (
        <div className="chartbox">
          {/* <MonthPicker style={{'float':'right','marginTop':'-20px','marginBottom':'20px'}}
                format={dateFormat}
                onChange={this.feedbackRangeSelected}/> */}
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Paper>
              <div style={{"height":"310px","padding-top":"30px"}}>
              <Doughnut data={myFeedback} height={200} options={options}/>
              <p style={{"text-align":"center", "fontSize":"14px", "fontWeight":"bold"}}>My Feedback</p>
              </div>
              </Paper>
            </Grid>
            <Grid item xs={9}>
              <Paper style={{"margin-left":"20px"}}>
              <div style={{"padding":"25px", "height":"310px"}}>
              <Line data={data} height={78} options={lineoptions} />
              </div>
              </Paper>
            </Grid>
          </Grid>
        <Grid container spacing={3}>
            <Grid item xs={3}>
              <Paper style={{"margin-top":"20px"}}>
              <div style={{"height":"310px","padding-top":"30px"}}>
              <Doughnut data={teamFeedback} height={200} options={options} />
              <p style={{"text-align":"center", "fontSize":"14px", "fontWeight":"bold"}}>Team Feedback</p>
              </div>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper style={{"margin-top":"20px", "margin-left":"20px"}}>
              <div style={{"padding":"40px", "height":"310px"}}>
              <p style={{"text-align":"left", "fontSize":"14px", "fontWeight":"bold", "margin-top":"-30px"}}>Manager feels about you</p>
              <Line data={managerFeels} height={121} options={manageroptions} />
              </div>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper style={{"margin-top":"20px", "margin-left":"20px"}}>
              <div style={{"height":"310px","padding-top":"30px"}}>
              <Doughnut data={companyFeedback} height={200} options={options} />
              <p style={{"text-align":"center", "fontSize":"14px", "fontWeight":"bold"}}>Company Feedback</p>
              </div>
              </Paper>
            </Grid>
          </Grid>
        </div>
      )
    }


}


export default PerformerScorecard;