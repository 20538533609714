import { decorate, observable, action } from 'mobx';

class SnackBarStore 
{

    message = ''
    state=false
    variant = ''

    showSuccessMessage = (message)=>{
        this.variant = 'success';
        this.state=true;
        this.message = message;
    }

    showErrorMessage = (message) =>{
        this.variant = 'error';
        this.state=true;
        this.message=message;
    }

    hideSnackBar = () =>{
        this.message='';
        this.state=false;
    }
}

decorate(SnackBarStore,{
    message: observable,
    state: observable,
    variant: observable
});

export const snackBarStore = new SnackBarStore();