import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';

import { inject, observer } from 'mobx-react';
import { text, reactionText, multiSelect, selectableText, tag } from './InputElements'

import { feedbackOns, feedbackKinds, feedbackSeverities } from './stores/LabelStore';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.spacing.unit * 2
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  chip: {

  },
  value: {
    padding: `${theme.spacing.unit * 0}px ${theme.spacing.unit * 1}px`,
  },
  menu: {
    width: 500,
  },
  column2: {
    flexBasis: '40%',
  },
  column3: {
    flexBasis: '25%',
  },
  column1: {
    flexBasis: '25%',
    padding: `${theme.spacing.unit * 1}px ${theme.spacing.unit * 1}px`,
  },
  context: {
    flexBasis: '100%',
    padding: `${theme.spacing.unit * 1}px ${theme.spacing.unit * 1}px`,
  },
  helper: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    padding: `${theme.spacing.unit * 1}px ${theme.spacing.unit * 2}px`,
  },
});

const NO_ACTORS = 'No owners are selected for this feedback. The action items for the feedback can be provided only by the owner of the functions you select. Hence please map a person as the owner in the Publisher Division Function Mapping configuration';
const NEW_FEEDBACK = 'New Feedback';

@inject('feedbackControllerStore')
@observer
class FeedbackPeople extends React.Component {

  render() {
    const { classes } = this.props;
    return (
      <div>
      <form className={classes.container} noValidate>
        {this.renderQualifiers()}
        {this.renderDistributionLists()}
        {this.renderInfo()}
      </form>
      <form className={classes.container}>
      {this.renderContext()}
    </form></div>
    );
  }

  renderContext() {
    const { classes } = this.props;
    const domainStore = this.props.feedbackControllerStore.peopleStore;
    const uiFields = domainStore.uiFields;
    const mailTag = domainStore.correspondenceTag;
    //const editable = domainStore.isEditable;
    const editable = true;
    //const editable = this.props.feedbackControllerStore.isEditable;
    const isMultiline = true;
    return(
      text('content', uiFields, editable, classes, true, isMultiline)
      //text('content', {...uiFields, ...mailTag}, editable, classes, true, isMultiline)
    );
  }

  renderInfo() {
    const { classes } = this.props;
    const domainStore = this.props.feedbackControllerStore.peopleStore;
    const uiFields = domainStore.uiFields;
    const mailTag = domainStore.correspondenceTag;
    //const editable = true;
    const editable = this.props.feedbackControllerStore.isEditable;

        //{text("providerRole", uiFields, editable, classes, true, false)}
        //{text("providerRole", {...uiFields, ...mailTag}, editable, classes, true, false)}
        //{text("projectTag", uiFields, editable, classes, true, false)}
        //{text("projectManager", uiFields, editable, classes, true, false)}
        //{text("projectTag", {...uiFields, ...mailTag}, editable, classes, true, false)}
        //{text("projectManager", {...uiFields, ...mailTag}, editable, classes, true, false)}
        
    return (
      <div className={classNames(classes.column3, classes.helper)}>
        <Typography gutterBottom variant='h5'>Details</Typography>
        {text("providerRole", uiFields, editable, classes, true, false)}
        {text("projectTag", uiFields, editable, classes, true, false)}
        {text("projectManager", uiFields, editable, classes, true, false)}
      </div>
    );
  }
  renderDistributionLists() {
    const { classes } = this.props;

    const domainStore = this.props.feedbackControllerStore.peopleStore;
    const publishers = domainStore.publishers;
    const divisions = domainStore.divisions;
    const functions = domainStore.functions;

    const uiFields = domainStore.uiFields;

    const feedbackPeople = domainStore.feedbackPeople;
    const owners = feedbackPeople.filter(people => people.owner);
    const ccs = feedbackPeople.filter(people => people.addressIn === 'cc');
    const bccs = feedbackPeople.filter(people => people.addressIn === 'bcc');

    //const editable = domainStore.isEditable;
    const editable = this.props.feedbackControllerStore.isEditable;
    const divisionEditable = (divisions.length > 0) && editable;
    
    return (
      <div className={classNames(classes.column2, classes.helper)}>
        <Typography gutterBottom variant='h5'>Distribution List</Typography>
        <Grid item xs={12}>
          {reactionText("publisherId", uiFields, domainStore.setPublisherId, editable, publishers, classes)}
          {reactionText("divisionId", uiFields, domainStore.setDivisionId, divisionEditable, divisions, classes)}
          <span style={{"padding": "10px"}}>{multiSelect("functionIds", uiFields, domainStore.setFunctionIds, editable, functions, classes)}</span>
        </Grid>
        <hr />
        <div>
          {this.renderPeople(owners, "Assigned To", true)}
          {this.renderPeople(ccs, "Copy", false)}
          {this.renderPeople(bccs, "BCC ", false)}
          {this.renderNoActors(owners)}
        </div>
      </div>
    );
  }

  renderQualifiers() {
    const { classes } = this.props;
    const domainStore = this.props.feedbackControllerStore.peopleStore;
    const uiFields = domainStore.uiFields;
    const editable = this.props.feedbackControllerStore.isEditable;
    return (
      <div className={classNames(classes.column1)}>
        <Typography gutterBottom variant='h5'>Qualifiers</Typography>
        <Grid item xs={12}>
          {selectableText("feedbackType", uiFields, editable, feedbackKinds, classes)}
          {selectableText("feedbackOn", uiFields, editable, feedbackOns, classes)}
          {selectableText("severity", uiFields, editable, feedbackSeverities, classes)}
        </Grid>
        <hr />
        {tag('Feedback Id', domainStore.uiFields.feedbackId === 0 ? NEW_FEEDBACK : domainStore.uiFields.feedbackId)}
        {this.renderFeedbackName(domainStore)}
      </div>
    );
  }

  renderPeople(people, label, isOwner) {
    if (people.length > 0) {
      console.log(people, label, isOwner, 'AAAAAAAAA');
      return (
        <Grid container>
          <Grid item xs={12} >
            <Typography variant='caption'>{label}</Typography>
          </Grid>
          <Grid item xs={12}>
            {isOwner ? this.asChip(people, 'default') : this.asChip(people, 'outlined')}
          </Grid>
        </Grid>
      )
    }
  }

  renderNoActors(people) {
    if (people.length === 0) {
      return (
        <Typography gutterBottom variant="body1" color="error">{NO_ACTORS}</Typography>
      )
    }
  }

  renderFeedbackName(domainStore) {
    if (domainStore.isValidStep) {
      return <Chip key='feedbackName' label={domainStore.feedbackName} color="primary" variant='default' />
    }
    return <Chip key='feedbackName' label={domainStore.feedbackName} color="secondary" variant='outlined' />
  }

  asChip(people, variant) {
    const { classes } = this.props;
    console.log(people, variant, 'BBBBBBBBB');
    return people.map(item => (
      <Chip key={item.userId} className={classes.chip} label={item.email} color="secondary" variant={variant} />
    ));
  }

}

FeedbackPeople.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FeedbackPeople);
