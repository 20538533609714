import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import Refresh from '@material-ui/icons/Refresh';
import MailIcon from '@material-ui/icons/Mail';

import { AgGridReact } from 'ag-grid-react';
import { observer, inject } from 'mobx-react';
import { StringFilter } from './filters/StringFilter';

const styles = theme => ({
  root: {
    height: window.innerHeight,
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
});

const columnDefs = [
  { headerName: "Name", field: "userName", sort: 'asc' ,filterFramework: StringFilter },
  { headerName: "Email", field: "userEmail" ,filterFramework: StringFilter},
  { headerName: "Pending Count", field: "total"},
  { headerName: "> 2 weeks", field: "high"},
  { headerName: "1-2 weeks", field: "medium"},
  { headerName: "less than a week", field: "low"},
]

@inject("feedbackStatStore")
@observer
class PendingFeedbackCounter extends Component {

  onGridReady = (params) => {
    if (!params) {
      return;
    }

    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.addEventListener('rowClicked', this.mailSelected);

    this.props.feedbackStatStore.fetchUserPendingStats();
  }

  renderAgGrid() {

    const { classes } = this.props;
    const grid_classes = [classes.root, "ag-theme-material"].join(' ');

    if (!this.params) {
      return (
        <div className={grid_classes}>
          <AgGridReact columnDefs={columnDefs} rowSelection={'single'} onGridReady={this.onGridReady} enableSorting={true} enableFilter={true} />
        </div>
      );
    }
  }

  renderRowData(unassignedFeedbacks) {
    if (this.gridApi) {
      this.gridApi.setRowData(unassignedFeedbacks);
    }
  }

  render() {
    const { feedbackStatStore, classes } = this.props;
    return (
      <Fragment>
        <AppBar position="static" color="secondary">
          <Toolbar variant="dense">
            <Typography variant="subtitle1" color="inherit" className={classes.grow}>
              Pending Feedbacks Count
                        </Typography>
            <IconButton color="inherit" onClick={()=>{feedbackStatStore.fetchUserPendingStats()}}><Refresh /></IconButton>
            <Badge badgeContent={feedbackStatStore.userPendingStats.length} max={10000} style={{ 'marginLeft': '10px' }} color="primary" />
          </Toolbar>
        </AppBar>
        {this.renderAgGrid()}
        {this.renderRowData(feedbackStatStore.userPendingStats)}
      </Fragment>
    );
  }
}
export default withStyles(styles)(PendingFeedbackCounter);