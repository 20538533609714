import React, { Component } from "react";
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { tag } from './InputElements'

import { inject, observer } from 'mobx-react';
import { feedbackStore } from "./stores/FeedbackStore";


const styles = theme => ({

});

@observer
class ActionMailTags extends Component {

    render() {
        const { classes } = this.props;
        const helperInfo = feedbackStore.correspondenceTag;
        console.log(helperInfo);
        return (
            <div>
                <Grid item xs={12}>
                    {tag("Publisher", helperInfo.publisherTag)}
                    {tag("Division", helperInfo.divisionTag)}
                    {tag("Project Name", helperInfo.projectTag)}
                    {tag("Project Manager", helperInfo.managerTag)}
                </Grid>
            </div>
        )
    }
}


ActionMailTags.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ActionMailTags);