import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import Refresh from '@material-ui/icons/Refresh';
import MailIcon from '@material-ui/icons/Mail';

import { AgGridReact } from 'ag-grid-react';
import { observer, inject } from 'mobx-react';

const styles = theme => ({
  root: {
    height: window.innerHeight,
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
});

const columnDefs = [
  { headerName: "Email Date", field:"feedbackDate", width: 120 },
  { headerName: "Circulated Date", field:"circulateDate", width: 130 },
  { headerName: "Month & Year", field:"feedbackMonth", width: 120 },
  { headerName: "Id",   field:"feedbackId",  sort: 'asc', width: 100 },
  { headerName: "Name", field:"feedbackName", },
  { headerName: "Type", field:"feedbackType",  width: 100 },
  { headerName: "On",   field:"feedbackOn",  width: 100 },
  { headerName: "Severity",   field:"severity",   width: 100 },
  { headerName: "Provider Role",   field:"providerRole", width: 120 },
  { headerName: "Publisher",   field:"publisherName",  },
  { headerName: "Division",   field:"divisionName",  },
  { headerName: "Project",   field:"projectTag",  },
  { headerName: "PM",   field:"projectManager",  },
  { headerName: "Function",   field:"functionName",  },
  { headerName: "Assigned To",   field:"actorName",  },
  { headerName: "Context",   field:"content",  },
  { headerName: "Not a Feedback",   field:"notAFeedback"},
  { headerName: "Justification for Not a Feedback/Reassigned",   field:"feedbackDescription"},
  { headerName: "Root Cause",   field:"rootCause",  },
  { headerName: "Sub-Root Cause",   field:"subRootCause",  },
  { headerName: "Root Cause Description", field:"rootCauseDescription"},
  { headerName: "Stage", field:"rootCauseStage"},
  { headerName: "Vendor Name", field:"vendorName"},
  { headerName: "Root Cause Category", field:"rootCauseCategory"},
  { headerName: "Primary Performers", field:"performerName"},
  { headerName: "Primary Performers Team", field:"primaryPerformerTeam"},
  { headerName: "Secondary Performers", field:"secondaryName"},
  { headerName: "Secondary Performers Team", field:"secondaryPerformerTeam"},
  { headerName: "RCA Submitted Date", field:"rcaDate", width: 150 },
  { headerName: "Action",   field:"action",  },
  { headerName: "ActionItem Description", field:"actionItemDescription"},
  { headerName: "Closure Flag",   field:"actionItemClosedFlag",  },
  { headerName: "Expected Comp. Date",   field:"expectedEndDate",  },
  { headerName: "Audit Date",   field:"auditDate",  },
  { headerName: "Actual End Date",   field:"actualEndDate",  },
  { headerName: "Action Item Status",   field:"actionItemStatus",  },
  { headerName: "Primary Emp. No.", field:"primaryEmpId"},
  { headerName: "Secondary Emp. No.", field:"secondaryEmpId"},
  { headerName: "QC Function", field:"qcFunction"},
  { headerName: "Function for Report", field:"functionForReport"},
  { headerName: "Division Group", field:"divisionGroup"},
  { headerName: "BU Head", field:"buHead"},
  { headerName: "Sub-Category", field:"subCategory"},
  { headerName: "Category", field:"category"}
 ];

@inject("correspondenceStore")
@inject("observationStore")
@observer
class ObservationUIContainer extends Component {

  constructor(props) {
    super(props);
  }

  onGridReady = (params) => {
    if (!params) {
      return;
    }

    this.gridApi = params.api;
    this.gridApi.addEventListener('rowClicked', this.mailSelected);

    this.props.observationStore.fetchFeedbacks();
  }

  refresh = () => {
    this.props.observationStore.fetchFeedbacks();
  }


  mailSelected = (params) => {
 
  }

  renderAgGrid() {

    const { classes } = this.props;
    const grid_classes = [classes.root, "ag-theme-material"].join(' ');

    if (!this.params) {
      return (
        <div className={grid_classes}>
          <AgGridReact 
              columnDefs={columnDefs} 
              rowSelection={'single'} 
              onGridReady={this.onGridReady} 
              enableSorting={true} 
              animateRows={true}
              enableFilter={true}
              enableColResize={true}
              rowGroupPanelShow={'always'}/>
        </div>
      );
    }
  }

  renderRowData(observationData) {
    if (this.gridApi) {
      this.gridApi.setRowData(observationData);
    }
  }

  render() {
    const store = this.props.observationStore;
    const observationData = store.observationData;

    const { classes } = this.props;
    return (
      <Fragment>
        <AppBar position="static" color="secondary">
          <Toolbar variant="dense">
            <Typography variant="subtitle1" color="inherit" className={classes.grow}>
              Feedback Observation
          </Typography>
            <IconButton color="inherit" onClick={this.refresh}><Refresh /></IconButton>
            <Badge badgeContent={store.rowCount} max={15000} style={{ 'marginLeft': '10px' }} color="primary" />
          </Toolbar>
        </AppBar>
        {this.renderAgGrid()}
        {this.renderRowData(observationData)}
      </Fragment>
    );
  }
}

ObservationUIContainer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ObservationUIContainer);
