import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import { DatePicker, Modal  } from 'antd';
import moment from 'moment';
import 'antd/dist/antd.css';
const { RangePicker } = DatePicker;
import { Button, Grid } from '@material-ui/core';
import {appStore} from './stores/AppStore';
import '../feedback.css';
import {AgGridReact} from 'ag-grid-react';
import APIProxy from './stores/APIProxy';
import { dimensionStore } from './stores/DimensionStore.js';

// import '../Delivery.css';



import {
  feedbackvsactor, getFeedbackReport
} from './stores/APIEndpoints';
import { now } from "moment";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}





const dateFormat = 'DD/MM/YYYY';
const monthFormat = 'YYYY/MM';

const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const styles = theme => ({
  root: {
    height: window.innerHeight / 2.5,
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
});

const dt = new Date();
const currentStartDate = new Date(dt.getFullYear(), dt.getMonth(), 1);
const currentEndDate = new Date(dt.getFullYear(), dt.getMonth() + 1, 0);
var startDatePayload, endDatePayload;
const gridOptions = {
  onCellClicked: function(event) { alert('clicked');console.log(event) ;},
}

class FeedbackAnalysis extends Component
{
    constructor(prop){
      super(prop);
      this.state = {
        columnDefs:[],
        rowData: [],
        visible:false,
        actorName:'',
        content:'',
        divisionName:'',
        expectedEndDate:'',
        feedbackDate:'',
        feedbackName:'',
        functionName:'',
        feedbackOn:'',

    };
    }
   
    async componentDidMount() {
      this.fetchFeedbackPeople();
       this.setHeaders();
    }
    setHeaders = (startDate, endDate) => {
     let columnDefsDublicate = [
        {headerName: 'Sl. No.', valueGetter: 'node.rowIndex + 1', width: 70, cellStyle:{textAlign:'left'}, pinned: 'left'},
        {headerName: 'Emp. No.', field: 'emp_id', width: 90, cellStyle:{textAlign:'left'}, pinned: 'left'},
        {headerName: 'Employee Name', field: 'email', width: 170, cellStyle:{textAlign:'left'}, pinned: 'left'},
        {headerName: 'Team Lead', field: 'tlName', width: 100, cellStyle:{textAlign:'left'}, pinned: 'left'},
        {headerName: 'Manager', field: 'managerName', width: 90, cellStyle:{textAlign:'left'}, pinned: 'left'},
        {headerName: 'BU Head', field: 'buName', width: 90, cellStyle:{textAlign:'left'}, pinned: 'left'},
        {headerName: 'Action', field: 'action', width: 70, cellStyle:{textAlign:'left'}, pinned: 'left'},
        {headerName: 'Client', field: 'publisherName', width: 110, cellStyle:{textAlign:'left'}, pinned: 'left'},
        {headerName: 'Function', field: 'functionName', width: 110, cellStyle:{textAlign:'left'}, pinned: 'left'},
      ];
      if(endDate == undefined)
        {
                startDate= currentStartDate.valueOf();
                endDate= currentEndDate.valueOf();
        }
          const theDate = new Date(startDate)
          while (theDate < endDate) {
            columnDefsDublicate.push({headerName: theDate.getDate() +'-'+ month[theDate.getMonth()], field: theDate.getDate() +'-'+ month[theDate.getMonth()], width: 80, cellStyle:{textAlign:'center'}});
            theDate.setDate(theDate.getDate() + 1);
          }
         
      columnDefsDublicate.push( {headerName: 'Cumulative', field: 'utotal', width: 100, cellStyle:{textAlign:'center'}},
                       {headerName: 'Total', field: 'total', width: 60, cellStyle:{textAlign:'center'} },
                       {headerName: 'Last FB Date', field: 'lastFbdate', width: 140, cellStyle:{textAlign:'center'}},
                       {headerName: 'No of days without FB from Jan 01', field: 'noOfDaysWithoutFeedback', width: 200, cellStyle:{textAlign:'center'} });
                       this.setState({columnDefs:columnDefsDublicate});
                       console.log(this.state.columnDefs);
    }

    feedbackRangeSelected = async(value) => {
      var startDate = value[0]._d.getFullYear()+'-'+(value[0]._d.getMonth()+1)+'-'+value[0]._d.getDate();
      var endDate = value[1]._d.getFullYear()+'-'+(value[1]._d.getMonth()+1)+'-'+value[1]._d.getDate();
      await this.setHeaders(value[0]._d.valueOf(), value[1]._d.valueOf());
      await this.fetchFeedbackPeople(startDate, endDate);
}

    fetchFeedbackPeople = async(startDate, endDate) => { 
      if(endDate == undefined)
        {

                startDate = currentStartDate.getFullYear()+'-'+(currentStartDate.getMonth()+1)+'-'+currentStartDate.getDate();
                endDate = currentEndDate.getFullYear()+'-'+(currentEndDate.getMonth()+1)+'-'+currentEndDate.getDate();
                
        }
      try {
        const url = feedbackvsactor.replace('#{startDate}', startDate).replace('#{endDate}', endDate);
        const response = await appStore.apiProxy.getAsync(url);
    
        let data = await response.json();
        this.getFeedbackAnalysis(data);
      }
      catch (e) {
        console.log(e);
      }
    }
    getFeedbackAnalysis = (serverdata) => {
      let analysisData=[];
      let compareDate='';
      let currentDate = new Date();
      var finalisedData={};
      var data=serverdata.feedback;
      var users=serverdata.users;
      console.log(data); 
      var usernames={};
      console.log(users);
      for(let i=0; i<users.length; i++){
        users[i]["total"]=(users[i]["total"]+users[i]["ltotal"])
        usernames[users[i]["id"]]=users[i];
      }
      console.log(usernames);
var feedbackUsers = {};
for(let i=0; i<data.length; i++)
{
    var eachRow = data[i];
    let tl_id=eachRow["tl_id"]
    if(usernames[tl_id]!= undefined){
      eachRow["tlName"]=usernames[tl_id]["name"];
      let manager_id=usernames[tl_id]["manager_id"];
        if(usernames[manager_id] != undefined){
          eachRow["managerName"]=usernames[manager_id]["name"];
          let bu_id=usernames[manager_id]["manager_id"];
          if(usernames[bu_id] != undefined){
            eachRow["buName"]=usernames[bu_id]["name"];
          }
        }
    }
    
    //eachRow["performer"] = eachRow["performer"].toLowerCase().trim();
    var dateObj = new Date(data[i].createdAt);
    var getDay = dateObj.getDate()+"-"+month[dateObj.getMonth()];
    eachRow[getDay] = 1; 
    eachRow['total'] = 1;
    eachRow['utotal'] = 0;
    if(usernames[eachRow["userId"]] != undefined){
      eachRow['utotal'] = usernames[eachRow["userId"]]["total"];
    }
    eachRow['lastFbdate'] = new Date(data[i].createdAt).toDateString();;
    var count = new Date().getTime() - dateObj.getTime();
    eachRow['noOfDaysWithoutFeedback'] = Math.round(count / (1000 * 3600 * 24));
    if(finalisedData[eachRow["email"]] == undefined ){
        finalisedData[eachRow["email"]] = eachRow;
        feedbackUsers[eachRow["userId"]] = 1;
        continue;
    }
    finalisedData[eachRow["email"]][getDay] = 1;
    finalisedData[eachRow["email"]]["total"] += 1;
    finalisedData[eachRow["email"]]["noOfDaysWithoutFeedback"] = eachRow['noOfDaysWithoutFeedback']
}

data = [];
for (var prop in finalisedData)
{
    data.push(finalisedData[prop])
}
console.log(feedbackUsers)
for (var prop in usernames)
{
    if (feedbackUsers[prop] != undefined){
        continue
    }
    console.log(usernames[prop]["name"])
    var noFeedbackUsers = {}
    noFeedbackUsers["email"]=usernames[prop]["name"]
    noFeedbackUsers["emp_id"]=usernames[prop]["emp_id"]
    noFeedbackUsers["total"]=0;
    noFeedbackUsers["utotal"]=0;
    data.push(noFeedbackUsers)
}
console.log(data);
      this.setState({'rowData':data});
    }

    mailHeight = () => {
      const mailHeight = dimensionStore.height+1000;
      return { height: mailHeight };
    }
    onCellClicked=(event)=>{
    if(event.value == 1)
      {
        this.openFeedback(event.data.feedbackId);
      }
    }
    showModal = () => {
      this.setState({
        visible: true,
      });
    }
    handleOk = (e) => {
      console.log(e);
      this.setState({
        visible: false,
      });
    }
    handleCancel = (e) => {
      console.log(e);
      this.setState({
        visible: false,
      });
    }
    
    openFeedback = async(id)=>{
      const url = getFeedbackReport.replace('#{feedbackId}', id);
      const response = await appStore.apiProxy.getAsync(url);
      let data = await response.json();
      this.setState({'actorName':data[0].actorName});
      this.setState({'content':data[0].content});
      this.setState({'divisionName':data[0].divisionName});
      this.setState({'expectedEndDate':data[0].expectedEndDate});
      this.setState({'feedbackDate':data[0].feedbackDate});
      this.setState({'feedbackName':data[0].feedbackName});
      this.setState({'functionName':data[0].functionName});
      this.setState({'feedbackOn':data[0].feedbackOn});
     this.showModal();

    }
  
  
    renderAgGrid() {

      return (
    
       
        <div className="ag-theme-material" style={this.mailHeight()}>
             <RangePicker style={{'float':'right','marginTop':'-50px'}}
                format={dateFormat}
                onChange={this.feedbackRangeSelected}/>
              <AgGridReact onCellClicked={this.onCellClicked}
                    rowData={this.state.rowData}
                    columnDefs={this.state.columnDefs}
                    enableSorting={true}  enableFilter={true}
                    />
                  <Modal
          title="Feedback"
          visible={this.state.visible}
          onOk={this.handleOk}

          onCancel={this.handleOk}
        >
          <p><b>Actor Name</b>: {this.state.actorName}</p>
          <p><b>Content</b>: {this.state.content}</p>
          <p><b>Division Name</b>: {this.state.divisionName}</p>
          <p><b>Expected End Date</b>: {this.state.expectedEndDate}</p>
          <p><b>Feedback Date</b>: {this.state.feedbackDate}</p>
          <p><b>Feedback Name</b>: {this.state.feedbackName}</p>
          <p><b>Feedback Type</b>: {this.state.feedbackType}</p>
          <p><b>Function Name</b>: {this.state.functionName}</p>
          <p><b>Feedback On</b>: {this.state.feedbackOn}</p>
          
        </Modal>    
          </div>
      );
  }
  
    render()
    {
      return (
        <div style ={{"text-align": "center","width":"100%","margin-top":"70px"}} >
        {this.renderAgGrid()}
      
        </div>
      )
    }
}

export default withStyles(styles)(FeedbackAnalysis);