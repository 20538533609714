import React, { Component, Fragment } from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import RootCause from "./RootCause";
import { observer } from 'mobx-react';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Button, CircularProgress } from "@material-ui/core";
import Tooltip from '@material-ui/core/Tooltip';
import { LOADING, ERROR } from './stores/LabelStore';

import { rootCauseTreeStore } from './stores/RootCauseTreeStore';
import { feedbackStore } from "./stores/FeedbackStore";

const styles = theme => ({
    root: {
        height: window.innerHeight / 2.5,
        width: '100%',
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    grow: {
        flexGrow: 1,
    },
    progress: {
        marginLeft: '50%'
    }
});


@observer
class RootCausePanel extends Component {

    render_add_button() {
        const { classes } = this.props;
        return (
            <Fab size="small" color="primary" disabled={!rootCauseTreeStore.isEditable()} style={{ 'position': 'relative', 'marginTop': '7px', 'float': 'right' }} aria-label="Add" className={classes.margin}>
                <AddIcon onClick={rootCauseTreeStore.create} />
            </Fab>
        );
    }

    render() {
        const { classes } = this.props;
        if (rootCauseTreeStore.feedbackActorId == 0) {
            return (<div className={classes.root}>{this.render_header()}<p style={{ "display": "grid", "alignItems": "center", "justifyContent": "center" }}>Select a feedback </p></div>);
        };
        return (
            <Fragment>
                {this.render_header()}
                <div className={classes.root}>
                    <div>{this.render_items()}</div>
                    {rootCauseTreeStore.isOwner && this.render_add_button()}
                    <br />
                    <br />
                </div>
            </Fragment>
        );
    }

    render_finalize_button() {
        return (
            <Tooltip title="Finalize Action" disabled={!rootCauseTreeStore.isEditable()} aria-label="Add">
                <Button variant="contained" onClick={feedbackStore.finalizeFeedbackActor}>Finalize</Button>
            </Tooltip>
        );
    }

    render_header = () => {
        const store = rootCauseTreeStore;
        const displayName = store.feedbackId === 0 ? 'Please Select a Feedback to see its Rootcauses' : 'Rootcauses For Feedback - ' + store.feedbackId;
        const { classes } = this.props;
        return (<AppBar position="static" color="secondary">
            <Toolbar variant="dense">
                <Typography variant="subtitle1" color="inherit" className={classes.grow}>
                    {displayName}
                </Typography>
                {store.isOwner && this.render_finalize_button()}
            </Toolbar>
        </AppBar>);
    }

    render_items = () => {
        const store = rootCauseTreeStore;
        const items = store.rootCauses;
        const { classes } = this.props;
        const feedbackType = feedbackStore.selectedFeedback.feedbackType;

        if (store.pageState === LOADING) {
            return <CircularProgress className={classes.progress} color="secondary" />;
        }

        if (store.pageState === ERROR) {
            return (<div><p style={{ "display": "grid", "alignItems": "center", "justifyContent": "center" }} >Error while loading rootcauses.</p></div>);
        }

        if (store.rootCauses.length === 0) {
            return (<div><p style={{ "display": "grid", "alignItems": "center", "justifyContent": "center" }} >No Rootcauses Available.</p></div>);
        }

        const renderItems = [];
        items.map((item, index) => { renderItems.push(<RootCause rootCause={item} key={item.id} index={index} tags={store.rootCauseTags} feedbackType={feedbackType} />) });
        return renderItems;
    }
}

RootCausePanel.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(RootCausePanel);