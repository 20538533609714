import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import { ExitToApp } from '@material-ui/icons';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { inject, observer } from 'mobx-react';

const styles = theme => ({
  accountmenu: {
    marginRight: '10px'
  }
});

@inject("appStore")
@observer
class AccountMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { classes } = this.props;
    const appStore = this.props.appStore;
    return (
      <div>
        <Button
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
          className={classes.accountmenu}
        >
          {appStore.credentials.username}
          <AccountCircle />
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.handleClose}>Profile</MenuItem>
          <MenuItem onClick={this.handleClose}>My account</MenuItem>
          <MenuItem onClick={appStore.logout}>
            <ListItemIcon>
              <ExitToApp />
            </ListItemIcon>
            Logout
            </MenuItem>
        </Menu>
      </div>
    );
  }
}

export default withStyles(styles)(AccountMenu);