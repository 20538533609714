
let backendHost = 'https://pubsub.newgen.co/';

let xLoginUrl = 'https://pubsub.newgen.co/feedback/domains/auth';

const hostname = window && window.location && window.location.hostname;

if(hostname === 'localhost') {
  backendHost = 'http://localhost:8082/';
  xLoginUrl = 'http://localhost:8082/feedback/domains/auth';
}

if(hostname === 'qmrstest.web.app') {
  backendHost = 'https://pubsubtest.newgen.co/';
  xLoginUrl = 'https://pubsubtest.newgen.co/feedback/domains/auth';
}

export const mailUrl = backendHost+"feedback/correspondences?id={mailId}";

export const unassignedFeedbacksUrl = backendHost+"feedback/correspondences/unassignedFeedbacks?zimbraEmailFolder";

export const getUserEmailFolders = backendHost+"feedback/correspondences/getUserEmailFolders";

export const ignoreCorrespondenceUrl = backendHost+"feedback/correspondences";

export const deleteCorrespondenceByMessageId = backendHost+"feedback/correspondences/deleteEmail";

export const feedbackMailUrl = backendHost+"feedback/feedbacks/mails/actors";

export const feedbackPeopleUrl = backendHost+"feedback/feedbacks/people";

export const deliverySave = backendHost+"feedback/delivery/savedata";

export const submittedFeedbacksUrl = backendHost+"feedback/feedbacks/submittedFeedbacks";

export const pendingForAuditUrl = backendHost+"feedback/feedbacks/pendingForAudit";

export const resendCompletedMailUrl = backendHost+"feedback/feedbacks/resendCompletedMail";

export const submittedFeedbackActorsUrl = backendHost+"/feedback/feedbackActors/submittedFeedbacks?userId=#{userId}"

export const feedbackUrl = backendHost+"feedback/feedbacks";

export const feedbackMailResendUrl = backendHost+"feedback/feedbacks/mails/resend"

export const finalizeFeedbackActorUrl = backendHost+"feedback/feedbackActors/finalize";

export const actionItemsUrl =   backendHost+'feedback/domains/actionItems?rootCauseId={rootCauseId}';

export const actionItemsSaveUrl = backendHost+'feedback/actionItems/save';

export const deleteActionItemsUrl = backendHost+'feedback/actionItems/delete';

export const actionItemsByFeedbackUrl = backendHost+'feedback/actionItems/submittedActionItems?feedbackId=#{feedbackId}';

export const rootCauseUrl = backendHost+'feedback/domains/rootCauses?feedbackId={feedbackId}';

export const rootCauseUsersByFeedbackUrl = backendHost+'feedback/rootCauses/users?feedbackId={feedbackId}';

export const rootCauseUsersUrl = backendHost+'feedback/domains/rootCauseUsers?rootCauseIds=#{rootCauseId}';

export const noFeedbackUrl = backendHost+'feedback/interactions/nofeedbacks/proposals';

export const noFeedbackAcceptanceUrl = backendHost+'feedback/interactions/nofeedbacks/acceptances';

export const noFeedbackReinstateUrl = backendHost+'feedback/interactions/nofeedbacks/rejections';

export const rejectSubmittedFeedbackUrl = backendHost+'feedback/interactions/submittedFeedback/reject';

export const acceptSubmittedFeedbackUrl = backendHost+'/feedback/feedbacks/submittedFeedback/accept';

export const markAsCompleteFeedbackUrl = backendHost+'/feedback/feedbacks/markAsComplete';

export const classifactionsUrl = backendHost+'feedback/classifications/subClassifications?functionIds={functionIds}';

export const reassignFeedbackActorsUrl  = backendHost+'feedback/interactions/functions';

export const feedbackInteractionsUrl = backendHost+'feedback/interactions';

export const pendingProposalsUrl = backendHost+'/feedback/interactions/pendingProposals';

export const pendingManagerProposalsUrl = backendHost+'feedback/interactions/pendingManagerProposals';

export const managerRemarkOnFeedback = backendHost+'feedback/interactions/managerRemarkOnFeedbackRejection';

export const distributionListUrl = backendHost+"feedback/domains/distributionLists?divisionId={divisionId}&functionIds={functionIds}";

export const distributionListManagementUrl = backendHost+"feedback/distributionLists";

export const deleteDistributionList = backendHost+"feedback/distributionLists/delete"

export const publisherListUrl = backendHost+"feedback/domains/publishers";

export const deliveryListUrl = backendHost+"feedback/delivery/getdata";

export const functionListUrl = backendHost+"feedback/domains/functions";

export const divisionListUrl = backendHost+"feedback/domains/divisions?publisherId={publisherId}";

export const usersUrl = backendHost+'feedback/domains/users';

export const userSave = backendHost+"feedback/domains/saveUser";

export const filteredUsersUrl = backendHost+'feedback/users/filteredUsers?name=#{name}';

export const saveRootCauseUrl = backendHost+"feedback/rootCauses/saveRootCause";

export const newRootCauseUrl = backendHost+"feedback/rootCauses/new";

export const deleteRootCauseUrl = backendHost+"feedback/rootCauses/delete";

export const assignedFeedbackUrl = backendHost+'feedback/feedbacks/assignedFeedbacks?userId=#{userId}&addressIn=#{addressIn}';

export const feedbackObservationUrl = backendHost+'feedback/observations/consolidations'

export const feedbackvsactor = backendHost+"feedback/delivery/feedbackvsactor?startDate=#{startDate}&endDate=#{endDate}";

export const getFeedbackReport = backendHost+"feedback/delivery/feedbackofperformer?feedbackId=#{feedbackId}";

export const getFeedbackScore = backendHost+"feedback/feedbackActors/scorecard?userId=#{userId}&year=#{year}";

export const userPendingStatsUrl = backendHost+"feedback/stats/userPendingFeedbacks";

export const rootCauseTagsUrl = backendHost+"feedback/tags/rootCauseTags";

export const mailTagUrl = "https://pubsub.newgen.co/reportsmicro/reports/mail_tags";

export const getPubkitQmrsPubDivUrl = backendHost+"feedback/domains/fetchqmrspublisherdivision";

export const userIdUrl = backendHost+"feedback/domains/emails?email=#{email}";

export const loginUrl = xLoginUrl;

export const autoLoginUrl = backendHost+'feedback/domains/autologin';

export const saveUserProfile = backendHost+'feedback/userProfile/saveUserProfile';

export const divisionGroupUrl = backendHost+'feedback/domains/divisionsGroup';

export const divisionSave = backendHost+"feedback/domains/saveDivision";

export const getDownloadAttachmentUrl = backendHost+"feedback/domains/downloadattachment";
