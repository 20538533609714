import { withStyles } from '@material-ui/core/styles';
import { Provider, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import FeedbackProposalUIContainer from './FeedbackProposalUIContainer';

import CorrespondenceStore from './stores/CorrespondenceStore';
import {dimensionStore} from './stores/DimensionStore';
import { feedbackStore } from './stores/FeedbackStore';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit,
  },
});


@inject("appStore")
class FeedbackProposalWorkbench extends Component {

  constructor(props)
  {
    super(props);
    this.correspondenceStore = new CorrespondenceStore({ apiProxy: props.appStore.apiProxy });
    dimensionStore.setDimension(window.innerHeight / 2.5, window.innerHeight / 2.5);
  }

  render() {
    const { classes } = this.props;
    return (
      <Provider correspondenceStore={this.correspondenceStore} feedbackStore={feedbackStore}>
        <div className={classes.root}>
          <FeedbackProposalUIContainer />
        </div>
      </Provider>
    );
  }
}

FeedbackProposalWorkbench.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FeedbackProposalWorkbench);
