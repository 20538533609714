import React, { Component } from "react";
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';

import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';


const styles = {
    card: {
        marginTop: '30px',
        marginLeft:'auto',
        marginRight:'auto',
        maxWidth: '600px',
    },
    media: {
        objectFit: 'cover',
    },
};

class About extends Component
{
    render()
    {
        const {classes} = this.props;
        return(
            <Card className={classes.card}>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        Quality Management And Reporting System
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                        Version 1.0
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                        Newgen Knowlege Works.
                    </Typography>
                </CardContent>
            </Card>
        )
    }
}

export default withStyles(styles)(About);