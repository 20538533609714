import { action, decorate, observable } from 'mobx';
import APIProxy from './APIProxy';
import LoginStore from './LoginStore';
import {isBlank, toUrlParams} from './Util';
import {userIdUrl,autoLoginUrl} from './APIEndpoints';

const blankCredentials = {
    email: '',
    token: '',
    role: '',
    username: '',
    userId:0
}

const INIT = 'init';
const PENDING = 'pending';
const DONE = 'done';
const ERROR = 'error';

const IN_PROGRESS_MESSAGE = 'Authenticating...';
const SUCCESS = 'Authentication is successful.';
const INVALID_CREDENTIAL = 'Invalid Login Information';
const LOGIN_ASSISTANCE = 'You are not a member of the Feedback Group. Contact Admin for Login assistance.';

class AppStore {

    credentials = blankCredentials;

    currentComponent = null;

    apiProxy = new APIProxy();
    loginStore = new LoginStore({ apiProxy: this.apiProxy });
    menus = [];
    userFeedbackMails = 'to';
    validationMessage = '';
    isEditable=true;
    state=INIT;

    constructor() {
        this.setSessionFromStorage();
    }

    setSessionFromStorage = () => {
        const storedCredentials = localStorage.getItem('credentials');

        if (storedCredentials !== null) {
            this.credentials = JSON.parse(storedCredentials);
            this.updateContext();
        }
        else {
            this.credentials = blankCredentials;
            this.updateContext();
        }
    }

   
    authenticate = async () => {

        this.notifyProgress();

        this.credentials = blankCredentials;

        const data = await this.loginStore.authenticate();
     
        if (data == null || data.token == null) {
            this.notifyError(INVALID_CREDENTIAL);
            return;
        }

        this.credentials.email = data.email;
        this.credentials.token = data.token;
        this.credentials.role = 'Role'+data.feedbackRoleId;
        this.credentials.username = data.name;


        this.apiProxy.updateCredentialHeaders(this.credentials);

        
        const userData = await this.getUserId();

        if(userData == null)
        {
            this.notifyError(LOGIN_ASSISTANCE);
            return;
        }

        this.credentials.userId = userData.id;
        this.updateContext();
        this.persistCredentials();
        this.notifySuccess();
    }

    autoAuthenticate = async (queryParams) => {

        this.notifyProgress();

        this.credentials = blankCredentials;

        const response = await this.apiProxy.asyncPost(autoLoginUrl + '?' + toUrlParams(queryParams));
        const data = await response.json();
          console.log(data)
     
        if (data == null || data.token == null) {
            this.notifyError(INVALID_CREDENTIAL);
            return;
        }
        this.credentials.email = data.email;
        this.credentials.token = data.token;
        this.credentials.role = 'Role'+data.feedbackRoleId;
        this.credentials.username = data.name;
        this.apiProxy.updateCredentialHeaders(this.credentials);

        const userData = await this.getUserId();

        if(userData == null)
        {
            this.notifyError(LOGIN_ASSISTANCE);
            return;
        }
        this.credentials.userId = userData.id;
        console.log("1111111");
        this.updateContext();
        this.persistCredentials();
        this.notifySuccess();
    }


    notifyError = (errorMessage) => {
        this.state = ERROR;
        this.isEditable = true;
        this.validationMessage = errorMessage;
    }

    notifyProgress = () => {
        this.state = PENDING;
        this.isEditable = false;
        this.validationMessage = IN_PROGRESS_MESSAGE;
    }

    notifySuccess = () => {
        this.state = DONE;
        this.isEditable=false;
        this.validationMessage = SUCCESS;
    }

    getUserId = async() =>{
        try {
            const url = userIdUrl.replace('#{email}',this.credentials.email);
            const userPost = {'id':this.credentials.userId,'name':this.credentials.username,'email':this.credentials.email}
            const response = await this.apiProxy.asyncPost(url,userPost);
            const data = await  response.json();

            return data;
        }
        catch(e) {
            console.log(e);
            return null;
        }
    }

    persistCredentials = () => {
        localStorage.setItem('credentials', JSON.stringify(this.credentials));
    }

    updateContext = () => {
        this.apiProxy.updateCredentialHeaders(this.credentials);
        this.resolveMenu();
        this.resolveLandingPage();
    }

    resolveMenu() {
        if (!this.isLoggedIn()) {
            this.menus = require('./menus/GuestMenu.json');
            return;
        }
        this.menus = require('./menus/'+this.credentials.role+'.json');
    }

    resolveLandingPage() {
        this.menus.map((menu, key) => {
            if (menu.isLandingPage) {
                this.transitionTo(menu.key);
                return;
            }
        })
    }

    transitionTo(componentkeyName) {
        this.menus.map((menu) => { (menu.key === componentkeyName) && (this.currentComponent = menu) });
    }

    isLoggedIn = () => {
        return !(isBlank(this.credentials.email) || isBlank(this.credentials.token))
    }

    logout = () => {
        localStorage.setItem('credentials', JSON.stringify(blankCredentials));
        window.location.reload();
    }

    setProxy = (apiProxy) =>{
        this.apiProxy = apiProxy;
    }
}

decorate(AppStore, {
    state:observable,
    validationMessage: observable,
    isEditable:observable,

    currentComponent: observable,
  
    credentials: observable,
    userFeedbackMails: observable,
    setCredentials: action,
    logout: action,

    authenticate: action,
    transitionTo: action
});

export const appStore = new AppStore();