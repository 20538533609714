import { decorate, computed, observable, flow, action } from "mobx";

import { assignedFeedbackUrl, feedbackPeopleUrl, mailTagUrl, finalizeFeedbackActorUrl } from './APIEndpoints';
import { rootCauseTreeStore } from './RootCauseTreeStore';
import { snackBarStore } from "./SnackBarStore";
import { appStore } from './AppStore';
import { LOADING, DONE, ERROR, INIT } from './LabelStore';
import { feedbackInteractionStore } from "./FeedbackInteractionStore";

const emptyCorrespondenceTag = {
    publisherTag: '',
    divisionTag: '',
    projectTag: '',
    managerTag: '',
    tagSource: '',
};

const processingTag = {
    publisherTag: 'loading',
    divisionTag: 'loading',
    projectTag: 'loading',
    managerTag: 'loading',
    tagSource: '',
};

class FeedbackStore {

    assignedFeedbacks = [];
    listState = INIT;
    detailState = INIT;
    feedbackId = 0;
    divisionId= 0;
    functionId=0;
    selectedFeedback = {};
    selectedFeedbackActor = {};
    correspondence = {};
    feedbackPeople = [];
    context = '';
    tabIndex = 0;
    correspondenceTag = emptyCorrespondenceTag;
    circulatedDate = new Date();

    setUserEmails=(value)=>{
        if(value=='to')
        {
            appStore.userFeedbackMails='to';
        }
        else if(value=='cc')
        {
            appStore.userFeedbackMails='cc';
        }
        this.fetchAssignedFeedbacks(appStore.credentials.userId);
    }

    fetchAssignedFeedbacks = flow(function* (userId) {
        this.reset();
        this.listState = LOADING;
        try {
            const url = assignedFeedbackUrl.replace('#{userId}', userId).replace('#{addressIn}', appStore.userFeedbackMails);
            const response = yield appStore.apiProxy.getAsync(url);
            const data = yield response.json();
            this.setAssignedFeedbacks(data);
        }
        catch (e) {
            this.listState = ERROR;
            console.log(e);
        }
    });

    fetchFeedbackPeople = async (feedbackId,selectedFeedbackActor = {}) => {
        this.detailState = LOADING;
        try {
            this.reset();
            this.selectedFeedbackActor = selectedFeedbackActor;
            const response = await appStore.apiProxy.getAsync(feedbackPeopleUrl + "?feedbackId=" + feedbackId);
            const data = await response.json();
            this.setFeedbackActors(data);
        }
        catch (e) {
            this.detailState = ERROR;
            console.log(e);
        }
    };

    setAssignedFeedbacks = (data) => {
        this.assignedFeedbacks = data;
        this.listState = DONE;
    }

    get rowCount() {
        return this.assignedFeedbacks.length;
    }

    setFeedbackActors = (data) => {
        if (!data || data.length === 0) {
            this.detailState = ERROR;
            return;
        }

        this.feedbackId = data[0].feedback.id;
        this.selectedFeedback = data[0].feedback;
        this.correspondence = data[0].feedback.correspondence;
        this.context = data[0].feedback.content;
        this.divisionId = data[0].feedback.divisionId;
        this.functionId = data[0].feedback.functionId;
        this.circulatedDate = data[0].feedback.createdAt;

        const list = [];

        data.map(item => {

            const map = {};
            const itemFunction = item.function === undefined ||  item.function === null ? {id:0,name:''} : item.function;
            map.id = item.id
            map.function = itemFunction.name;
            map.functionId = itemFunction.id;
            map.user = item.user.name;
            map.addressIn = item.owner ? 'To' : 'CC';
            map.owner = item.owner;
            if(itemFunction.id !== 0)
            {
                feedbackInteractionStore.addSelectedFunction(item.function);    
            }
            list.push(map);
        });

        this.feedbackPeople = list;
        this.detailState = DONE;

        this.loadCorrespondenceTag(this.correspondence.messageId);
    }

    get header() {
        if (this.feedbackId <= 0 || !this.selectedFeedbackActor) {
            return 'Please Select a Feedback to see its details';
        }
        
        if(this.detailState === LOADING)
        {
            return 'Loading....';
        }
        if(this.detailState === ERROR)
        {
            return 'Error while loading details.'
        }
        
        return 'Feedback - ' + this.feedbackId;
    }

    loadCorrespondenceTag = flow(function* (messageId) {

        try {
            this.correspondenceTag = processingTag;
            const response = yield appStore.apiProxy.getAsync(mailTagUrl + "?messageId=" + messageId);
            const data = yield response.json();
            this.correspondenceTag = data.found ? data : emptyCorrespondenceTag
        }
        catch (e) {
            console.log(e)
        }
    });

    finalizeFeedbackActor = async() =>{

        if (rootCauseTreeStore.feedbackActorId === 0) {
            snackBarStore.showErrorMessage('Select a feedback');
            return;
        }
        if (!rootCauseTreeStore.canFinalize()) {
            return;
        }

        const response = await appStore.apiProxy.asyncPost(finalizeFeedbackActorUrl, rootCauseTreeStore.feedbackActorId);
        const data = await response.json();

        if (data.finalized == false) {
            snackBarStore.showErrorMessage(data.message);
            return;
        }
        this.refresh();
        snackBarStore.showSuccessMessage(data.message);
    };

    refresh = () => {
        this.reset();
        this.fetchAssignedFeedbacks(appStore.credentials.userId);
    }

    reset = () => {
        rootCauseTreeStore.reset();
        feedbackInteractionStore.reset();
        this.selectedFeedback = {};
        this.selectedFeedbackActor = {};
        this.feedbackId = 0;
        this.correspondenceTag = emptyCorrespondenceTag;
        this.context = '';
        this.feedbackPeople = [];
    }

    get selectedFunctionIds(){
        const functionIds = new Set();
        this.feedbackPeople.map(item=>{
            if(item.functionId !== 0)
            {
                functionIds.add(parseInt(item.functionId));
            }
        });
        return [...functionIds];
    }

    get editable(){
        if(this.listState === LOADING || this.listState === ERROR){
            return false;
        }
        return this.detailState !== LOADING && this.detailState !== ERROR;
    }
}

decorate(FeedbackStore, {
    rowCount: computed,
    selectedFunctionIds: computed,
    listState: observable,
    assignedFeedbacks: observable,
    detailState: observable,
    feedbackPeople: observable,
    correspondence: observable,
    context: observable,
    feedbackId: observable,
    divisionId: observable,
    functionId: observable,
    header: computed,
    fetchFeedbackPeople: action,
    finalizeFeedbackActor: action,
    correspondenceTag: observable,
    tabIndex: observable,
    selectedFeedback: observable,
    editable: computed,
    circulatedDate: observable,
});
export const feedbackStore = new FeedbackStore();