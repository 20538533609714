import { decorate, observable, computed, action } from 'mobx';
import FeedbackPeopleStore from './FeedbackPeopleStore';

import { feedbackMailResendUrl } from './APIEndpoints';
import { LOADING } from './LabelStore';
import { ComponentResolver } from 'ag-grid-community';
;

const PENDING = 'pending';
const DONE = 'done';
const ERROR = 'error';
const INIT = 'init';


export default class FeedbackControllerStore {

  state = INIT;

  correspondenceId = 0;
  messageId = '';

  feedbackId = 0;
  isFeedbackFinalized = false;
  isMailClosed = false;
  mailTag = {};

  validationMessage = '';

  isEditable = false;

  constructor(props) {
    this.apiProxy = props.apiProxy;
    this.peopleStore = new FeedbackPeopleStore(props);
  }

  get isValidStep() {
    return this.peopleStore.isValidStep;
  }

  get canIgnore() {
    return this.correspondenceId > 0 && this.state === DONE;
  }

  /**
   * When a feedback is finalized the respective correspondence will bear the feedback id
   * 
   */
  get shouldResend() {
    return this.isFeedbackFinalized && !this.isMailClosed;
  }

  async save(body){
    console.log("2222");
    console.log(body);
    this.state=PENDING;
    await this.peopleStore.save(body);
    this.state=DONE;
  }

  async mailSelected(mailId, messageId) {
    this.state = PENDING;
    this.correspondenceId = mailId;
    this.messageId = messageId;
    this.isFeedbackFinalized = false;
    this.isEditable = true;

    this.peopleStore.loadCorrespondenceTag(this.messageId);

    const feedbackInfo = await this.peopleStore.loadFeedback(this.correspondenceId);
    console.log(feedbackInfo);

    this.setFeedbackInfo(feedbackInfo);

    this.state = DONE;
  }

  /**
   * It is a provisional closure of the mail:
   * 
   * As obtaining the response from the mail service is asynchronous, there is no
   * guarantee that the mail is really dispatched. 
   * 
   * Hence we just mark this mail as provisionally closed.
   * 
   */
  async resend() {

    this.state = PENDING;
    try {
      const body = { feedbackId: this.feedbackId };

      const response = await this.apiProxy.asyncPost(feedbackMailResendUrl, body);
      await response.json();
      this.isMailClosed = true;
      this.state=DONE;
    }
    catch (e) {
      console.log(e);
      this.state = ERROR;
    }
  }

  async finish(body) {
    try {
      this.state = PENDING;
      const feedbackInfo = await this.peopleStore.saveAndFinalize(body);

      this.setFeedbackInfo(feedbackInfo);

      this.state = DONE;
    }
    catch (message) {
      this.onValidationError(message)
    }
  }


  // The Private Internal callback methods

  setFeedbackInfo = (feedbackInfo) => {
    this.feedbackId = feedbackInfo.feedbackId;
    this.isFeedbackFinalized = feedbackInfo.isFeedbackFinalized;
    this.isMailClosed = feedbackInfo.isMailClosed;
  }

  onSaveSuccess = (feedbackInfo) => {

    this.setFeedbackInfo(feedbackInfo);

    this.activeStep = this.isLastStep ? this.activeStep : this.activeStep + 1;

    this.activeDomainStore.loadFeedback(this.feedbackId);

    this.state = DONE;
    
  }

  onValidationError = (validationMessage) => {
    this.state = ERROR;
    this.validationMessage = validationMessage;
  }

  get editable(){
    return this.state !== PENDING;
  }

}

decorate(FeedbackControllerStore, {

  state: observable,
  correspondenceId: observable,
  messageId: observable,
  feedbackId: observable,
  isFeedbackFinalized: observable,
  isMailClosed: observable,

  activeStep: observable,

  validationMessage: observable,

  isValidStep: computed,
  canIgnore: computed,
  shouldResend: computed,

  mailSelected: action,
  finish: action,

  setFeedbackInfo: action,
  onSaveSuccess: action,
  onValidationError: action,
  resend: action,
  save: action,

})
