import React, { Component, Fragment } from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import ActionItemView from './ActionItemView';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import { actionItemListStore } from './stores/ActionItemTreeStore';
import { LOADING, ERROR } from "./stores/LabelStore";
import { CircularProgress } from "@material-ui/core";
import { centerMessageDiv, initHeader } from "./InputElements";

const styles = theme => ({
  root: {
    height: window.innerHeight / 2.5,
    width: '100%',
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  grow: {
    flexGrow: 1,
  }
});

@observer
class ActionItemPanel extends Component {

  render() {
    const { classes } = this.props;
    if (actionItemListStore.rootCauseName === '') {
      return (<div className={classes.root}>{this.render_header()}<p style={{ "display": "grid", "alignItems": "center", "justifyContent": "center" }}>Select/Save the Rootcause</p></div>);
    };
    return (
      <Fragment>
        <div className={classes.root}>
          {this.render_header()}
          {this.render_action_items()}
          <Fab size="small" onClick={actionItemListStore.create} disabled={!actionItemListStore.isEditable()} color="primary" style={{ 'position': 'relative', 'marginTop': '5px', 'float': 'right' }} aria-label="Add" className={classes.margin}>
            <AddIcon />
          </Fab>
          <br />
          <br />
        </div>
      </Fragment>
    );

  }

  render_action_items() {
    const actionItems = actionItemListStore.actionItems;
    const { classes } = this.props;
    if (actionItemListStore.pageState === LOADING) {
      return <CircularProgress className={classes.progress} color="secondary" />;
    }

    const message = actionItemListStore.pageState === ERROR ? 'Error while loading action items.' : 'No action Items Present.';
    if (actionItems.length === 0 || actionItemListStore.pageState === ERROR) {
      return centerMessageDiv(message);
    }
    const renderItems = [];
    actionItems.map((item, index) => { renderItems.push(<ActionItemView index={index} key={item.id} actionItem={item} />) });
    return renderItems;
  }

  render_header = () => {
    const displayHeader = actionItemListStore.rootCauseId === 0 ? 'Please Select a Rootcause to see its Action Items' : 'Action Items for ' + actionItemListStore.rootCauseName;
    return initHeader(this.props, displayHeader);
  }

}
ActionItemPanel.propTypes = {
  classes: PropTypes.object.isRequired,
}
export default withStyles(styles)(ActionItemPanel);