import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {dimensionStore} from './stores/DimensionStore';

import { observer, inject } from 'mobx-react';

import PendingProposals from './PendingProposals';
import MailContent from './MailContent';
import FeedbackDetailsManagerView from './FeedbackDetailsManagerView';

const styles = theme => ({
    paper: {
        padding: theme.spacing.unit,
    },
});

@inject("correspondenceStore")
@observer
class FeedbackProposalUIContainer extends Component {
    constructor(props){
        super(props);
        dimensionStore.setDimension(window.innerHeight / 2.5, window.innerHeight / 2.5);
    }
    
    render() {
        const { classes } = this.props;
        const mailWidth = dimensionStore.mailWidth;
        return (
            <Grid container spacing={16}>
                <Grid item xs={12 - mailWidth}>
                    <Paper>
                        <PendingProposals />
                    </Paper>
                </Grid>
                <Grid item xs={mailWidth}>
                    <Paper>
                        <MailContent />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <FeedbackDetailsManagerView />
                    </Paper>
                </Grid>
            </Grid>
        )
    }
}
FeedbackProposalUIContainer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FeedbackProposalUIContainer);
